import React, { useState } from 'react'
import RegisteredMemberChart from './RegisteredMemberChart'
import MemberEducationChart from './MemberEducationChart'
import MemberDetailChart from './MemberDetailChart'
import MaterialStatusChart from './MaterialStatusChart'
import MedicationChart from './MedicationChart'
import { Link } from 'react-router-dom'

export const MemberDashboard = () => {
  const [material, setMaterial] = useState(true)
  const [medication, setMedication] = useState(true)

  return (
    <>
    <section className='Admindashboard__task__wrapper '>
      <div className='container'>
        <div className='row mb-3'>
          <div className='col-12 d-flex align-items-center justify-content-between'>
            <p className='title'>Member Dashboard</p>
          </div>
        </div>
          <div className='mb-5'>
          <div className='mb-5'>
              <div className='dashboardCard'>
                <Link to='/memberdetailpage'>
                <div className=''>
                <p style={{color:'black', fontSize:'20px'}}>
                  Registered Members
                  </p>
                </div>
                <RegisteredMemberChart/>
                </Link>
              </div>
          </div>
          <div>
              <div className='dashboardCard cardTop'>
              <Link to='/memberdetailpage'>
                <div className=''>
                <p style={{color:'black', fontSize:'20px'}}>
                  Members Education
                  </p>
                  <MemberEducationChart/>
                </div>
                </Link>
              </div>
          </div>
        </div>
        <div className=' mb-5'>
          <div className='mb-5'>
              <div className='dashboardCard'>
              <Link to='/memberdetailpage' state= {material} >
                <div >
                <p style={{color:'black', fontSize:'20px'}}>
                  Material Status
                  </p>
                </div>
                <MaterialStatusChart/>
                </Link>
              </div>
          </div>
          <div >
              <div className='dashboardCard cardTop'>
              <Link to='/memberdetailpage' state={medication}>
                <div className=''>
                <p style={{color:'black', fontSize:'20px'}}>
                 Permanent Medication
                  </p>
                  <MedicationChart/>
                </div>
                </Link>
              </div>
          </div>
        </div>
        {/* <div className='row mb-5'>
          <div className='col-md-12'>
              <div className='dashboardCard'>
                <div className=''>
                  <p className=''>
                  Members Detail
                  </p>
                  <MemberDetailChart/>
                </div>
              </div>
          </div>
        </div>  */}
      </div>
    </section>
  </>
  )
}
