/* eslint-disable react/jsx-no-undef */
import React from "react";
import "./App.css";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Login } from "./Login/Login";
import { FamilyRegistration } from "./RegistrationForms/FamilyRegistration";
import { MemberRegistration } from "./RegistrationForms/MemberRegistration";
import { PropertyRegistration } from "./RegistrationForms/PropertyRegistration";
import { FamilyListingpage } from "./ListingPage/FamilyListingpage";
import { MemberListingpage } from "./ListingPage/MemberListingpage";
import { FamilyListingdetail } from "./ListingPage/FamilyListingdetail";
import { MemberListingdetail } from "./ListingPage/MemberListingdetail";
import { ViewFamilyMember } from "./ListingPage/ViewFamilyMember";
import { useSelector } from "react-redux";
import { HomePage } from "./HomePage";
import About from "./About_us/About";
import { DonationPage } from "./Donation/DonationPage";
import Dashboard from "./dashboard/Dashboard";
import AppriciationModel from "./Stripe/AppriciationModel";
import { DetailPage } from "./dashboard/Family/DetailPage";
import { MemberDetailPage } from "./dashboard/Member/DetailPage";
import { DonationFilters } from "./dashboard/Donation/DonationFilters";
import { ApplicationDetail } from "./dashboard/Applicaton/ApplicationDetail";
import { ViewApplicationMember } from "./dashboard/Applicaton/ViewApplicationMember";
import { PropertyListing } from "./ListingPage/PropertyListing";
import { Propertylistingdetail } from "./ListingPage/Propertylistingdetail";
import { ProgramDetail } from "./dashboard/Programs/ProgramDetail";
import MedicalStoredetail from "./dashboard/Medicalstaff/MedicalStoredetail";
import RegisterMedicalStore from "./RegistrationForms/RegisterMedicalStore";
import CasesFeeds from "./Cases_feeds/CasesFeeds";
import PostCase from "./Cases_feeds/PostCase";
import {Programs} from "./Programs/Programs";
import { ProgramDetails } from "./Programs/ProgramDetails";
import SpecificCase from "./Cases_feeds/SpecificCase";
import CaseDonationStats from "./Cases_feeds/CaseDonationStats";


function App() {
  const { isLogin } = useSelector((state) => state.authSlice);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/login" element={<Login />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/casesfeeds" element={<CasesFeeds/>} />
        <Route path="/casesfeeds/:id" element={<SpecificCase/>} />
        <Route path="/caseDonationStats/:id" element={<CaseDonationStats/>} />
        <Route path="/postcase" element={<PostCase/>} />
        <Route path="/programs" element={<Programs/>} />
        <Route path="/programDetail/:id" element={<ProgramDetails/>} />
        <Route path="/donation" element={<DonationPage />} />
        <Route path="/donation/:id" element={<DonationPage />} />
        <Route path="/appericiation" element={<AppriciationModel />} />
        <Route
          path="/familyRegistration"
          element={isLogin ? <FamilyRegistration /> : <Navigate to="/login" />}
        />
        <Route
          path="/memberRegistration"
          element={isLogin ? <MemberRegistration /> : <Navigate to="/login" />}
        />
         <Route
          path="/medicalstoreRegistration"
          element={isLogin ? <RegisterMedicalStore /> : <Navigate to="/login" />}
        />
        <Route
          path="/propertyRegistration"
          element={
            isLogin ? <PropertyRegistration /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/familylisting"
          element={isLogin ? <FamilyListingpage /> : <Navigate to="/login" />}
        />
        <Route
          path="/memberlisting"
          element={isLogin ? <MemberListingpage /> : <Navigate to="/login" />}
        />
        <Route
          path="/familydetailpage"
          element={isLogin ? <DetailPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/donationdetailpage"
          element={isLogin ? <DonationFilters /> : <Navigate to="/login" />}
        />
        <Route
          path="/memberdetailpage"
          element={isLogin ? <MemberDetailPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/propertydetailpage"
          element={isLogin ? <PropertyListing /> : <Navigate to="/login" />}
        />
        <Route
          path="/familylistingdetail/:id"
          element={isLogin ? <FamilyListingdetail /> : <Navigate to="/login" />}
        />
        <Route
          path="/applicationdetail/:id"
          element={isLogin ? <ApplicationDetail /> : <Navigate to="/login" />}
        />
        <Route
          path="/applicationMember/:id"
          element={
            isLogin ? <ViewApplicationMember /> : <Navigate to="/login" />
          }
        />

        <Route
          path="/memberlistingdetail/:id"
          element={isLogin ? <MemberListingdetail /> : <Navigate to="/login" />}
        />
        <Route
          path="/medicalstoredetail/:id"
          element={isLogin ? <MedicalStoredetail /> : <Navigate to="/login" />}
        />
        <Route
          path="/propertylistingdetail/:id"
          element={
            isLogin ? <Propertylistingdetail /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/viewmember/:id"
          element={isLogin ? <ViewFamilyMember /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/programDetail/:id"
          element={isLogin ? <ProgramDetail /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/*"
          element={isLogin ? <Dashboard /> : <Navigate to="/login" />}
        />

        {/* If none of the above routes match, redirect to the home page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
