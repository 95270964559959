import React from 'react'

export const OurGoal = () => {
  return (
    <div className='goal_container'>
    <div className='sec-title text-center'>
    <div className='sub-title'>
    </div>
    <h2>Our Mission & Goals</h2>
    </div>
    <div className='goal_row'>
    <div className='col-xl-12'>
    <div className='mission-goals-content clearfix'>
    <div className='mission-goals-image-box'>
    </div>
    <div className='text-holder text-right-rtl'>
    <div className='top'>
    <div className='icon'>
    <img src='https://loveicon.smartdemowp.com/wp-content/uploads/2021/06/thm-shape-4.png' alt='icon' />
    </div>
    <div className='mt-4' style={{display:'flex', flexDirection:'column'}}>
    <div className='title'>
    <h3>Small Donations Make Bigger Impact On Someone’s Life, Act Today!</h3>
    </div>
    </div>
    </div>
    <div className='text' style={{marginLeft:'70px', marginTop:'20px'}}>
    Our mission is to make the world a better place for everyone. 
    Everyone has their rights equally and we want to make sure 
    that people can get food, education, treatment and accommodation 
    for everyone who is living in poverty. 
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}
