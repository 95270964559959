import React from 'react'
import DonationDetail from './DonationDetail'
import UpCommingDonation from './UpCommingDonation'
import { Link } from 'react-router-dom'

export const DonationDashboard = () => {
  return (
    <>
    <section className='Admindashboard__task__wrapper '>
      <div className='container'>
        <div className='row mb-3'>
          <div className='col-12 d-flex align-items-center justify-content-between'>
            <p className='title'>Donation Dashboard</p>
          </div>
        </div>
          <div className=' mb-5'>
          <div className=''>
              <div className='dashboardCard'>
                <Link to='/donationdetailpage'>
                <div className=''>
                <p style={{color:'black', fontSize:'20px'}}>
                 KWF Donation
                  </p>
                </div>
                <UpCommingDonation/>
                </Link>
              </div>
          </div>
         
        </div>
        <div className=' mb-5'>
          <div >
              <div className='dashboardCard'>
              <Link to='/donationdetailpage'>
                <div className=''>
                <p style={{color:'black', fontSize:'20px'}}>
                  Donation Detail
                  </p>
                  <DonationDetail/>
                </div>
                </Link>
              </div>
          </div>
        </div>
       
        
      </div>
    </section>
  </>
  )
}
