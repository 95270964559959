import React from 'react'
import Navbar from '../Common/Navbar'
import Header from './Header'
import { MiddleSection } from './MiddleSection'
import { OurGoal } from './OurGoal'
import { Footer } from '../Footer/Footer'
import img1 from '../assets/partner/partner-2.png'
import img2 from '../assets/partner/partner-3.png'
import img3 from '../assets/partner/partner-5.png'
import img4 from '../assets/partner/partner-6.png'
import img5 from '../assets/partner/partner-7.png'
import img6 from '../assets/partner/partner-9.png'
import bugdev from '../assets/bugdev.png'
// import { AuthWrapper } from '../AuthWrapper'
const About = () => {
  return (
    // <AuthWrapper>
    <>
    <Navbar/>
    <Header/>
    <MiddleSection/>
    <OurGoal/>
    <div className='w-100 p-5'>
        <div className='sponsor-div'>
        <button className='sponsor-btn'>Our Sponsor</button>
        </div>
        <div className='intro__logos'>
          <div className='intro__trusted-by '>
            Sponsord By
            </div>
            <a href='https://www.bugdev.co.uk/'>
            <img src={bugdev} alt="img" width='110px' />
            </a>
            {/* <img src={img2} alt="img" width='80px' />
            <img src={img3} alt="img" width='80px' />
            <img src={img4} alt="img" width='80px' />
            <img src={img5} alt="img" width='80px' />
            <img src={img1} alt="img" width='80px' />
            <img src={img6} alt="img" width='80px' /> */}
        
        </div>
    </div>
    <Footer/>
    </>
    // </AuthWrapper>
  )
}

export default About