import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../Common/Navbar";
import { Bar } from "react-chartjs-2";
import { fetchApi } from "../utlis/axios";
import { toast } from "react-toastify";

const CaseDonationStats = () => {
  const { id } = useParams();
  const [requiredAmount, setRequiredAmount] = useState(null);
  const [collectedAmount, setCollectedAmount] = useState(null);
  const [donations, setDonations] = useState([]);

  useEffect(() => {
    const fetchDonationData = async () => {
      try {
        const result = await fetchApi.get(`case/?id=${id}`);
        if (result?.status === 200) {
          setRequiredAmount(result.data.results[0].required_amount);
          const tempResult = await fetchApi.get(`case-specific-donation/?case_id=${id}`);
          if (tempResult?.status === 200) {
            const collectedAmount = tempResult.data.results.reduce((acc, donation) => acc + donation.donation_amount, 0);
            setCollectedAmount(collectedAmount);
            setDonations(tempResult.data.results.map(donation => ({
              date: new Date(donation.created_at).toLocaleDateString(),
              amount: donation.donation_amount
            })));
          }
        }
      } catch (error) {
        toast.error("Error fetching donation data");
        console.error("Error fetching donation data:", error);
      }
    };
    fetchDonationData();
  }, [id]);

  const data = {
    labels: donations.map((donation) => donation.date),
    datasets: [
      {
        label: "Donations",
        data: donations.map((donation) => donation.amount),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const remainingAmount = requiredAmount !== null && collectedAmount !== null 
    ? collectedAmount > requiredAmount 
      ? `Exceeded by ${collectedAmount - requiredAmount}` 
      : requiredAmount - collectedAmount 
    : null;

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h2 className="text-center">Donation Statistics of Case</h2>
        <div className="stats">
          <p>Required Amount: {requiredAmount}</p>
          <p>Collected Amount: {collectedAmount}</p>
          <p>Remaining Amount: {remainingAmount}</p>
        </div>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

export default CaseDonationStats;
