import React, { useState } from "react";
import ReceiptModel from "./ReceiptModel";

const Receipts = () => {
  const [receipts, setReceipts] = useState([1]);
  const [showModel, setShowModel] = useState(false);

  const toggleShowmodel = () => {
    setShowModel(true);
  };

  const medicines = [];
  return (
    <>
      {showModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div
            className="bg-gray-900 bg-opacity-80 h-full w-full fixed"
            onClick={() => {
              setShowModel(false);
            }}
          ></div>
          <ReceiptModel setShowModel={setShowModel} medicines={medicines} />
        </div>
      )}
      <section>
        <h1 className="md:text-4xl text-xl sm:text-2xl font-bold text-center pt-1 capture_fingerprint">
          Medicines Receipts
        </h1>
        <div className="flex justify-between items-baseline px-2 flex-col sm:flex-row">
          <div className="sidebar-search d-flex justify-content-between">
            <form className="formSearch">
              <input
                type="search"
                name="search"
                className="px-3 Search_input"
                placeholder="Search Here"
                // value={searchQuery}
                // onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div><span className="font-semibold">Current month sales: </span>50116 Pkr</div>
        </div>
        <div className="flex flex-wrap justify-evenly p-4">
          {receipts.length === 0 ? (
            <h4 className="py-20">No Receipts Found</h4>
          ) : (
            <div className="table-responsive">
              <table className="table align-middle mb-0 bg-white table-hover">
                <thead className="table-light m-3">
                  <tr className="m-3">
                    <th style={{ padding: "5px" }}>Patient</th>
                    <th style={{ padding: "5px" }}>Total amount</th>
                    <th style={{ padding: "5px" }}>Date</th>
                    <th style={{ padding: "5px" }}>Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  {receipts.map((data, index) => (
                    <tr
                      key={index}
                      className="table-row" // Add this class for styling
                      // onClick={() => handleTableRowClick(data?.id)}
                    >
                      <td style={{ verticalAlign: "middle" }}>
                        <div className="d-flex ">
                          <div className="">
                            <p className="fw-bold mb-3 mt-3 ">{data?.name}</p>
                          </div>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div className="dot d-flex align-items-start justify-content-start w-100">
                          {data?.application_priority}
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <span className="">{data?.status}</span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <button
                          onClick={toggleShowmodel}
                          className="bg-gray-900 text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded w-full md:w-auto z-10"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Receipts;
