/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../../Common/Navbar";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import Loader from '../../animation/Loader.json'

export const MemberDetailPage = () => {
  const [memberData, setMemberData] = useState([]); // Initialize as an object with an empty results array
  const { access } = useSelector(state => state.authSlice.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(null)
  const navigate = useNavigate();
  const location = useLocation();
  const [isCheck, setisCheck] = useState(location.state);
  console.log("is Checked", isCheck)

  const [selectedEducation, setSelectedEducation] = useState({
    'high_school': false,
    'college': false,
    'university': false,
  });
  const [selectedMaterial, setSelectedMaterial] = useState({
    'single': false,
    'married': false,
    'committed': false,
  });
  const [selectedMedication, setSelectedMedication] = useState({
    'yes': false,
    'no': false,
  });
  const [selectedwilling, setSelectedWilling] = useState({
    'yes': false,
    'no': false,
  });
  const [selectgender, setSelectGander] = useState({
    'Male': false,
    'Female': false,
  });


  const getMemberData = async () => {
    setIsLoading(true)
    try {
      const result = await fetchApi.get(`member/?search=${searchQuery}`, {
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setMemberData(result?.data);
        console.log('result data', result?.data);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log({ error });
    }
  }

 useEffect(() => {
    getMemberData();
  }, [searchQuery, access]);

  const handleTableRowClick = (id) => {
    console.log("Clicked on row with data:",id);
    navigate(`/memberlistingdetail/${id}`);
  };


  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(memberData.count / itemsPerPage);

  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;
    
    try {
      const selectedEducationList = Object.keys(selectedEducation).filter(
        (status) => selectedEducation[status]
      );

      const selectedMaterialList = Object.keys(selectedMaterial).filter(
        (status) => selectedMaterial[status]
      );

      const selectedMedicationList = Object.keys(selectedMedication)
      .filter((status) => selectedMedication[status])
      .map((status) => (status === 'yes' ? true : false));

      const selectedWillingList = Object.keys(selectedwilling)
      .filter((status) => selectedwilling[status])
      .map((status) => (status === 'yes' ? true : false));

      const selectedgenderList = Object.keys(selectgender).filter(
        (status) => selectgender[status]
      );

      const result = await fetchApi.get(
        `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}&education_or_qualification=${selectedEducationList.join(',')}&marital_status=${selectedMaterialList.join(',')}&permanent_medication=${selectedMedicationList.join(',')}&willing_to_study=${selectedWillingList.join(',')}&gender=${selectedgenderList.join(',')}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      return result;
    } catch (error) {
      throw error;
    }
  };

  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;
  
    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );
  
      for (let i = 2; i < totalPages; i++) {
        if (
          i >= currentPage - 1 &&
          i <= currentPage + 2
        ) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
  
      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  
    return pageButtons;
  };

  const handlePageClick = (pageNumber) => {
    console.log("pageNumbber", pageNumber)
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);
  
      // Fetch data for the selected page
      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            
            setMemberData(result?.data);
            // console.log("family data....", familyData)
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };


 const nextPageData = async () => {
  const nextPage = currentPage + 1;
  const newOffset = nextPage * itemsPerPage;

  setIsLoading(true);

  try {
    const result = await fetchApi.get(
      `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );

    if (result.status === 200) {
      // Concatenate the new data with the existing data
      setMemberData(result?.data);

      setCurrentPage(nextPage);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

const prevPageData = async () => {
  const prevPage = currentPage - 1;
  const newOffset = (prevPage - 1) * itemsPerPage;

  setIsLoading(true);

  try {
    const result = await fetchApi.get(
      `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );

    if (result.status === 200) {
      setMemberData(result?.data);
      setCurrentPage(prevPage);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

const handleEducation = (status) => {
  const updatedStatus = { ...selectedEducation, [status]: !selectedEducation[status] };
 setSelectedEducation(updatedStatus);
 setisCheck(false)
}
useEffect(()=>{
  if (Object.values(selectedEducation).some((value) => value)) {
    setIsLoading(true);
    fetchDataForPage(currentPage)
      .then((result) => {
        if (result.status === 200) {
          setMemberData(result?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
},[selectedEducation, currentPage])

const handleMaterial = (material) => {
  const updatedStatus = { ...selectedMaterial, [material]: !selectedMaterial[material] };
 setSelectedMaterial(updatedStatus);
 setisCheck(false)
}
useEffect(()=>{
  if (Object.values(selectedMaterial).some((value) => value)) {
    setIsLoading(true);
    fetchDataForPage(currentPage)
      .then((result) => {
        if (result.status === 200) {
          setMemberData(result?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
},[selectedMaterial, currentPage])

const handleMadication = (medication) => {
  const updatedStatus = { ...selectedMedication, [medication]: !selectedMedication[medication] };
 setSelectedMedication(updatedStatus);
}
useEffect(()=>{
  if (Object.values(selectedMedication).some((value) => value)) {
    setIsLoading(true);
    fetchDataForPage(currentPage)
      .then((result) => {
        if (result.status === 200) {
          setMemberData(result?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
},[selectedMedication, currentPage])


const handleWilling = (willing) => {
  const updatedStatus = { ...selectedwilling, [willing]: !selectedwilling[willing] };
 setSelectedWilling(updatedStatus);
}
useEffect(()=>{
  if (Object.values(selectedwilling).some((value) => value)) {
    setIsLoading(true);
    fetchDataForPage(currentPage)
      .then((result) => {
        if (result.status === 200) {
          setMemberData(result?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
},[selectedwilling, currentPage])

const handleGender= (gender) => {
  const updatedStatus = { ...selectgender, [gender]: !selectgender[gender] };
 setSelectGander(updatedStatus);
}
useEffect(()=>{
  if (Object.values(selectgender).some((value) => value)) {
    setIsLoading(true);
    fetchDataForPage(currentPage)
      .then((result) => {
        if (result.status === 200) {
          setMemberData(result?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
},[selectgender, currentPage])

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
      <div className="filter_Container">
      <div className="col-12 col-md-3 col-lg-3 " style={{paddingTop:'160px', paddingBottom:'20px'}}>
      <div  className="filter_sidebar" >
      <h6>Filters</h6>
      <div className="line_tag"></div>
      <heading className='heading'>Member Education</heading>
    <div className="filter_box">
    {Object.keys(selectedEducation).map(status => (
    <label key={status} className="filter_label">
    <input
        type="checkbox"
        checked={selectedEducation[status]}
        onChange={() => handleEducation(status)}
        style={{ marginRight: '5px', width: '20px', height: '20px' }}
    />
    {status}
    </label>
    ))}
    </div>
    <heading className='heading'>Member Matrial Status</heading>
    <div className="filter_box">
    {Object.keys(selectedMaterial).map(material => (
    <label key={material} className="filter_label">
    <input
        type="checkbox"
        checked={selectedMaterial[material]}
        onChange={() => handleMaterial(material)}
        className="checkbox"
    />
    {material}
    </label>
    ))}
    </div>
    <heading className='heading'>Parmanent Medication</heading>
    <div className="filter_box">
    {Object.keys(selectedMedication).map(medication => (
    <label key={medication} className="filter_label">
    <input
        type="checkbox"
        checked={selectedMedication[medication]}
        onChange={() => handleMadication(medication)}
        className="checkbox"
    />
    {medication}
    </label>
    ))}
    </div>
    <heading className='heading'>Willing to study</heading>
    <div className="filter_box">
    {Object.keys(selectedwilling).map(willing => (
    <label key={willing} className="filter_label">
    <input
        type="checkbox"
        checked={selectedwilling[willing]}
        onChange={() => handleWilling(willing)}
        className="checkbox"
    />
    {willing}
    </label>
    ))}
    </div>
    <heading className='heading'>Gender</heading>
    <div className="filter_box">
    {Object.keys(selectgender).map(gender => (
    <label key={gender} className="filter_label">
    <input
        type="checkbox"
        checked={selectgender[gender]}
        onChange={() => handleGender(gender)}
        className="checkbox"
    />
    {gender}
    </label>
    ))}
    </div>
     </div>
     </div>
     <div className="col-12 col-md-9 col-lg-9">
    <div className="Table_container " style={{minHeight:'100vh'}}>
    <div className="Table_heading">
    <div className='sidebar-search d-flex justify-content-between'>
    <form className="formSearch">
      <input
        type='search'
        name='search'
        className='px-3 Search_input'
        placeholder='Search Here'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </form>
    </div>
    <div className="Tbtn">
    <Link to="/memberRegistration">
      <button className="heading_btn">Register Member</button>
    </Link>
    </div>
    </div>
    <div className="Table_Section">
      <h5 className=" p-3">Member Listing Table</h5>
      {
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Lottie
          loop={true}
          animationData={Loader}
          style={{ width: '200px' }}
          className='auth-sidebar-video'
        />
      </div>
        ) : (
          <>
          <div className="table-responsive">
      <table className="table align-middle mb-0 bg-white table-hover">
        <thead className="table-light m-3">
          <tr className="m-3 ">
            <th style={{ padding: "10px", }}>Name</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>Education</th>
            {
              (  isCheck === true)? ( 
                <th style={{ padding: "10px", textAlign: 'center' }}>Marital Status</th>
              ) : (
                <th style={{ padding: "10px",textAlign: 'center'}}> {Object.values(selectedMaterial).some((value) => value) ? "Material Status" : "Monthly Income"}</th>
              )
            }
            {
              (  isCheck === true)? ( 
                <th style={{ padding: "10px", textAlign: 'center' }}>Parmanent Medication</th>
              ) :(
                <th style={{ padding: "10px", textAlign: 'center' }}>{Object.values(selectedMedication).some((value) => value) ? "Parmanent Medication" : "Medical Condition"}</th>
              )
            }
            <th style={{ padding: "10px", textAlign: 'center' }}>Gender</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>{Object.values(selectedwilling).some((value) => value) ? "Willing to study" : "Family"}</th>
          </tr>
        </thead>
        <tbody>
          {memberData?.results?.map((data, value) => {
            return (
                <tr
                key={value}
                className="table-row" // Add this class for styling
                onClick={() => handleTableRowClick(data?.id)}
                >
                <td style={{ verticalAlign: 'middle' }}>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="fw-bold mb-3 mt-3 ">{data?.first_name}</p>
                    </div>
                  </div>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <div
                    className='dot d-flex align-items-center justify-content-center w-100'
                  >
                    <span className='span-badge'></span>
                    {data?.education_or_qualification}
                  </div>
                </td>
                {
                  ( isCheck === true) ? (
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      {data?.marital_status}
                    </td>
                  ) :(
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                    {Object.values(selectedMaterial).some((value) => value) ? `${data?.marital_status}` : `${data?.monthly_income}`}
                  </td>
                  )
                }
                {
                  ( isCheck === true) ? (
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      {data?.permanent_medication ? 'yes' : 'no' }
                    </td>
                  ) :(
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                    {Object.values(selectedMedication).some((value) => value) ? `${data?.permanent_medication ? 'yes' : 'no' }` : `${data?.medical_condition}`}
                  </td>
                  )
                }
                <td style={{ verticalAlign: 'middle' }}>
                  {data?.gender}
                </td>
                <td style={{ verticalAlign: 'middle' }}>     
                      <p className="fw-normal mb-1 text-center"> {Object.values(selectedwilling).some((value) => value) ? `${data?.willing_to_study ? 'yes' : 'no'}` : `${data?.family?.name}`}</p>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex justify-content-between align-items-center mt-3">
        <Pagination>
        <Pagination.Prev
          onClick={prevPageData}
          disabled={currentPage === 1}
        />
        {renderPaginationItems()}
        <Pagination.Next
          onClick={nextPageData}
          disabled={currentPage === totalPages}
        />
      </Pagination>
        </div>
      </div>
          </>
        )
      }
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>
  )
}
