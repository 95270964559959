import React, { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from './style.module.css'
import { Autoplay, EffectCoverflow } from "swiper/modules";
import CardData from "./CardData";
import { Link } from "react-router-dom";

function Cardslider() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    // Load the first image in the CardData
    img.src = CardData[0].src;
  }, []);

  return (
    <div className="hero-marquee">
      <Swiper
        effect={"coverflow"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        modules={[Autoplay, EffectCoverflow]}
        className={styles.swiper_container}
      >
        {CardData.map((data, index) => (
          <SwiperSlide key={index} className={styles.swiper_slide}>
            <div className={styles.swiper_slide_div}>
              <div style={{ display: imageLoaded ? "none" : "inline" }}>
                <Blurhash
                  hash={data.code}
                  width={288}
                  height={352}
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              </div>
              <img
                src={data.src}
                className={styles.swiper_slide_img}
                alt=""
                style={{ display: imageLoaded ? "inline" : "none" }}
                onLoad={() => setImageLoaded(true)} // Update state when image is loaded
              />
              <button className={styles.swiper_slide_button}>
                <Link to="/donation" className="text-white">{data.text}</Link>
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Cardslider;
