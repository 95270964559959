/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate} from 'react-router-dom';
import Header from '../Common/Navbar';
import { fetchApi } from '../utlis/axios';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import Loader from '../animation/Loader.json'
import backArrow from '../assets/back-button.png'
import dropdown from '../assets/dropdown.png'
import { toast } from 'react-toastify'
import { Pagination } from "react-bootstrap";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export const FamilyListingdetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { access } = useSelector(state => state.authSlice.userInfo);
  const {role} = useSelector(state => state.authSlice.userInfo.user);
  const [location, setLocation] = useState(null)
  const [members, setMembers] = useState();
  const [isLoading, setIsLoading] = useState(null)
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [property, setProperty] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [familydata, setFamilydata] = useState([])
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [memberData, setMemberData] = useState()
  const [selectedView, setSelectedView] = useState('property');

    const fetchData = async ()=>{
      setIsLoading(true)
      try{
        const result = await fetchApi.get(`family/${id}/`,{
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        })
        if(result.status === 200)
        {
          setData(result?.data)
          console.log("family detail :", result?.data)
          setIsLoading(false)
        }
      } catch(error){
        setIsLoading(false)
        console.log({error})
        toast.error(error?.response?.data?.detail)
      }
    }

    const fetchFamilyData = async ()=>{
      setIsLoading(true);
      try{
        const result = await fetchApi.get(`family_detail/${id}`,{
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        })
        if(result?.status === 200)
        {
          setMembers(result?.data)
        }
      } catch(error)
      {
        console.log({error})
      }
    
    }

    const fetchPropertyData = async ()=>{
      setIsLoading(true);
      try{
        const result = await fetchApi.get(`property/?family__id=${id}`,{
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        })
        if(result?.status === 200)
        {
          setProperty(result?.data)
          console.log("property result :///", result?.data)
        }
      } catch(error)
      {
        console.log({error})
      }
    
    }

    useEffect(()=>{
      fetchData();    
    },[])

    useEffect(()=>{
      fetchFamilyData();
      fetchPropertyData();
    },[])

    const handleTableRowClick = (id) => {
      console.log("Clicked on row with data:",id);
      navigate(`/propertylistingdetail/${id}`);
    };

    const handleNext = () => {
      setStep(step + 1);
    };
  
    const handleBack = () => {
      setStep(step - 1);
    };

    const viewMember =(id)=> {
      console.log("Member id :", id)
      navigate(`/viewmember/${id}`)
    }

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`);
          setEditedData({ ...editedData, pin_location: `Latitude: ${latitude}, Longitude: ${longitude}` });
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`)
        }, (error) => {
          setLocation(`Error: ${error.message}`);
        });
      } else {
        setLocation('Geolocation is not supported in this browser.');
      }
    };

    const handleGoBack = () => {
      navigate(-1); // Go back to the previous page
    };

    console.log("user Role", role)

    const renderEditFields = () => {
      return (
        <>
    <div class="main-container" style={{ minHeight: '100vh' , backgroundColor:'white'}}>
    <div className='content' >
    <button className='btn2 btn2--tertiary  back-btn backButton' 
      style={{backgroundColor:'white'}}
      onClick={handleGoBack} >
        <img src={backArrow} alt='back-button' />
      </button>
      <div className='formgroup'>
      <h4 className='text-center'>Family Registration Form</h4>
      {/* <p >Your generosity opens up a world of possibilities for 
        the pediatric medicine of today and tomorrow.</p> */}
      <div className='field_Container'>
        {step === 1 && (
          <>
      <form onSubmit={handleNext}>
      <div className='group-fields'>
       <div className='field-container'>
        <h6 className='field-txt'>Family Name</h6>
        <input 
        type='text' 
        id='name' 
        name='name' 
        placeholder='Family Name' 
        className='formField' 
        value={editedData?.name}
        onChange={(e)=> setEditedData({...editedData, name:e.target.value})}
        required/>
       </div>
       <div className='field-container'>
        <h6 className='Status Statement'>Status Statement</h6>
       <input 
        type='text' 
        id='Status Statement' 
        name='Status Statement' 
        placeholder='Status Statement' 
        className='formField' 
        value={editedData?.status_statement}
        onChange={(e)=> setEditedData({...editedData, status_statement:e.target.value})}
        required/>
      </div>
      </div>

      <div className="group-fields">
        <div className='field-container'>
        <h6 className='field-txt'>Village</h6>
        <input
          type="text"
          id="Village"
          name="Village"
          placeholder="Village"
          className="formField"
          value={editedData?.village_or_city}
          onChange={(e)=> setEditedData({...editedData, village_or_city:e.target.value})}
          required
        />
        </div>
        <div className='field-container'>
          <h6 className='field-txt'>Tahsil</h6>
        <input
          type="text"
          id="Tahsil"
          name="Tahsil"
          placeholder="Tahsil"
          className="formField"
          value={editedData?.tehsil}
          onChange={(e)=> setEditedData({...editedData, tehsil:e.target.value})}
          required
        />
        </div>
       </div>

      <div className="group-fields">
        <div className='field-container'>
        <h6 className='field-txt'>District</h6>
        <input
          type="text"
          id="District"
          name="District"
          placeholder="District"
          className="formField"
          value={editedData?.district}
          onChange={(e)=> setEditedData({...editedData, district:e.target.value})}
          required
        />
        </div>
        <div className='field-container'>
          <h6 className='Post code'>Post code</h6>
        <input
          type="text"
          id="Post code"
          name="Post code"
          placeholder="Post code"
          className="formField"
          value={editedData?.post_code}
          onChange={(e)=> setEditedData({...editedData, post_code:e.target.value})}
          required
        />
        </div>
      </div>

        <div className="group-fields">
        <div className='field-container'>
        <h6 className='field-txt'>pin-location</h6>
        <input
          type="button"
          id="pin-location"
          name="pin-location"
          onClick={getLocation} 
          style={{ display: "none" }} 
        />
          <input
          type="text"
          id="pin-location-text"
          name="cnicfront-text"
          placeholder="Pin Location"
          onClick={() => document.getElementById("pin-location").click()} 
          className="formField custom-field"
          value={editedData?.pin_location}
          readOnly 
          required
          />
          </div>
          <div className='field-container'>
          <h6 className='field-txt'>Application Priority</h6>
          <select
          type="text"
          id="Application Priority"
          name="Application Priority"
          placeholder="Application Priority"
          className="formField custom-select"
          value={editedData?.application_priority}
          onChange={(e)=> setEditedData({...editedData, application_priority:e.target.value})}
          required
          >
         
          <option>high</option>
          <option>low</option>
          <option>normal</option>
        </select>
        </div>
         </div>

          <div className="group-fields">
            <div className='field-container'>
            <h6 className='field-txt'>Address</h6>
            <input
              type="text"
              id="Address"
              name="Address"
              placeholder="Address"
              className="formField"
              value={editedData?.address}
              onChange={(e)=> setEditedData({...editedData, address:e.target.value})}
              required
            />
            </div>
            <div className='field-container'>
            <h6 className='field-txt'>Description</h6>
            <input
              type="text"
              id="Description"
              name="Description"
              placeholder="Description"
              className="formField"
              value={editedData?.description}
              onChange={(e)=> setEditedData({...editedData, description:e.target.value})}
              required
            />
            </div>
          </div>
      
      
      <div className='next_btn'>
          <button className="Registration_btn">
             Next
          </button>
      </div>
      </form>
       </>
        )}
        {step ===2 &&(
          <>   
         <form onSubmit={handleUpdate} className='form_responsive'>
           <div className='group-fields'>
          <div className='field-container'>
          <h6 className='field-txt'>Father Name</h6>
          <input 
          type='text' 
          id='father_name' 
          name='father_name' 
          placeholder='Father Name' 
          className='formField' 
          value={editedData?.father_name}
          onChange={(e)=> setEditedData({...editedData, father_name:e.target.value})}
          required/>
          </div>
      
          <div className='field-container'>
            <h6 className='field-txt'>Mother Name</h6>
            <input 
              type='text' 
              id='mother_name' 
              name='mother_name' 
              placeholder='Mother Name' 
              className='formField' 
              value={editedData?.mother_name}
              onChange={(e)=> setEditedData({...editedData, mother_name:e.target.value})}
              required/>
          </div>
          </div>

         <div className="group-fields">
             <div className='field-container'>
             <h6 className='field-txt'>Number of Sons</h6>
             <input
               type="number"
               id="Number of Sons"
               name="Number of Sons"
               placeholder="Number of Sons"
               className="formField"
               value={editedData?.number_of_sons}
               onChange={(e) => {
                 const inputValue = e.target.value.slice(0, 3); 
                 setEditedData({ ...editedData, number_of_sons: inputValue });
               }}
               maxLength="3"
               required
             />
             </div>
             <div className='field-container'>
              <h6 className='field-txt'>Number of Daughters</h6>
             <input
               type="number"
               id="Number of Daughter"
               name="Number of Daughter"
               placeholder="Number of Daughters"
               className="formField"
               value={editedData?.number_of_daughters}
               onChange={(e) => {
                 const inputValue = e.target.value.slice(0, 3); 
                 setEditedData({ ...editedData, number_of_daughters: inputValue });
               }}
               maxLength="3"
               required
                   />
             </div>
             </div>

           <div className='group-fields'>
          <div className='field-container'>
          <h6 className='field-txt'>Contact Number</h6>
          <PhoneInput
            type='text'
            value={editedData?.contact_number}
            name='contact_number'
            placeholder='Enter your phone'
            required
            onChange={(value) => setEditedData({ ...editedData, contact_number: value })}
            style={{ 
            display: 'flex', 
            background: 'white', 
            borderRadius: '20px', 
            padding: '0px 10px',
            border:'2px solid #f4f4f4'
            }}
            />
          </div>
           </div>
           <div className='btn_conatiner'>
          <button onClick={handleBack} className="Registration_Backbtn">
            Back
          </button>
         <button className="Registration_btn" disabled={loading}>
          {loading ? 'Loading...' : 'Update'}
        </button>
           </div>
           </form>
          </>
        )
        }
               
      </div>
    </div>
    
    </div>
  </div>
        </>
      );
    };

    const handleEditClick = () => {
      setIsEditing(true);
      setEditedData({ ...data });
    };
    
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const result = await fetchApi.put(`family/${id}/`, editedData, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      if (result.status === 200) {
        setData({ ...data, ...editedData });
        setIsEditing(false);
        setEditedData({});
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.detail)
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  }

  const Registrationfrom = (id) =>{
    navigate('/memberRegistration', { state: { familyId: id } });
  }

  const RegisterProperty = (id) =>{
    navigate('/propertyRegistration', { state: { familyId: id } });
  }

  const handleStatus = async (e) => {
		setData((prev) => ({ ...prev, status: e.target.id }))
		const updatedStatus = e.target.id
		const data = new FormData()
		data.append('status', updatedStatus)
		try {
			const result = await fetchApi.patch(`family/${id}/`, data,{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				navigate(-1);
			}
		} catch (error) {
		console.log({error})
    toast.error(error?.response?.data?.detail)
		}
	}

  const fetchFamilyMembers = async ()=>{
    try{
        const result = await fetchApi.get(`member/?family__id=${id}`,{
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        })
        if(result?.status === 200)
        setMemberData(result?.data); 
      } catch(error){
        console.log({error})
      }
    }

  useEffect(()=>{
    fetchFamilyMembers();
  },[id])

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = 1;
  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;
    
    try {
      const result = await fetchApi.get(
        `property/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      return result;
    } catch (error) {
      throw error;
    }
  };
  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;
  
    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );
  
      for (let i = 2; i < totalPages; i++) {
        if (
          i >= currentPage - 1 &&
          i <= currentPage + 2
        ) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
  
      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  
    return pageButtons;
  };

  const handlePageClick = (pageNumber) => {
    console.log("pageNumbber", pageNumber)
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);
  
      // Fetch data for the selected page
      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            
            setFamilydata(result?.data);
            // console.log("family data....", familyData)
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const nextPageData = async () => {
    const nextPage = currentPage + 1;
    const newOffset = nextPage * itemsPerPage;
  
    setIsLoading(true);
  
    try {
      const result = await fetchApi.get(
        `property/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      if (result.status === 200) {
        // Concatenate the new data with the existing data
        setFamilydata(result?.data);
  
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const prevPageData = async () => {
    const prevPage = currentPage - 1;
    const newOffset = (prevPage - 1) * itemsPerPage;
  
    setIsLoading(true);
  
    try {
      const result = await fetchApi.get(
        `property/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      if (result.status === 200) {
        setFamilydata(result?.data);
        setCurrentPage(prevPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleToggleClick = (view) => {
    setSelectedView(view);
  };

  const ViewMemberfunc =(id)=>{
    navigate(`/applicationMember/${id}`);
 }



  return (
    <>
    <Header/>
    <div style={{backgroundColor:'#F8F7F4', minHeight:'100vh'}} className='Table_container md:px-10 px-5'>
    <button className='btn2 btn2--tertiary  back-btn backButton' 
      style={{backgroundColor:'white'}}
      onClick={handleGoBack} >
        <img src={backArrow} alt='back-button' />
      </button>
    <div className='Table_Section '>
      {
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Lottie
            loop={true}
            animationData={Loader}
            style={{ width: '200px' }}
            className='auth-sidebar-video'
          />
        </div>
        ) :(
          <>
          {
            isEditing ? (
               renderEditFields()
            ) :(
              <>
      <div className='container-small job-details-page-container'>
      <div className='main-content-container'>
      <div className='font-label'>
        Family Detail Page
      </div>
     
      <h1 className='margin-t-16 job-details-title'>
        {data?.name}
      </h1>
      <div className='js-share-actions margin-t-20 display-flex'>
      <a className='btn2 btn2--tertiary facebook-btn margin-r-16'>
        <b style={{marginRight:'20px'}}>Male</b>
        {members?.male_members}
      </a>
      <a className='btn2 btn2--tertiary facebook-btn margin-r-16'>
        <b style={{marginRight:'20px'}}>Female</b>
        {members?.female_members}
      </a>
      {/* <a
        className='btn2 btn2--tertiary facebook-btn margin-r-16'
        style={{ backgroundColor: '#82EADD' }}
        onClick={()=> viewMember(data?.id)}
        >
        View Members
      </a> */}
      </div>
        <div className='margin-t-16 job-details-description'>
        <div className='detail_MainConatiner'>
        <div className='detailDiv'>
        <div>
          <b>Village / City</b>
          <p>{data?.village_or_city}</p>
        </div>
        <div >
          <b style={{marginRight:'85px'}}>Tahsil</b>
          <p>{data?.tehsil}</p>
        </div>
       </div>
       <div className='detailDiv'>
        <div>
          <b>District</b>
          <p>{data?.district}</p>
        </div>
        <div >
          <b style={{marginRight:'50px'}}>Post Code</b>
          <p>{data?.post_code}</p>
        </div>
       </div>
       <div className='detailDiv'>
        <div>
          <b>Discription</b>
          <p>{data?.description}</p>
        </div>
        <div >
          <b>Status Statement</b>
          <p>{data?.status_statement}</p>
        </div>
       </div>
       <div className='detailDiv'>
        <div>
          <b>Father Name</b>
          <p>{data?.father_name !== null ? data?.father_name : "null"}</p>
        </div>
        <div >
          <b>Mother Name</b>
          <p>{data?.mother_name !== null ? data?.mother_name : "null"}</p>
        </div>
       </div>
       <div className='detailDiv'>
        <div>
          <b>No. of Sons</b>
          <p>{data?.number_of_sons !== null ? data?.number_of_sons : "null"}</p>
        </div>
        <div>
        <b>No. of Daughter</b>
        <p>{data?.number_of_daughters !== null ? data?.number_of_daughters : "null"}</p>
      </div>
       </div>

       <div className='detailDiv'>
        <div>
          <b>Address</b>
          <p>{data?.address}</p>
        </div>
        <div >
          <b style={{marginRight:'30px'}}>Pin Location</b>
          <p style={{width:'200px'}}>{data?.pin_location}</p>
        </div>
       </div>
        </div>
      </div>
      </div>
      
      <div className='sidebar-container'>
        <div className='buttonConatiner'>
        <div className='sidebar_edit'>
           <button className='edit_btn' onClick={handleEditClick}>Edit</button>
        </div>
        <div className='sidebar_edit'>
           <button className='edit_btn' onClick={()=> Registrationfrom(data.id)}>Add Member</button>
        </div>
        <div className='sidebar_edit'>
           <button className='edit_btn' onClick={()=> RegisterProperty(data.id)}>Add Property</button>
        </div>
        </div>
      <div className='sidebar-content-container'>
      <div className='margin-t-24 family-status' >
        <div className='font-sublabel mt-3' >
         <b> Status</b>
        </div>
        <div className='font-label margin-t-8'>
          {
            role === 'admin' ? (
              <>
              <a 
        className='btn' 
        style={{
          backgroundColor:'#82EADD', 
          padding:'3px 8px',
          borderRadius:'30px',
          display:"flex"
        }}
        onClick={() => {
          setIsOpen((prevState) => !prevState)
        }}
        >
        {data?.status}<span><img src={dropdown} alt='img'/></span>
        </a>
        <div
					className='status-dropdown'
          style={{
            opacity: isOpen ? 1 : 0,
            visibility: isOpen ? 'visible' : 'hidden',
          }}
					>
          <p
            className=' text-capitalize accepted '
            id='accepted'
            onClick={handleStatus}
          >
            accepted
          </p>
          <p
            className=' text-capitalize rejected'
            id='rejected'
            onClick={handleStatus}
          >
            rejected
          </p>
          <p
            className=' text-capitalize  in-review'
            id='in-review'
            onClick={handleStatus}
          >
            in-review
          </p>
          <p
            className=' text-capitalize  hold'
            id='hold'
            onClick={handleStatus}
          >
            hold
          </p>
        </div>
              </>
            ):(
              <a 
              className='btn' 
              style={{
                backgroundColor:'#82EADD', 
                padding:'3px 8px',
                borderRadius:'30px'
              }}
             
              >
              {data?.status}
              </a>
            )
          }
        
        </div>
      </div>
      <div className='margin-t-24'>
        <div className='font-sublabel'>
          <b>Total Member</b>
        </div>
        <div className='font-label margin-t-8'>
          {members?.total_members}
        </div>
      </div>
      <div className='margin-t-24'>
        <div className='font-sublabel'>
        <b>Application Priority</b>
        </div>
        <div className='font-label margin-t-8'>
        {data?.application_priority}
        </div>
      </div>
      <div className='margin-t-24'>
        <div className='font-sublabel'>
        <b>Contact Number</b>
        </div>
        <div className='font-label margin-t-8'>
        {data?.contact_number !== null ? data?.contact_number : "null"}
        </div>
      </div>
      </div>
      </div>
      </div> 
      </>
            )
          }

      <div className='d-flex justify-content-center mt-5'>
        <button
          className={`toggle-button accepted_btn ${selectedView === 'property' ? 'active' : ''}`}
          onClick={() => handleToggleClick('property')}
          style={{
            backgroundColor: selectedView === 'property' ? '#de1285' : '#f8f9fa',
            color: selectedView === 'property' ? 'white' : 'black',
          }}
        >
          Property
        </button>
        <button
          className={`toggle-button rejected_btn ${selectedView === 'members' ? 'active' : ''}`}
          onClick={() => handleToggleClick('members')}
          style={{
            backgroundColor: selectedView === 'members' ? '#de1285' : '#f8f9fa',
            color: selectedView === 'members' ? 'white' : 'black',
          }}
        >
          Members
        </button>
      </div>

      <div className="propertyListing">
        {selectedView === 'property' ? (
          <>
            <div className='propertyListing'>
        <h4 className='mb-4'>Family Property</h4>

        {property?.results?.length === 0 ? (
          <p>No property registered for this family.</p>
        ) : (
        <>
        <div class="table-responsive">
        <table className="table align-middle mb-0 bg-white table-hover">
          <thead className="table-light m-3">
          <tr className="m-3 ">
          <th style={{ padding: "10px", }}>Property Name</th>
          <th style={{ padding: "10px", textAlign: 'center' }}>Property Type</th>
          <th style={{ padding: "10px", textAlign: 'center' }}>Worth</th>
          <th style={{ padding: "10px", textAlign: 'center' }}>Property Since</th>
          <th style={{ padding: "10px", textAlign: 'center' }}>Family </th>
          </tr>
          </thead>
          <tbody>
            {property?.results?.map((data, value) => {
              return (
                <tr
                  key={value}
                  className="table-row" // Add this class for styling
                  onClick={() => handleTableRowClick(data?.id)}
                >
                  <td style={{ verticalAlign: 'middle' }}>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="fw-bold mb-3 mt-3 ">{data?.name}</p>
                      </div>
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div
                      className='dot d-flex align-items-center justify-content-center w-100'
                    >
                      <span className='span-badge'></span>
                      {data?.type}
                    </div>
                  </td>
                  <td className="text-center" style={{ verticalAlign: 'middle' }}>
                    {data?.worth}
                  </td>
                  <td className="text-center" style={{ verticalAlign: 'middle' }}>
                    {data?.owned_since}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <p className="fw-normal mb-1 text-center">{data?.family?.name}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <Pagination>
              <Pagination.Prev
                onClick={prevPageData}
                disabled={currentPage === 1} />
              {renderPaginationItems()}
              <Pagination.Next
                onClick={nextPageData}
                disabled={currentPage === totalPages} />
            </Pagination>
          </div>
      </div>
      </>
        )}
        </div>
          </>
        ) : (
          <div className='container px-md-0 mt-4 mb-4' >
    <h4>Members</h4> 
    <div className='row'>
    {
        memberData?.results?.map((item)=>{
        return(
            <>
    <div className='col-md-5 gy-4 ' key={item?.id}>
    <div className='userCard position-relative'>
    <div
    className='position-absolute d-none user-action_btn'
    style={{ right: '25px' }}
    >
        <button
        className='border-0 bg-transparent'
        // onClick={() => deleteUser(user?.id)}
    >
    <i
        className='fa fa-trash'
        style={{
            color: 'white',
            cursor: 'pointer',
            fontSize: '22px',
        }}
    ></i>
    </button>  
    </div>
    <div className='card_title justify-content-center'>
        <img
            src={require(`../assets/${item?.gender === 'Male' ? 'man' : 'woman'}.png`)}
            alt=''
            className='profile'
            style={{
                width: '70px',
                height: '70px',
                borderRadius: '50%',
                objectFit: 'cover',
            }}
        />
    </div>
    <div className='mainCard'>
    <div className='infoContainer'>
        <div className='infoCard'>
            <h6>Name</h6>
            <p>{item?.first_name}</p>
        </div>
        <div className='infoCard'>
            <h6>CNIC</h6>
            <p>{item?.cnic_number}</p>
        </div>
    </div>
    <div className='infoContainer'>
        <div className='infoCard'>
            <h6>Education</h6>
            <p>{item?.education_or_qualification }</p>
        </div>
        <div className='infoCard'>
            <h6>Willing to Study</h6>
            <p>{item?.willing_to_study ? 'Yes' : 'No'}</p>
        </div>
    </div>
    <div className='infoContainer'>
        <div className='infoCard'>
            <h6 style={{width:'180px'}}>Parmanent Medication</h6>
            <p>{item?.permanent_medication ? 'Yes' : 'No'}</p>
        </div>
        <div className='infoCard' style={{marginRight:'90px'}}>
            <h6>Age</h6>
            <p>{item?.age}</p>
        </div>
    </div>
    <div className='cmn-btn justify-content-center'>
    <a
       onClick={()=>ViewMemberfunc(item?.id)}
       className="btn text-white px-3 py-2 "
       style={{ 
       backgroundColor: "#DE1285",
       borderRadius: 30,
       width:'100%'
    }}
    >
    View
    </a>
    </div>
    </div>
    </div>
    </div>
            </>
        )
        })
    }
    </div>
    </div>
        )}
      </div>
          </>
        )
      }
    </div>
    </div>
    </>
  )
}