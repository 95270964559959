// Dashboard.js
import React from 'react';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import Sidebar from './Sidebar';
import { FamilyDashboard } from './Family/FamilyDashboard';
import { MemberDashboard } from './Member/MemberDashboard';
import { DonationDashboard } from './Donation/DonationDashboard';
import Header from '../Common/Navbar';
import { AuthWrapper } from '../AuthWrapper';
import { Application } from './Applicaton/Application';
import { Programs } from './Programs/Programs';
import { FamilyDonation } from './Family_Donation/FamilyDonation';
import { KwfDonation } from './Kwf_Donation/KwfDonation';
import { AllGraph } from './AllGraph/AllGraph';
import { FamilyProgram } from './Programs/FamilyProgram';
import CaptureFingerprint from './Fingerprint/CaptureFingerprint';
import Receipts from './Receipts/Receipts';
import {MedicalStaff} from './Medicalstaff/MedicalStaff';
import MonthlyReport from './MonthlyReport/MonthlyReport';

const Dashboard = () => {
  const isMobile = useMediaQuery({ maxWidth: 850 }); // Check if the device is mobile

  return (
    <AuthWrapper>
      <div className={`wrapper ${isMobile ? 'mobile' : ''}`}>
        <Header />
        <div className="content-wrapper" style={{ minHeight: '100vh', overflowX: 'hidden' }}> 
          {/*{!isMobile && <Sidebar />}*/} {/* Render sidebar only if not mobile */}
          <Sidebar />
          <div id="content" className={`dashboard-content overflow-x-auto ${isMobile ? 'mobile-content' : ''}`}>
            <Routes>
              <Route
                index
                element={<AllGraph />}
              />
              <Route
                path="familydashboard"
                element={<FamilyDashboard />}
              />
              <Route
                path="memberdashboard"
                element={<MemberDashboard />}
              />
              <Route
                path="donationdashboard"
                element={<DonationDashboard />}
              />
              <Route
                path="applications"
                element={<Application />}
              />
              <Route
                path="program"
                element={<Programs />}
              />
               <Route
                path="familyProgram"
                element={<FamilyProgram />}
              />
              <Route
                path="family_donation"
                element={<FamilyDonation />}
              />
              <Route
                path="kwf_donation"
                element={<KwfDonation />}
              />
              <Route
                path="kwf_medical_staff"
                element={<MedicalStaff/>}
              />
              <Route
                path="dashboard_graph"
                element={<AllGraph />}
              />
              <Route
                path="verify_fingerprint"
                element={<CaptureFingerprint />}
              />
              <Route
                path="kwf_receipts"
                element={<Receipts />}
              />
              <Route
                path="kwf_monthlyreport"
                element={<MonthlyReport/>}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Dashboard;
