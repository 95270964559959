import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Common/Navbar";
import { Link, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import CasePostModel from "./CasePostModel";
import { fetchApi } from "../utlis/axios";
import { BsFillSendFill } from "react-icons/bs";
import { FaDonate } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { IoIosShareAlt } from "react-icons/io";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaLink } from "react-icons/fa6";
import { toast } from "react-toastify";

const CasesFeeds = () => {
  const [showModel, setShowModel] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [comments, setComments] = useState([]);
  const [isShowComment, setIsShowComment] = useState(null);
  const initialRender = useRef(true);
  const [commentsCounts, setCommentsCounts] = useState({});
  const [commentsPage, setCommentsPage] = useState({});
  const [hasMoreComments, setHasMoreComments] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [donationsCounts, setDonationsCounts] = useState({});
  const [isHoveredId, setIsHoveredId] = useState(null);
  const [shareMenu, setShareMenu] = useState(false);
  const {id} =useParams();


  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
  const videoExtensions = [
    "mp4",
    "avi",
    "mov",
    "mkv",
    "wmv",
    "flv",
    "webm",
    "mpeg",
    "m4v",
  ];
  const [postImages, setPostImages] = useState([]);
  const [postVideos, setPostVideos] = useState([]);

  const toggleShowmodel = (img, vid) => {
    setPostImages(img);
    setPostVideos(vid);
    setShowModel(true);
  };

  const submitComment = async (e, caseID) => {
    e.preventDefault();
    try {
      const data = {
        case_id: caseID,
        is_active: true,
        text: commentValue,
      };
      const result = await fetchApi.post("case-comment/", data);
      if (result?.status === 201) {
        // Prepend the new comment to the comments array
        setComments((prevComments) => ({
          ...prevComments,
          [caseID]: [result.data, ...(prevComments[caseID] || [])],
        }));
        setCommentValue("");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const toggleComments = async (postId) => {
    if (!comments[postId]) {
      await fetchComments(postId, 1);
    }
    setCommentsPage((prevState) => ({
      ...prevState,
      [postId]: 1,
    }));
  };

  const fetchComments = async (postId, page) => {
    const newOffset = (page - 1) * 10;
    setCommentLoading(true);
    const response = await fetchApi.get(
      `case-comment/?ordering=-created_at&case_id=${postId}&offset=${newOffset}&page=${page}`
    );
    setComments((prevState) => ({
      ...prevState,
      [postId]: [...(prevState[postId] || []), ...response?.data?.results],
    }));
    setCommentLoading(false);
    setHasMoreComments(response?.data?.next != null);
  };

  const loadMoreComments = async (postId) => {
    const nextPage = commentsPage[postId] + 1;
    await fetchComments(postId, nextPage);
    setCommentsPage((prevState) => ({
      ...prevState,
      [postId]: nextPage,
    }));
  };

  useEffect(() => {
    const GetCases = async () => {
      setIsLoading(true);
      try {
        const result = await fetchApi.get(
          `case/?search=${id}`
        );
        if (result?.status === 200) {
          let results = result?.data?.results;
          setFeeds(results);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log({ error });
      }
    };

      GetCases();

  }, []);

  useEffect(() => {
    const fetchCommentsCounts = async () => {
      const counts = {};
      for (const feed of feeds) {
        const response = await fetchApi.get(
          `case-comment/?case_id=${feed?.id}`
        );
        counts[feed.id] = response?.data?.count;
      }
      setCommentsCounts(counts);
    };
    fetchCommentsCounts();
  }, [feeds, comments]);

  useEffect(() => {
    const fetchDonationsCounts = async () => {
      const counts = {};
      for (const feed of feeds) {
        const response = await fetchApi.get(
          `case-specific-donation/?case_id=${feed?.id}`
        );
        counts[feed.id] = response?.data?.count;
      }
      setDonationsCounts(counts);
    };
    fetchDonationsCounts();
  }, [feeds]);



  const toggleDescription = (index) => {
    setShowFullDescription((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleHover = (id) => {
    setIsHoveredId(id);
  };

  const handleHoverEnd = () => {
    setIsHoveredId(null);
  };

  const handleCopy = () => {
    toast.dismiss()
    toast.success("Link Copied")
  };

  return (
    <>
      {showModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div
            className="bg-gray-950 bg-opacity-95 h-full w-full fixed"
            onClick={() => {
              setShowModel(false);
            }}
          ></div>
          <CasePostModel
            setShowModel={setShowModel}
            postImages={postImages}
            postVideos={postVideos}
          />
        </div>
      )}
      <Navbar />
      <div className="bg-[#f8f7f4] h-screen">
        <div className="sm:flex justify-between items-center p-5">
          <div className="text-4xl font-bold">Cases Feeds</div>
          <div className="Tbtn">
            <Link to="/postcase">
              <button className="bg-[#DE1285] rounded-3xl">
                Post new case
              </button>
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className=" lg:w-2/4 w-3/4 mx-2">
            {isLoading  ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Lottie
                  loop={true}
                  animationData={Loader}
                  style={{ width: "200px" }}
                  className="auth-sidebar-video"
                />
              </div>
            ) : (
<>
{(() => {
                  const filteredFeeds = feeds
                  if (filteredFeeds.length === 0) {
                    return (
                      <div className="h-screen w-full text-center">
                        <p className="text-4xl font-semibold py-5">
                          No data found
                        </p>{" "}
                      </div>
                    );
                  }

                  return filteredFeeds.map((item, index) => {
                    return (
                      <div
                        className="bg-white w-full rounded-xl shadow-md p-4 my-3"
                        id={item?.id}
                        element-index={index}
                        key={index}
                        onMouseOver={() => handleHover(item?.id)}
                          onMouseLeave={() => {
                            handleHoverEnd();
                            setShareMenu(false);
                        }}
                      >
                        <div className="flex items-center ">
                          <div className="rounded-full font-semibold p-2 w-14 text-xl h-14 border border-black flex justify-center items-center bg-gradient-to-br from-pink-600">
                            KWF
                          </div>
                          <div className="ml-2 flex flex-col gap-0">
                            <div className="flex flex-wrap items-baseline">
                              <span className="font-semibold text-xl">
                                {item?.title}
                              </span>
                              <span className="text-gray-600 text-sm pl-2">
                                {(() => {
                                  const dateString = item?.created_at;
                                  if (!dateString) return null; // Handle the case when dateString is null or undefined

                                  const date = new Date(dateString);
                                  const day = date
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0");
                                  const month = (date.getMonth() + 1)
                                    .toString()
                                    .padStart(2, "0"); // Months are zero-based
                                  const year = date.getFullYear();

                                  const formattedDate = `${day}-${month}-${year}`;

                                  return (
                                    <span className="text-sm">
                                      {formattedDate}
                                    </span>
                                  );
                                })()}
                              </span>
                            </div>
                            <span className="text-gray-600">
                              Khadim Walfare Foundation
                            </span>
                          </div>
                        </div>
                        <div className="relative">
                          <p className="mb-2">
                            {showFullDescription[index]
                              ? item.description
                              : item.description.length > 120
                              ? `${item.description.slice(0, 120)}...`
                              : item.description}
                            {item.description.length > 120 && (
                              <span
                                className="text-blue-500 font-semibold text-sm hover:underline cursor-pointer"
                                onClick={() => toggleDescription(index)}
                              >
                                {showFullDescription[index]
                                  ? " see less"
                                  : " see more"}
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {(() => {
                            let images = [];
                            let videos = [];
                            item?.attachment_before_completion.forEach(
                              (file) => {
                                if (file?.file !== null) {
                                  const fileNameParts = file?.file?.split(".");
                                  const fileExtension =
                                    fileNameParts[fileNameParts.length - 1];
                                  if (imageExtensions.includes(fileExtension)) {
                                    images.push(file?.file);
                                  } else if (
                                    videoExtensions.includes(fileExtension)
                                  ) {
                                    videos.push(file?.file);
                                  }
                                }
                              }
                            );
                            return (
                              <>
                                {images?.length === 1 &&
                                videos?.length === 1 ? (
                                  <div className="flex gap-2">
                                    <img
                                      src={images[0]}
                                      onClick={() =>
                                        toggleShowmodel(images, videos)
                                      }
                                      key={98}
                                      className="w-1/2 h-60 object-cover cursor-pointer"
                                      alt="post-img"
                                      element-index={0}
                                    />
                                    <video
                                      onClick={() =>
                                        toggleShowmodel(images, videos)
                                      }
                                      className="w-1/2 h-60 object-cover cursor-pointer"
                                      src={videos[0]}
                                      controls
                                    />
                                  </div>
                                ) : (
                                  <div className="flex gap-2">
                                    {images?.slice(0, 2).map((image, index) => (
                                      <div
                                        className={`image-overlays w-1/2 h-60 object-cover relative `}
                                        key={index}
                                      >
                                        <img
                                          src={image}
                                          onClick={() =>
                                            toggleShowmodel(images, videos)
                                          }
                                          id={image.id}
                                          key={index}
                                          alt="post-img"
                                          element-index={index}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            cursor: "pointer",
                                          }}
                                        />
                                        {images?.length > 1 && index === 1 && (
                                          <div
                                            className={`overlay overlay-${
                                              index + 1
                                            }`}
                                            element-index={index}
                                            onClick={() =>
                                              toggleShowmodel(images, videos)
                                            }
                                          >
                                            + {images?.length - 1}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </>
                            );
                          })()}
                        </div>
                        <div className="flex gap-2 justify-end pt-2 -mb-2 items-baseline">
                          <div className="text-xs">
                            {commentsCounts[item.id] === 0 ? (
                              ""
                            ) : commentsCounts[item.id] === 1 ? (
                              <span
                                className="hover:underline hover:text-blue-700 cursor-pointer"
                                onClick={() => {
                                  toggleComments(item?.id);
                                  setIsShowComment((prevIndex) =>
                                    prevIndex === index ? null : index
                                  );
                                }}
                              >
                                1 comment
                              </span>
                            ) : (
                              commentsCounts[item.id] > 1 && (
                                <span
                                  className="hover:underline hover:text-blue-700 cursor-pointer"
                                  onClick={() => {
                                    toggleComments(item?.id);
                                    setIsShowComment((prevIndex) =>
                                      prevIndex === index ? null : index
                                    );
                                  }}
                                >
                                  {commentsCounts[item.id]} comments
                                </span>
                              )
                            )}
                          </div>
                          {(item?.status === "approved" ||
                            item?.status === "in-progress") && (
                            <>
                              {donationsCounts[item.id] > 0 &&
                                commentsCounts[item.id] > 0 && (
                                  <span className="text-4xl text-gray-500 -mx-1">
                                    .
                                  </span>
                                )}
                              <div className="hover:text-blue-700 text-xs hover:underline cursor-pointer">
                                <Link to={`/donation/${item?.id}`}>
                                  {donationsCounts[item.id] === 0 ? (
                                    ""
                                  ) : donationsCounts[item.id] === 1 ? (
                                    <span className="hover:underline hover:text-blue-700 cursor-pointer">
                                      1 donation
                                    </span>
                                  ) : (
                                    donationsCounts[item.id] > 1 && (
                                      <span className="hover:underline hover:text-blue-700 cursor-pointer">
                                        {donationsCounts[item.id]} donations
                                      </span>
                                    )
                                  )}
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                        <hr className="-mb-3 " />
                        <div className="flex justify-between mt-4 mx-2">
                          <div className="flex gap-1 items-center ">
                            <div
                              className="cursor-pointer flex gap-2 h-6"
                              onClick={() => {
                                toggleComments(item?.id);
                                setIsShowComment((prevIndex) =>
                                  prevIndex === index ? null : index
                                );
                              }}
                            >
                              <FaComments className="text-2xl " />
                              <p>Comment</p>
                            </div>
                          </div>
                          {(item?.status === "approved" ||
                            item?.status === "in-progress") && (
                            <>
                              <div>
                                <Link to={`/donation/${item?.id}`}>
                                  <div className="flex gap-2 h-6">
                                    <FaDonate className="text-2xl" />
                                    <p>Donate</p>
                                  </div>
                                </Link>
                              </div>
                              <div>
                                <div
                                  className="flex gap-2 h-6 cursor-pointer relative"
                                  onClick={() => setShareMenu(true)}
                                >
                                  <IoIosShareAlt className="text-2xl" />
                                  <p>Share</p>
                                  {shareMenu && isHoveredId === item?.id && (
                                  <div className="flex gap-3 absolute bottom-7 -right-6 bg-slate-300 rounded-3xl h-10 items-center px-3 py-3">
                                    <div>
                                      <FacebookShareButton
                                      className="hover:-translate-y-1"
                                        url={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                      >
                                        <FacebookIcon size={32} round />
                                      </FacebookShareButton>
                                    </div>
                                    <div>
                                      <WhatsappShareButton
                                      className="hover:-translate-y-1"
                                        url={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                        title={"KWF"}
                                        separator=":: "
                                      >
                                        <WhatsappIcon size={32} round />
                                      </WhatsappShareButton>
                                    </div>
                                    <div>
                                      <LinkedinShareButton
                                      className="hover:-translate-y-1"
                                        url={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                      >
                                        <LinkedinIcon size={32} round />
                                      </LinkedinShareButton>
                                    </div>
                                    <div className="h-8 w-8 flex justify-center items-center rounded-full bg-blue-500 cursor-pointer hover:-translate-y-1">
                                      <CopyToClipboard
                                        onCopy={handleCopy}
                                        text={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                      >
                                        <FaLink />
                                      </CopyToClipboard>
                                    </div>
                                  </div>
                                )}
                                </div>

                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className={`${
                            isShowComment !== index ? "d-none" : ""
                          }`}
                        >
                          <div className={`feed-social socials p-4 py-3`}>
                            <form
                              className="relative flex justify-center w-full"
                              onSubmit={(e) => submitComment(e, item?.id)}
                            >
                              <input
                                required
                                type="text"
                                name="Comment"
                                placeholder="comment Here"
                                value={commentValue}
                                onChange={(e) =>
                                  setCommentValue(e.target.value)
                                }
                                className="px-3 Search_input border border-black"
                              />
                              <button
                                className="bg-transparent contents"
                                type="submit"
                              >
                                <BsFillSendFill className="text-black absolute right-4 top-4 text-xl cursor-pointer" />
                              </button>
                            </form>
                          </div>
                          <>
                            {comments[item?.id]?.map((cmnt) => (
                              <>
                                <div
                                  key={cmnt?.id}
                                  className="flex items-center mb-1 gap-3"
                                >
                                  <div className="rounded-full font-semibold p-2 w-8 text-xs h-8 border border-black flex justify-center items-center bg-gradient-to-br from-pink-600">
                                    KWF
                                  </div>
                                  <div className="flex">{cmnt?.text}</div>
                                </div>
                                <hr className="mb-3" />
                              </>
                            ))}
                            {hasMoreComments && (
                              <span
                                className="cursor-pointer hover:bg-gray-200 p-1 rounded"
                                onClick={() => loadMoreComments(item?.id)}
                              >
                                Show more comments
                              </span>
                            )}
                          </>
                          {commentLoading && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Lottie
                                loop={true}
                                animationData={Loader}
                                style={{ width: "100px" }}
                                className="auth-sidebar-video"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  });
                })()}
</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CasesFeeds;
