/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector } from 'react-redux'

export const BottomSecton = () => {
  const { isLogin } = useSelector((state) => state.authSlice);
  
  return (
    <div className='footer-cta'>
    <div className='footer-cta__content Footerhome-container'>
    <h2 className='home-type-heading'>
    We are in a Mission to Help the Helpless
    </h2>
    <div className='footer-cta__copy'>
    KWF provide seervices such as sheltering the abandoned newborn babies, 
    the Orphanage, the Old Home, the Women Shelter and Home for Children with Special needs.
    </div>
    <div className='footer-cta__actions'>
    <div className='perfect-match__actions'>
            <a className='btn2 btn2--large'
            data-track-cta="Find a designer"
            style={{backgroundColor:'#0D0C22', color:'white'}}
            href='/donation'
            >
            Donate Now
            </a>
            {/* <a className='btn2 btn2--large tertary_btn'
            data-track-cta="Learn about hiring"
            style={{ backgroundColor: 'white', color: 'black', borderColor: '#e7e7e9', borderWidth: '0.5px', borderStyle: 'solid' }}

            >
            Learn About
            </a> */}
        </div>
    </div>
  
    <div className='footer-cta__copy'>
        Register your family
      {
        isLogin ? (
          <>
          </>
        ) : (
          <>
      <a href='/login'>
      <b>? Login</b>
      </a>
          </>
        )
      }
    </div>
    </div>
    </div>
  )
}
