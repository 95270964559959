import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const RegisteredFamilyChart = () => {
  const [family, setFamily] = useState([]);
  const { access } = useSelector((state) => state.authSlice.userInfo);

  const getFamilyData = async () => {
    try {
      const result = await fetchApi.get("family-graph/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      if (result.status === 200) {
        const familyDataResponse = result.data;
        const monthlyRegister = {};
        const currentYear = new Date().getFullYear();

        familyDataResponse.forEach((register) => {
          const createdDate = new Date(register.created_at);
          const registerYear = createdDate.getFullYear();

          if (registerYear === currentYear) {
            const month = createdDate.toLocaleString("en-US", {
              month: "long",
            });

            if (monthlyRegister[month]) {
              monthlyRegister[month]++;
            } else {
              monthlyRegister[month] = 1;
            }
          }
        });

        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = new Date(currentYear, i).toLocaleString("en-US", {
            month: "long",
          });
          return {
            month: monthName,
            totalRegisrer: monthlyRegister[monthName] || 0,
          };
        });

        setFamily(allMonths);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getFamilyData();
  }, []);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const data = {
    labels: family.slice(0, currentMonth)?.map((label) => label?.month),
    datasets: [
      {
        label: "Families Registered",
        data: family
          .slice(0, currentMonth)
          ?.map((label) => label?.totalRegisrer),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: [
        {
          type: "category",
          grid: {
            display: false,
          },
        },
      ],
      y: [
        {
          beginAtZero: true,
        },
      ],
    },
  };

  return (
    <div className="family-registration-chart dashboard-responsive">
      <Bar data={data} options={options} height={230} />
    </div>
  );
};

export default RegisteredFamilyChart;
