/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Stripe from "../Stripe/App";
import card from "../assets/dollar.png";
import axios from "axios";
import { useParams } from "react-router-dom";

function DonationForm() {
  const { id } = useParams();
  const [isStripeOpen, setIsStripeOpen] = useState(false);
  const [amount, setAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [newAmount, setNewAmont] = useState();
  const [userCurrency, setUserCurrency] = useState();
  const [countryflag, setCountryFlag] = useState();
  const [location, setLocation] = useState({});
  const [cFlag, setCFlag] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(true);

  const fetchExchangeRate = async () => {
    try {
      const response = await axios.get(
        `https://open.er-api.com/v6/latest/${userCurrency}`
      );
      const rate = response.data.rates.USD; // Convert to USD
      const newRate = rate * amount;
      setNewAmont(newRate);
      console.log("pkr to usd", rate * amount);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);

      return null;
    }
  };

  const postAmount = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const exchangeRate = await fetchExchangeRate();

    if (exchangeRate !== null) {
      const convertedAmountValue = amount * exchangeRate;
      setConvertedAmount(convertedAmountValue);

      setTimeout(() => {
        setIsStripeOpen(true);
        setIsLoading(false);
      }, 2000);
    } else {
      setIsLoading(false);
    }
  };

  const getCurrencyfromAPI = async () => {
    try {
      const response = await axios.get("https://ipapi.co/currency/");
      console.log("user currency ", response?.data);
      setUserCurrency(response?.data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getCurrencyfromAPI();
  }, []);

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const response = await axios.get("https://ipapi.co/country/");
        setCFlag(response?.data);

        const flagResponse = await axios.get(
          `https://restcountries.com/v3/alpha/${cFlag}`
        );
        //const flag = flagResponse.data[0]?.flags?.svg;
        setCountryFlag(flagResponse.data[0]?.flags[0]);
        setLoadingFlag(false);
      } catch (error) {
        console.error("Error fetching user location:", error);
      }
    };

    fetchUserLocation();
  }, [cFlag]);

  return (
    <div style={{ backgroundColor: "#F8F7F4" }}>
      <div className="goal_container">
        <div className="sec-title text-center pt-5">
          <h2>Donation</h2>
          <p style={{ padding: "0px 70px" }}>
            In these hard times of poverty, unemployment, low wages amid
            escalating rate of inflation, the poor people are suffering the
            most. They find it most difficult to earn enough to feed their kith
            and kin and provide them with clothing and education. To offer
            solace and relief to the poor.
          </p>
        </div>
        <div
          className="goal_row"
          style={{ minHeight: isStripeOpen ? "120vh" : "100vh" }}
        >
          <div className="col-xl-12">
            <div className="mission-goals-content clearfix">
              <div className="mission-goals-image-box"></div>
              <div className="StripeCard_box text-right-rtl">
                {isStripeOpen ? (
                  <Stripe newAmount={newAmount} CaseId={id}/>
                ) : (
                  <>
                    <h4 className="text-center mb-4 ">Donation Form</h4>
                    <div className="stripe_card ">
                      <form onSubmit={postAmount} className="w-full">
                        <div
                          style={{
                            paddingBottom: "20px",
                            position: "relative",
                          }}
                        >
                          <label
                            style={{
                              paddingBottom: "10px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Donation Amount
                          </label>
                          <div style={{ position: "relative" }}>
                            {loadingFlag ? (
                              <p>Loading flag...</p>
                            ) : (
                              <>
                                <p
                                  style={{
                                    position: "absolute",
                                    right: "30px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                >
                                  {userCurrency}
                                </p>
                                <img
                                  src={countryflag}
                                  alt="Dollar sign"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "17px",
                                    height: "auto",
                                  }}
                                />
                              </>
                            )}
                            <input
                              type="number"
                              placeholder="Enter Amount "
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              onKeyPress={(e) => {
                                // Allow only numeric characters and the backspace key
                                const isValidChar = /^[0-9\b]+$/.test(e.key);
                                if (!isValidChar) {
                                  e.preventDefault();
                                }
                              }}
                              className="stripe_input"
                              pattern="[0-9]*"
                              required
                            />
                          </div>
                        </div>
                        <button
                          className="pay-button"
                          disabled={isLoading}
                          style={{ borderRadius: "40px" }}
                        >
                          {isLoading ? "Loading..." : "Next"}
                        </button>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DonationForm;
