/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../Common/Navbar";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchApi } from "../utlis/axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import { AuthWrapper } from "../AuthWrapper";

export const MemberListingpage = () => {
  const [memberData, setMemberData] = useState([]); // Initialize as an object with an empty results array
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();

  const getMemberData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get(`member/?search=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setMemberData(result?.data);
        console.log("result data", result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    getMemberData();
  }, [searchQuery, access]);

  const handleTableRowClick = (id) => {
    console.log("Clicked on row with data:", id);
    navigate(`/memberlistingdetail/${id}`);
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(memberData.count / itemsPerPage);

  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;

    try {
      const result = await fetchApi.get(
        `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      return result;
    } catch (error) {
      throw error;
    }
  };

  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );

      for (let i = 2; i < totalPages; i++) {
        if (i >= currentPage - 1 && i <= currentPage + 2) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }

      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (pageNumber) => {
    console.log("pageNumbber", pageNumber);
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);

      // Fetch data for the selected page
      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            setMemberData(result?.data);
            // console.log("family data....", familyData)
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const nextPageData = async () => {
    const nextPage = currentPage + 1;
    const newOffset = nextPage * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        // Concatenate the new data with the existing data
        setMemberData(result?.data);

        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const prevPageData = async () => {
    const prevPage = currentPage - 1;
    const newOffset = (prevPage - 1) * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        setMemberData(result?.data);
        setCurrentPage(prevPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <>
        <Header />
        <div className="Table_container md:px-10 px-5" style={{ minHeight: "100vh" }}>
          <div className="Table_heading">
            <div className="sidebar-search d-flex justify-content-between">
              <form className="formSearch">
                <input
                  type="search"
                  name="search"
                  className="px-3 Search_input"
                  placeholder="Search Here"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </div>
            <div className="Tbtn">
              <Link to="/memberRegistration">
                <button className="heading_btn">Register Member</button>
              </Link>
            </div>
          </div>
          <div className="Table_Section">
            <h5 className=" p-3">Member Listing Table</h5>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Lottie
                  loop={true}
                  animationData={Loader}
                  style={{ width: "200px" }}
                  className="auth-sidebar-video"
                />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table align-middle mb-0 bg-white table-hover">
                    <thead className="table-light m-3">
                      <tr className="m-3 ">
                        <th style={{ padding: "10px" }}>Name</th>
                        <th style={{ padding: "10px" }}>Education</th>
                        <th style={{ padding: "10px" }}>Monthly Income</th>
                        <th style={{ padding: "10px" }}>Medical Condition</th>
                        <th style={{ padding: "10px" }}>Family </th>
                      </tr>
                    </thead>
                    <tbody>
                      {memberData?.results?.map((data, value) => {
                        return (
                          <tr
                            key={value}
                            className="table-row" // Add this class for styling
                            onClick={() => handleTableRowClick(data?.id)}
                          >
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="d-flex align-items-center">
                                <div className="">
                                  <p className="fw-bold mb-3 mt-3">
                                    {data?.first_name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="dot d-flex align-items-start justify-content-start w-100">
                                {data?.education_or_qualification}
                              </div>
                            </td>
                            <td
                              className=""
                              style={{ verticalAlign: "middle" }}
                            >
                              {data?.monthly_income}
                            </td>
                            <td
                              className=""
                              style={{ verticalAlign: "middle" }}
                            >
                              {data?.medical_condition}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <p className="fw-normal mb-1 ">
                                {data?.family?.name}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <Pagination>
                      <Pagination.Prev
                        onClick={prevPageData}
                        disabled={currentPage === 1}
                      />
                      {renderPaginationItems()}
                      <Pagination.Next
                        onClick={nextPageData}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </AuthWrapper>
  );
};
