export const handleRole = (role, route) => {
    if (role?.toLowerCase() === 'staff') {
      return route('/familylisting');
    }
    if (role?.toLowerCase() === 'admin') {
      return route('/dashboard');
    }
    if (role?.toLowerCase() === 'medicalstaff') {
      return route('/dashboard/verify_fingerprint');
    }
    return route('/login');
  };
  