import React, { useState, useEffect } from "react";
import Header from "../../Common/Navbar";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../../animation/Loader.json";
import { AuthWrapper } from "../../AuthWrapper";

export const MedicalStaff = () => {
  const [storeData, setStoreData] = useState([]); // Initialize as an object with an empty results array
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedToggle, setSelectedToggle] = useState("in-review");
  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();

  const getStoreData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get(
        `medical-store/?search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      if (result.status === 200) {
        setStoreData(result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    getStoreData();
  }, [searchQuery, access, selectedToggle]);

  const handleTableRowClick = (id) => {
    navigate(`/medicalstoredetail/${id}`);
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(storeData.count / itemsPerPage);

  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;

    try {
      const result = await fetchApi.get(
        `medical-store/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      return result;
    } catch (error) {
      throw error;
    }
  };

  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );

      for (let i = 2; i < totalPages; i++) {
        if (i >= currentPage - 1 && i <= currentPage + 2) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }

      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (pageNumber) => {
    console.log("pageNumbber", pageNumber);
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);

      // Fetch data for the selected page
      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            setStoreData(result?.data);
            // console.log("family data....", familyData)
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const nextPageData = async () => {
    const nextPage = currentPage + 1;
    const newOffset = (nextPage - 1) * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `medical-store/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        setStoreData(result?.data);
        console.log("next data", result.data.results);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const prevPageData = async () => {
    const prevPage = currentPage - 1;
    const newOffset = (prevPage - 1) * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `family/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        setStoreData(result?.data);
        setCurrentPage(prevPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleClick = (toggleName) => {
    setSelectedToggle(toggleName);
  };
  return (
    <AuthWrapper>
      <>
        <div className="Table_container" style={{ minHeight: "100vh" }}>
          <div className="Table_heading">
            <div className="sidebar-search d-flex justify-content-between">
              <form className="formSearch">
                <input
                  type="search"
                  name="search"
                  className="px-3 Search_input"
                  placeholder="Search Here"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </div>
            <div className="Tbtn">
              <Link to="/medicalstoreRegistration">
                <button className="heading_btn">Register Medical Store</button>
              </Link>
            </div>
          </div>
          <div className="Table_Section">
            <div className="d-flex justify-content-between ">
              <h5 className=" p-3">Medical Store Listing Table</h5>
              <h6 className="pt-3">
                Total Medical Store : <span>{storeData?.count}</span>
              </h6>
            </div>

            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Lottie
                  loop={true}
                  animationData={Loader}
                  style={{ width: "200px" }}
                  className="auth-sidebar-video"
                />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table align-middle mb-0 bg-white table-hover">
                    <thead className="table-light m-3">
                      <tr className="m-3 ">
                        <th style={{ padding: "10px" }}>Pharmacy</th>
                        <th style={{ padding: "10px" }}>
                          Maneger
                        </th>
                        <th style={{ padding: "10px" }}>Email</th>
                        <th style={{ padding: "10px" }}>Phone Number</th>
                        <th style={{ padding: "10px" }}>Address</th>
                        <th style={{ padding: "10px" }}>City</th>
                        <th style={{ padding: "10px" }}>State</th>
                        <th style={{ padding: "10px" }}>Registered Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {storeData?.results?.map((data, value) => {
                        return (
                          <tr
                            key={value}
                            className="table-row" // Add this class for styling
                            onClick={() => handleTableRowClick(data?.id)}
                          >
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="d-flex align-items-center">
                                <div className="">
                                  <p className="fw-bold mb-3 mt-3">
                                    {data?.name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="dot d-flex align-items-start justify-content-start w-100">
                                {data?.user?.first_name} {data?.user?.last_name}
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <p className="fw-normal mb-1 ">
                              {data?.user?.email}
                              </p>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <p className="fw-normal mb-1 ">
                                {data?.phone_number}
                              </p>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <p className="fw-normal mb-1 ">
                                {data?.address &&
                                data.address.length > 30
                                  ? `${data.address.slice(0, 20)}...`
                                  : data?.address}
                              </p>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <p className="fw-normal mb-1 ">
                                {data?.city}
                              </p>
                            </td>
                            <td
                              className=""
                              style={{ verticalAlign: "middle" }}
                            >
                              {data?.state}
                            </td>
                            <td
                              className=""
                              style={{ verticalAlign: "middle" }}
                            >
                              {moment
                                .utc(data?.updated_at?.toString())
                                .format("MM/DD/YYYY")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <Pagination>
                      <Pagination.Prev
                        onClick={prevPageData}
                        disabled={currentPage === 1}
                      />
                      {renderPaginationItems()}
                      <Pagination.Next
                        onClick={nextPageData}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </AuthWrapper>
  );
};
