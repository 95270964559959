/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import hand from "../assets/Coin in Hand.png";
import dollar from "../assets/Transaction.png";
import map from "../assets/map.png";
import whyContent from "../assets/why-content.png";
import charityImg from "../assets/charity_img.jpg";
export const Features = () => {
  return (
    <div className="why Whome-container">
      <h2 className="why__heading home-type-heading">
        Checks Make us Different with others
      </h2>
      <div className="why__content">
        <div className="why__left">
          <div className="why__item">
            <img src={hand} alt="image" width="40px" />
            <h3 className="why__subheading">KWF PROVIDE FOOD</h3>
            <div className="why__copy">
              KWF took upon itself this responsibility for providing free cooked
              meals to more than 5,000 indigent citizens of Pakistan.
            </div>
          </div>
          <div className="why__item">
            <img src={dollar} alt="image" width="40px" />
            <h3
              className="why__subheading"
              style={{ backgroundColor: "#e3ebfd" }}
            >
              KWF ORPHANAGE
            </h3>
            <div className="why__copy">
              To help children in distress who are orphaned, are taken care and
              provided with all the necessities for their upbringing.
            </div>
          </div>
          <div className="why__item">
            <img src={map} alt="image" width="40px" />
            <h3
              className="why__subheading"
              style={{ backgroundColor: "#edddfb",paddingRight:"40px" }}
            >
              KWF HELP SPECIAL CHILDREN
            </h3>
            <div className="why__copy">
              Special children are looked after and taken with utmost care for
              their special needs. The poor, needy and handicapped are provided
              free-of-cost wheel chairs, crutches, artificial limbs, prosthesis,
              etc.
            </div>
          </div>
        </div>
        <div className="why__right">
          <img
            src={charityImg}
            alt="image"
            width="627px"
            height="550px"
            className="lazyfadein lazyautosizes lazyloaded"
          />
        </div>
      </div>
    </div>
  );
};
