/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isTokenExpired } from './isTokenExpired'
import { toggleAuth } from './Redux/Slices/auth-slice';
import { useNavigate } from 'react-router-dom';
export const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authSlice.userInfo?.access);

  useEffect(() => {
    const checkTokenExpiry = async () => {
      const expired = isTokenExpired(accessToken);
      if (expired) {
        // Token is expired, log out the user
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
          navigate('/'); 
      }
      else{
        console.log("Token not expired")
      }
    };

    checkTokenExpiry();
  }, [accessToken, dispatch]);

  return <>{children}</>;
};
