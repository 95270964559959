import React, {useState, useEffect} from "react";
import { Bar } from "react-chartjs-2";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from 'react-redux';

const FamilyExpenseChart = () => {
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [donation, setDonation] = useState({
    education:0,
    food:0,
    health:0,
    bussiness:0,
    others:0
});

const getDonationStatus = async ()=>{
  try{
    const result = await fetchApi.get('/donation-graph',{
      headers: {
        Authorization: `Bearer ${access}`,
      },
    })
    if (result.status === 200) {
      const apiData = result.data;

      // Calculate the count for each status
      const statusCounts = apiData.reduce((counts, item) => {
        counts[item.type] = (counts[item.type] || 0) + 1;
        return counts;
      
      }, {});

      setDonation({
        education: statusCounts.education || 0,
        food: statusCounts.food || 0,
        health: statusCounts.health || 0,
        bussiness: statusCounts.bussiness || 0,
        others:statusCounts.others || 0
      });
    }

  } catch(error){
    console.log({error})
  }
}

useEffect(()=>{
  getDonationStatus();
},[])

  const data = {
    labels: ["Education", "Health", "Food", "Bussiness", "Others"],
    datasets: [
      {
        label: "Expense Chart",
        data: [donation.education, donation.health, donation.food, donation.bussiness, donation.others],
        backgroundColor: ["rgba(255,99,132,0.2)", "rgba(54,162,235,0.2)", "rgba(255,206,86,0.2)", "green", "blue"],
        borderColor: ["rgba(255,99,132,1)", "rgba(54,162,235,1)", "rgba(255,206,86,1)","green", "blue"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="expense-chart dashboard-responsive">
      <Bar data={data} options={options}  height={230} />
    </div>
  );
};

export default FamilyExpenseChart;
