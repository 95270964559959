/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Family from "../assets/Family_Registration.png";
import uploadFile from "../assets/upload-file.png";
import { fetchApi } from "../utlis/axios";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import backArrow from "../assets/back-button.png";
import { toast } from "react-toastify";
import { AuthWrapper } from "../AuthWrapper";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import FingerPrintPage from "../Fingerprint/FingerPrintPage";

export const MemberRegistration = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [type, setType] = useState("text");
  const [familyData, setFamilyData] = useState();
  const [family, setFamily] = useState();
  const { id } = useSelector((state) => state.authSlice.userInfo?.user);
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [cincFront, setCnicFront] = useState();
  const [cnicBack, setCnicBack] = useState();
  const [workExperience, setWorkExperience] = useState([]);
  const [educationalDoc, setEducationalDoc] = useState([]);
  const [educationalLoading, setEducationalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [fingerprintpage, setFringerprintpage] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    family: "",
    education_documents: [],
    work_experience_documents: [],
    cnic_front: null,
    cnic_back: null,
  });

  // Access the state data
  const familyId = state && state.familyId;

  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    age: null,
    cnic_number: "",
    date_of_birth: "",
    education_or_qualification: "high_school",
    education_status: "on_hold",
    education_status_statement: "",
    willing_to_study: true,
    job: "",
    monthly_income: null,
    medical_condition: "",
    permanent_medication: true,
    permanent_medication_description: "",
    gender: "Male",
    cnic_front: null,
    cnic_back: null,
    skills: "",
    // father_name:'',
    // mother_name:'',
    num_of_childrens: null,
    // num_of_brothers:null,
    // num_of_sisters:null,
    marital_status: "single",
    family: null,
    education_documents: [],
    work_experience_documents: [],
    is_active: false,
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const PostRegistration = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      const data = new FormData();
      data.append("id", id);
      data.append("first_name", formValues?.first_name);
      data.append("last_name", formValues?.last_name);
      data.append("age", formValues?.age);
      data.append("cnic_number", formValues?.cnic_number);
      data.append("date_of_birth", formValues?.date_of_birth);
      data.append(
        "education_or_qualification",
        formValues?.education_or_qualification
      );
      data.append("education_status", formValues?.education_status);
      data.append(
        "education_status_statement",
        formValues?.education_status_statement
      );
      data.append("willing_to_study", formValues?.willing_to_study);
      data.append("job", formValues?.job);
      data.append("monthly_income", formValues?.monthly_income);
      if (!formValues?.medical_condition) {
        data.append("medical_condition", null);
      } else {
        data.append("medical_condition", formValues?.medical_condition);
      }
      data.append("permanent_medication", formValues?.permanent_medication);
      data.append(
        "permanent_medication_description",
        formValues?.permanent_medication_description
      );
      data.append("gender", formValues?.gender);
      data.append("cnic_front", cincFront || "");
      data.append("cnic_back", cnicBack || "");
      data.append("skills", formValues?.skills);

      if (
        formValues.marital_status === "single" ||
        formValues.marital_status === "committed"
      ) {
        data.append("num_of_childrens", "0");
      } else {
        data.append("num_of_childrens", formValues?.num_of_childrens || "0");
      }
      data.append("marital_status", formValues?.marital_status);
      data.append("family", formValues?.family || familyId);
      educationalDoc.forEach((docId) => {
        data.append("education_documents", docId?.id);
      });
      workExperience.forEach((docId) => {
        data.append("work_experience_documents", docId?.id);
      });
      data.append("address", formValues?.address);
      data.append("is_active", true);

      const result = await fetchApi.post("member/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 201) {
        // toast.success('Upload Application sucessfully!');
        console.log("Family data", data);
        //navigate('/memberlisting')
        navigate(`/viewmember/${familyId}`);
      }
      setFormValues({
        ...formValues,
        first_name: "",
        last_name: "",
        age: null,
        cnic_number: "",
        date_of_birth: "",
        education_or_qualification: "",
        education_status: null,
        education_status_statement: "",
        willing_to_study: null,
        job: "",
        monthly_income: null,
        medical_condition: "",
        permanent_medication: null,
        permanent_medication_description: "",
        gender: null,
        cnic_front: null,
        cnic_back: null,
        skills: "",
        // father_name:'',
        // mother_name:'',
        num_of_childrens: null,
        // num_of_brothers:null,
        // num_of_sisters:null,
        marital_status: null,
        family: null,
        education_documents: [],
        work_experience_documents: [],
        address: "",
      });
      setStep(step - 2);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false); // Set loading back to false when API call is completed
    }
  };

  const getFamilydata = async () => {
    try {
      const result = await fetchApi.get(`family/${familyId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setFamilyData(result.data);
        console.log("Family data...", result.data);
      } else {
        console.error("Failed to fetch data:", result.status);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getFamily = async () => {
    try {
      const result = await fetchApi.get("family/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setFamily(result?.data);
        console.log("Families data...", result.data);
      } else {
        console.error("Failed to fetch data:", result.status);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFormValues({ ...formValues, cnic_front: file.name });
        setCnicFront(file);
      } else {
        setFormValues({ ...formValues, cnic_front: "" });
      }
    } else {
      setFormValues({ ...formValues, cnic_front: "" });
    }
  };

  const handleFileCnicBackChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFormValues({ ...formValues, cnic_back: file.name });
        setCnicBack(file);
      } else {
        setFormValues({ ...formValues, cnic_back: "" });
      }
    } else {
      setFormValues({ ...formValues, cnic_back: "" });
    }
  };

  const handleEducationalChange = async (e) => {
    const newFiles = e.target.files;

    if (newFiles.length > 0) {
      setEducationalLoading(true);
      const updatedSelectedFiles = [...educationalDoc];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const data = new FormData();
        data.append("file", file);
        data.append("type", "Educational Document");
        data.append("is_active", true);

        try {
          const result = await fetchApi.post("attachments/", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access}`,
            },
          });

          if (result?.status === 201) {
            updatedSelectedFiles.push({ id: result.data.id, name: file.name });
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          setEducationalLoading(false);
          return; // Stop further processing on error
        }
      }

      setEducationalDoc(updatedSelectedFiles);
      setEducationalLoading(false);
    } else {
    }
  };

  const handleFileDelete = (index) => {
    const updatedEducationalDoc = [...educationalDoc];
    updatedEducationalDoc.splice(index, 1);
    setEducationalDoc(updatedEducationalDoc);
  };

  const handleWorkChange = async (e) => {
    const newFiles = e.target.files;

    if (newFiles.length > 0) {
      setLoading(true);
      const updatedSelectedFiles = [...workExperience];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const data = new FormData();
        data.append("file", file);
        data.append("type", "Experience Document");
        data.append("is_active", true);

        try {
          const result = await fetchApi.post("attachments/", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access}`,
            },
          });

          if (result?.status === 201) {
            updatedSelectedFiles.push({ id: result.data.id, name: file.name });
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          setLoading(false);

          return; // Stop further processing on error
        }
      }

      setWorkExperience(updatedSelectedFiles);
      setLoading(false);
    } else {
    }
  };

  const handleworkFileDelete = (index) => {
    const updatedworkDoc = [...workExperience];
    updatedworkDoc.splice(index, 1);
    setWorkExperience(updatedworkDoc);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = today.getDate().toString().padStart(2, "0");
    return `${today.getFullYear()}-${month}-${day}`;
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      family: "",
      // education_documents:[],
      // work_experience_documents:[],
      // cnic_front:null,
      // cnic_back:null,
    };

    if (!familyId) {
      // If familyId is not present, check if formValues.family is empty
      if (!formValues.family) {
        valid = false;
        errors.family = "Family is required";
      }
    }
    //   if (!educationalDoc.length) { // Updated condition to check array length
    //   valid = false;
    //   errors.education_documents = "Educational Doc is required";
    // }
    // if (!workExperience.length) { // Updated condition to check array length
    //   valid = false;
    //   errors.work_experience_documents = "Work Experience Doc is required";
    // }
    //   if (!formValues.cnic_front) {
    //     valid = false;
    //     errors.cnic_front = "Cnic front required";
    //   }
    //   if (!formValues.cnic_back) {
    //     valid = false;
    //     errors.cnic_back = "Cnic back required";
    //   }

    setValidationErrors(errors);
    return valid;
  };

  useEffect(() => {
    getFamilydata();
    getFamily();
  }, []);

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  };

  const handleDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormValues({
      ...formValues,
      age: age,
      date_of_birth: dateOfBirth,
    });
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handlefingerPrintPage = () => {
    setFringerprintpage(!fingerprintpage);
  };

  return (
    <AuthWrapper>
      <div
        class="main-container familyRegistration text-white"
        style={{ minHeight: "100vh" }}
      >
        <div className="bg-gray-700 w-full bg-opacity-70 h-screen bg-fixed overflow-y-auto">
          {/* <div className="auth-sidebar">
          <div className="auth-sidebar-content">
            <img
              src={Family}
              alt="image"
              className="auth-sidebar-video"
              width="450px"
              height="100%"
            />
          </div>
        </div> */}
          <div className="content">
            <button
              className="btn2 btn2--tertiary  back-btn backButton"
              style={{ backgroundColor: "white" }}
              onClick={handleGoBack}
            >
              <img src={backArrow} alt="back-button" />
            </button>
            <div className="formgroup pb-2">
              <h4 className="text-center">Member Registration Form</h4>
              <div className="field_Container">
                {step === 1 && (
                  <>
                    <form onSubmit={handleNext} className="form_responsive">
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">First name</h6>
                          <input
                            type="text"
                            id="First name"
                            name="First name"
                            placeholder="First Name"
                            className="formField"
                            value={formValues?.first_name}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                first_name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Last Name</h6>
                          <input
                            type="text"
                            id="Last Name"
                            name="Last Name"
                            placeholder="Last Name"
                            className="formField"
                            value={formValues?.last_name}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                last_name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Date of Birth</h6>
                          <input
                            type={type}
                            id="date"
                            name="DOB"
                            placeholder="Date of Birth"
                            onFocus={(event) => {
                              event.target.type = "date";
                            }}
                            className="formField"
                            onClick={(e) => setType("date")}
                            value={formValues.date_of_birth}
                            onChange={handleDateChange}
                            max={getCurrentDate()}
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">CNIC</h6>
                          <input
                            type="number"
                            id="CNIC"
                            name="CNIC"
                            placeholder="CNIC"
                            className="formField"
                            value={formValues.cnic_number}
                            onChange={(e) => {
                              const inputValue = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 14);
                              setFormValues({
                                ...formValues,
                                cnic_number: inputValue,
                              });
                            }}
                            pattern="\d*"
                            maxLength="14"
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Age</h6>
                          <input
                            type="number"
                            id="Age"
                            name="Age"
                            placeholder="Age"
                            className="formField bg-white"
                            value={formValues.age}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 3);
                              setFormValues({ ...formValues, age: inputValue });
                            }}
                            maxLength="3"
                            disabled
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Education</h6>
                          <select
                            type="text"
                            id="Education"
                            name="Education"
                            placeholder="Education"
                            className="formField custom-select"
                            value={formValues.education_or_qualification}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                education_or_qualification: e.target.value,
                              })
                            }
                            required
                          >
                            <option>none</option>
                            <option>high_school</option>
                            <option>college</option>
                            <option>university</option>
                          </select>
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Education status choice</h6>
                          <select
                            type="text"
                            id="Education status choice"
                            name="Education status choice"
                            placeholder="Education status choice"
                            className="formField custom-select"
                            value={formValues?.education_status}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                education_status: e.target.value,
                              })
                            }
                            required
                          >
                            <option>on_hold</option>
                            <option>completed</option>
                            <option>quitted</option>
                            <option>ongoing</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">
                            Education status statement
                          </h6>
                          <input
                            type="text"
                            id="Education status statement"
                            name="Education status statement"
                            placeholder="Education status statement"
                            className="formField"
                            value={formValues?.education_status_statement}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                education_status_statement: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Willing to Study</h6>
                          <select
                            type="text"
                            id="Willing to Study"
                            name="Willing to Study"
                            placeholder="Willing to Study"
                            className="formField custom-select"
                            value={formValues?.willing_to_study.toString()}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                willing_to_study: e.target.value === "true",
                              })
                            }
                            required
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Job</h6>
                          <input
                            type="text"
                            id="Job"
                            name="Job"
                            placeholder="Job"
                            className="formField"
                            value={formValues?.job}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                job: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="next_btn">
                        <button className="Registration_btn">Next</button>
                      </div>
                    </form>
                  </>
                )}
                {step === 2 && (
                  <>
                    <form onSubmit={handleNext} className="form_responsive">
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Monthly income</h6>
                          <input
                            type="number"
                            id="Monthly income"
                            name="Monthly income"
                            placeholder="Monthly income"
                            className="formField"
                            value={formValues?.monthly_income}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                monthly_income: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Permanent medication</h6>
                          <select
                            type="text"
                            id="Permanent medication"
                            name="Permanent medication"
                            placeholder="Permanent medication"
                            className="formField custom-select"
                            value={formValues?.permanent_medication.toString()}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                permanent_medication: e.target.value === "true",
                              })
                            }
                            required
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Medical condition</h6>
                          <input
                            type="text"
                            id="Medical condition"
                            name="Medical condition"
                            placeholder="Medical condition"
                            className="formField"
                            value={formValues?.medical_condition}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                medical_condition: e.target.value,
                              })
                            }
                            disabled={!formValues.permanent_medication}
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Medication description</h6>
                          <input
                            type="text"
                            id="Medication description"
                            name="Medication description"
                            placeholder="Medication description"
                            className="formField"
                            value={formValues?.permanent_medication_description}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                permanent_medication_description:
                                  e.target.value,
                              })
                            }
                            disabled={!formValues.permanent_medication}
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Gender</h6>
                          <select
                            type="text"
                            id="Gender"
                            name="Gender"
                            placeholder="Gender"
                            className="formField custom-select"
                            value={formValues?.gender}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                gender: e.target.value,
                              })
                            }
                            required
                          >
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Skills</h6>
                          <input
                            type="text"
                            id="Skills"
                            name="Skills"
                            placeholder="Skills"
                            className="formField"
                            value={formValues?.skills}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                skills: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      {/* <div className="group-fields">
          <div className='field-container'>
            <h6 className='field-txt'>Father name</h6>
          <input
            type="text"
            id="Father name"
            name="Father name"
            placeholder="Father name"
            className="formField"
            value={formValues?.father_name}
            onChange={(e)=> setFormValues({...formValues, father_name:e.target.value})}
            required
          />
          </div>
          <div className='field-container'>
          <h6 className='field-txt'>Mother name</h6>
          <input
            type="text"
            id="Mother name"
            name="Mother name"
            placeholder="Mother name"
            className="formField"
            value={formValues?.mother_name}
            onChange={(e)=> setFormValues({...formValues, mother_name:e.target.value})}
            required
          />
          </div>
        </div> */}

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Martial status</h6>
                          <select
                            type="text"
                            id="Martial status"
                            name="Martial status"
                            placeholder="Martial status"
                            className="formField custom-select"
                            value={formValues?.marital_status}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                marital_status: e.target.value,
                              })
                            }
                            require
                          >
                            <option>single</option>
                            <option>married</option>
                            <option>committed</option>
                            <option>divorced</option>
                            <option>widow</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Number of childrens</h6>
                          <input
                            type="number"
                            id="Number of childrens"
                            name="Number of childrens"
                            placeholder="Number of childrens"
                            className="formField"
                            value={formValues?.num_of_childrens}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 3); // Limit input to 3 characters
                              setFormValues({
                                ...formValues,
                                num_of_childrens: inputValue,
                              });
                            }}
                            maxLength="3"
                            required
                            disabled={
                              formValues?.marital_status === "single" ||
                              formValues?.marital_status === "committed"
                            }
                          />
                        </div>
                      </div>

                      <div className="btn_conatiner">
                        <button
                          onClick={handleBack}
                          className="Registration_Backbtn"
                        >
                          Back
                        </button>
                        <button className="Register_btn">Next</button>
                      </div>
                    </form>
                  </>
                )}

                {step === 3 && (
                  <>
                    <form
                      onSubmit={PostRegistration}
                      className="form_responsive"
                    >
                      {/* <div className="group-fields">
             <div className='field-container'>
             <h6 className='field-txt'>Number of brother</h6>
             <input
               type="number"
               id="Number of brother"
               name="Number of brother"
               placeholder="Number of brother"
               className="formField"
               value={formValues?.num_of_brothers}
               onChange={(e) => {
                 const inputValue = e.target.value.slice(0, 3); 
                 setFormValues({ ...formValues, num_of_brothers: inputValue });
               }}
               maxLength="3"
               required
             />
             </div>
             <div className='field-container'>
              <h6 className='field-txt'>Number of sisters</h6>
             <input
               type="number"
               id="Number of sister"
               name="Number of sister"
               placeholder="Number of sister"
               className="formField"
               value={formValues?.num_of_sisters}
               onChange={(e) => {
                 const inputValue = e.target.value.slice(0, 3); 
                 setFormValues({ ...formValues, num_of_sisters: inputValue });
               }}
               maxLength="3"
               required
                   />
             </div>
             </div> */}
                      {fingerprintpage ? (
                        <FingerPrintPage
                          handlefingerPrintPage={handlefingerPrintPage}
                        />
                      ) : (
                        <>
                          <div className="group-fields">
                            <div className="field-container">
                              <h6 className="field-txt">Cnic-front</h6>
                              <div className="upload-field">
                                <input
                                  type="file"
                                  id="cnic-front"
                                  name="cnic-front"
                                  onChange={handleFileInputChange}
                                  accept=".jpg, .jpeg, .png"
                                  style={{ display: "none" }}
                                />
                                <input
                                  type="text"
                                  id="cnic-front-text"
                                  name="cnicfront-text"
                                  placeholder="Upload CNIC Front"
                                  onClick={() =>
                                    document
                                      .getElementById("cnic-front")
                                      .click()
                                  }
                                  className="formField with-icon"
                                  value={formValues?.cnic_front}
                                  readOnly
                                  required
                                />
                              </div>
                              {/* <span className="error-message text-danger">{validationErrors.cnic_front}</span> */}
                            </div>

                            <div className="field-container">
                              <h6 className="field-txt">Cnic-back</h6>
                              <div className="upload-field">
                                <input
                                  type="file"
                                  id="cnic-back"
                                  name="cnic-back"
                                  onChange={handleFileCnicBackChange}
                                  accept=".jpg, .jpeg, .png"
                                  style={{ display: "none" }}
                                />
                                <input
                                  type="text"
                                  id="cnic-back-text"
                                  name="cnicback-text"
                                  placeholder="Upload CNIC Back"
                                  onClick={() =>
                                    document.getElementById("cnic-back").click()
                                  }
                                  className="formField with-icon"
                                  value={formValues?.cnic_back}
                                  readOnly
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="group-fields">
                            <div className="field-container">
                              <h6 className="field-txt">
                                Educational attachment
                              </h6>
                              <div className="upload-field">
                                <input
                                  type="file"
                                  id="Educational attachment"
                                  name="Educational attachment"
                                  onChange={handleEducationalChange}
                                  style={{ display: "none" }}
                                  multiple
                                  accept="image/*, .pdf"
                                  capture="environment"
                                />
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    id="Educational attachment-text"
                                    name="cnicfront-text"
                                    placeholder="Upload Educational Doc"
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          "Educational attachment"
                                        )
                                        .click()
                                    }
                                    className="formField with-icon"
                                    value={educationalDoc[0]?.name}
                                    readOnly
                                    required
                                  />
                                </div>
                                {educationalLoading && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                  >
                                    <Lottie
                                      loop={true}
                                      animationData={Loader}
                                      style={{ width: "80px" }}
                                    />
                                  </div>
                                )}
                                <div className="selected-files ms-2">
                                  {educationalDoc.map((file, index) => (
                                    <div key={index} className="selected-file">
                                      <span>{file.name}</span>
                                      <span
                                        className="delete-icon"
                                        onClick={() => handleFileDelete(index)}
                                      >
                                        <img
                                          src={require("../assets/delete.png")}
                                          alt="delete"
                                          width="15px"
                                          height="15px"
                                        />
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="field-container">
                              <h6 className="field-txt">
                                Work Experience attachment
                              </h6>
                              <div className="upload-field">
                                <input
                                  type="file"
                                  id="Work Experience"
                                  name="Work Experience"
                                  onChange={handleWorkChange}
                                  style={{ display: "none" }}
                                  multiple
                                  accept="image/*, .pdf"
                                  capture="environment"
                                />
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    id="Work Experience-text"
                                    name="Work Experience-text"
                                    placeholder="Upload Work Experience Doc"
                                    onClick={() =>
                                      document
                                        .getElementById("Work Experience")
                                        .click()
                                    }
                                    className="formField with-icon"
                                    value={workExperience[0]?.name}
                                    readOnly
                                    required
                                  />
                                  {loading && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                    >
                                      <Lottie
                                        loop={true}
                                        animationData={Loader}
                                        style={{ width: "80px" }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="selected-files ms-2">
                                  {workExperience.map((file, index) => (
                                    <div key={index} className="selected-file">
                                      <span>{file.name}</span>
                                      <span
                                        className="delete-icon"
                                        onClick={() =>
                                          handleworkFileDelete(index)
                                        }
                                      >
                                        <img
                                          src={require("../assets/delete.png")}
                                          alt="delete"
                                          width="15px"
                                          height="15px"
                                        />
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="group-fields">
                            {/* <div className='field-container'>
           <h6 className='field-txt'>Address</h6>
           <input
             type="text"
             id="Address "
             name="Address "
             placeholder="Address"
             className="formField"
             value={formValues?.address}
             onChange={(e)=> setFormValues({...formValues, address:e.target.value})}
             required
           />
           </div> */}
                            {!familyId && (
                              <div className="field-container">
                                <h6 className="field-txt">Select Family</h6>
                                <select
                                  type="text"
                                  id="Family "
                                  name="Family "
                                  placeholder="Select Family"
                                  className="formField custom-select"
                                  value={formValues?.family}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      family: e.target.value,
                                    })
                                  }
                                  required
                                >
                                  <option>Select</option>
                                  {family?.results?.map((item) => {
                                    return (
                                      <option value={item?.id}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </select>

                                <span className="error-message text-danger">
                                  {validationErrors.family}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="group-fields">
                            <div className="field-container">
                              <h6 className="field-txt">
                                Add Family member fingerprints
                              </h6>
                              <button
                                className="Register_btn"
                                onClick={handlefingerPrintPage}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="btn_conatiner">
                        {!fingerprintpage && (
                          <button
                            onClick={handleBack}
                            className="Registration_Backbtn"
                          >
                            Back
                          </button>
                        )}
                        <button className="Registration_btn" disabled={loading}>
                          {loading ? "Loading..." : "Register"}
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};
