import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";

const MemberEducationChart = () => {
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [status, setStatus] = useState({
    single:0,
    married:0,
    committed:0,
})

const getFamilyStatus = async () => {
  try {
    const result = await fetchApi.get("members-graph/", {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });
    if (result.status === 200) {
      const apiData = result.data;

      // Calculate the count for each status
      const statusCounts = apiData.reduce((counts, item) => {
        counts[item.marital_status] = (counts[item.marital_status] || 0) + 1;
        console.log("status count for material", counts)
        return counts;
      
      }, {});

      setStatus({
        single: statusCounts.single || 0,
        married: statusCounts.married || 0,
        committed: statusCounts.committed || 0,
        
      });

    }
  } catch (error) {
    console.log({ error });
  }
};

  useEffect(()=>{
    getFamilyStatus();
  },[])

  const data = {
    labels: ["Single", "Married", "Commited", ],
    datasets: [
      {
        data: [status.single, status.married, status.committed,],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
  };

  return (
    <div className="pie-chart dashboard-responsive">
      <Pie data={data} options={options} height={230} />
    </div>
  );
};

export default MemberEducationChart;