import React, { useEffect, useRef, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import logo from "../../assets/kwf-logo.png";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";

const ReceiptModel = ({ setShowReceiptModel, medicines }) => {
  const [receiptId, setReceiptId] = useState(null);
  const printRef = useRef();
  const [data, setData] = useState();
  const { id } = useSelector((state) => state.authSlice.userInfo?.user);
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [loading, setLoading] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;

  const totalAmount = medicines.reduce(
    (acc, cur) => acc + Number(cur.amount),
    0
  );

  useEffect(() => {
    setLoading(true);
    let addMedicines = async () => {
      try {
        let data = new FormData();
        data.append("medicines", JSON.stringify(medicines));
        data.append("amount", totalAmount);
        data.append("medical_store", "b26bae13-5621-437c-8ab2-c040062a6d79");
        data.append("is_active", true);

        const result = await fetchApi.post(`medical-store-receipt/`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access}`,
          },
        });
        if (result.status === 201) {
          setReceiptId(result.data.id);
          setLoading(false);
        }
      } catch (error) {
        toast.dismiss();
        toast.error("Something went wrong");
        setShowReceiptModel(false);
        console.log(error);
      }
    };
    addMedicines();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await fetchApi.get(`medical-store/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        let medicalStoreData = result.data?.results;
        medicalStoreData.forEach((data) => {
          console.log(data);
          if (data?.user?.id === id) {
            setData(data);
            console.log(data?.user?.id);
          }
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteReceipt = async () => {
    try {
      const result = await fetchApi.delete(
        `medical-store-receipt/${receiptId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const postMedicines = async (receiptImage) => {
    setLoading(true);
    try {
      const data = new FormData();
      data.append("file", receiptImage);
      data.append("type", "receipt");
      data.append("is_active", true);
      let results = await fetchApi.post("attachments/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });
      if (results.status === 201) {
        try {
          let data = new FormData();
          data.append("medicines", JSON.stringify(medicines));
          data.append("amount", totalAmount);
          data.append("medical_store", "b26bae13-5621-437c-8ab2-c040062a6d79");
          // data.append("medical_store", data?.id);
          // data.append("patient", "ABC");
          data.append("reciept_image", results.data.id);
          data.append("is_active", true);

          let result = await fetchApi.patch(
            `medical-store-receipt/${receiptId}`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${access}`,
              },
            }
          );
          if (result.status === 201) {
            setLoading(false);
            toast.success("Receipt save successfully");
            setShowReceiptModel(false);
          }
        } catch (error) {
          setLoading(false);
          toast.error("Something went wrong");
          console.log(error);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong while uploading receipt image");
      console.error("Error in receipt model:", error);
    }
  };

  const handleSave = async () => {
    try {
      const canvas = await html2canvas(printRef.current);
      const dataUrl = canvas.toDataURL("image/png");
      const blob = await (await fetch(dataUrl)).blob();
      postMedicines(blob);
    } catch (error) {
      console.error("Error saving receipt:", error);
    }
  };

  return (
    <div className="h-screen overflow-y-auto">
      <div className="p-4 ml-10">
        <div className="max-w-md mx-auto bg-white sm:p-8 p-5 rounded shadow-lg relative">
          <div
            className="absolute text-black right-2 top-2 cursor-pointer text-2xl"
            onClick={async () => {
              await deleteReceipt();
              setShowReceiptModel(false);
            }}
          >
            <IoMdCloseCircle />
          </div>

          <div className="max-w-md mx-auto p-3" ref={printRef}>
            <div className="w-full flex justify-center items-center py-2">
              <img className="h-16" src={logo} alt="Logo" />
            </div>
            <div className="flex justify-between">
              <p>
                <span className="font-bold">Patient: </span>ABC
              </p>
              <p>
                <span className="font-bold">Medical Store: </span>
                {data?.name}
              </p>
            </div>
            <div className="flex justify-between">
              <p>
                <span className="font-bold">Date: </span>
                {formattedDate}
              </p>
              <p>
                <span className="font-bold">Address: </span>
                {data?.address}
              </p>
            </div>
            <div className="py-1 mb-2">
              <table className="border-collapse border border-black w-full">
                <tbody>
                  <tr>
                    <td className="border border-slate-700 w-1/4 p-1">
                      Receipt Id
                    </td>
                    <td className="border border-slate-700 p-1">{receiptId}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="border-t border-b border-gray-300 py-2">
              <div className="flex justify-between items-center">
                <p className="text-gray-700">Qty</p>
                <p className="text-gray-700">Medicine</p>
                <p className="text-gray-700">Amt</p>
              </div>
              {medicines.map((data) => (
                <div
                  className="flex justify-between items-center mt-2"
                  key={data?.name}
                >
                  <p>{data?.quantity}</p>
                  <p>{data?.name}</p>
                  <p>{data?.amount}</p>
                </div>
              ))}
            </div>
            <div className="mt-4 flex justify-around items-center">
              <p className="text-lg font-semibold">Total:</p>
              <p className="text-lg font-semibold">Rs: {totalAmount}</p>
            </div>
            <div className="flex justify-center items-center">
              Have a nice day
            </div>
          </div>
          <button
            className="bg-gray-900 text-white py-2 px-4 mt-6 rounded-md"
            onClick={handlePrint}
          >
            Print Receipt
          </button>
          <button
            className="bg-gray-900 text-white py-2 px-4 mt-6 rounded-md"
            onClick={handleSave}
          >
            {loading ? "Loading..." : "Save Receipt"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiptModel;
