/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Facebook from '../assets/SocialMedia/Facebook.png'
import Twitter from '../assets/SocialMedia/Twitter.png'
import Pintrest from '../assets/SocialMedia/Pinterest.png'
import instagram from '../assets/SocialMedia/Instagram Old.png'
import Logo from '../assets/kwf-logo.png'
export const Footer = () => {
  return (
    <div className='footer-v2__upper'>
    <a className='footer-v2__logo' href='#'>
        <img src={Logo} alt='logo' width='140px'/>
    </a>
    <ul className='footer-v2__links'>
        <li className='footer-v2__link'>
           <a href='/aboutus'>About us</a> 
        </li>
        <li className='footer-v2__link'>
            <a href='#'>Blogs</a>
        </li>
        <li className='footer-v2__link'>
            <a href='#'>Projects</a>
        </li>
        <li className='footer-v2__link'>
            <a href='/donation'>Donation</a>
        </li>
    </ul>
    <div className='footer-v2__social-links'>
        <a className='footer-v2__social-link' href='#'>
        <img src ={Facebook} alt='facebook' width='30px'/>
        </a>
        <a className='footer-v2__social-link' href='#'>
        <img src ={Twitter} alt='facebook' width='40px' />
        </a>
        <a className='footer-v2__social-link' href='#'>
        <img src ={instagram} alt='facebook' width='30px' />
        </a>
        {/* <a className='footer-v2__social-link' href='#'>
        <img src ={Pintrest} alt='facebook' width='30px' />
        </a> */}
    </div>
    </div>
  )
}
