/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import charity from '../assets/charity_img.jpg'
const Header = () => {
  return (
    <div className='w-100 hero_Section' style={{backgroundColor:"#F8F7F4"}}>
    <div className='home_Container'>
    <div className='home-badge'>
    About us
    </div>
  <div className='hero__heading'>
    <h1>Donations Even if it is a small can Bring Bigger</h1>
  </div>
  <div className='container_Ltext'>
    <p>We know only too well that what we are doing is nothing more than a drop in the ocean. But if the drop were not there, the ocean would be missing something.</p>
  </div>
 <div className='img_container'>
    <img src={charity} alt='image' width='100%' style={{borderRadius:'30px'}}/>
 </div>
    </div>
  </div>
  )
}

export default Header