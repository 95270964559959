/* eslint-disable jsx-a11y/img-redundant-alt */
import React,{useState, useEffect} from 'react'
import '../App.css'
import { useDispatch, useSelector } from 'react-redux';
import { toggleAuth } from '../Redux/Slices/auth-slice';
import { fetchApi } from '../utlis/axios';
import { useNavigate } from 'react-router-dom';
import backArrow from '../assets/back-button.png'
import Lottie from 'lottie-react';
import LoginAnimation from '../animation/Login.json'
import Navbar from '../Common/Navbar'
import { toast } from 'react-toastify'
import { handleRole } from '../constent/helper';


export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { isLogin, userInfo } = useSelector((state) => state?.auth || {});
  const [formValues, setFormValues] = useState({ email: '', password: '' })

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!formValues?.email || !formValues?.password)
			return toast.error('All fields are required!')
    setLoading(true);
    try {
      const result = await fetchApi.post('login/', formValues);
      if (result?.data) {
        dispatch(toggleAuth({ isLogin: true, userInfo: result?.data }));
        setLoading(false)
        handleRole(result?.data?.user?.role, navigate)
      } else {
        setError('Given credentials are wrong');
      }
    } catch (error) {
      setError('An error occurred while trying to log in. Please try again later.');
      console.error(error); // Log the error for debugging
      toast.error(error?.response?.data?.detail)
    }
  };
  
   useEffect(() => {
		if (isLogin) handleRole(userInfo?.user?.role, navigate)
	}, [isLogin])

  return (
    <>
    <Navbar />
     <div class="main-container" style={{ minHeight: '100vh' }}>
    <div className='auth-sidebar'>
    <div className='auth-sidebar-content' >
    <Lottie
				loop={true}
				animationData={LoginAnimation}
			  style={{ width: '500px', }}
        className='auth-sidebar-video'
		/>
    </div>
    </div>
    <div className='content'>
      <div className='main'>
      <button className='btn2 btn2--tertiary  back-btn backButton' 
      style={{backgroundColor:'white'}}
      onClick={()=> navigate('/')} >
        <img src={backArrow} alt='back-button' />
      </button>
      <div className='auth-content'>
        <h2>Sign in to KWF</h2>
        <div className='auth-form sign-in-form'>
        <form onSubmit={handleLogin}>
          <div class="form-outline mb-4">
            <label class="form-label" for="form2Example1">Email address</label>
              <input 
              type="email" 
              id="form2Example1" 
              className="form-control login-field" 
              required
              style={{borderRadius:'10px', padding:'13px'}}
              value={formValues?.email}
							onChange={(e) =>
									setFormValues({ ...formValues, email: e?.target?.value })
								}
              />
            </div>
            <div class="form-outline mb-4">
            <label class="form-label" for="form2Example2">Password</label>
              <input 
              type="password" 
              id="form2Example2" 
              className="form-control login-field" 
              style={{borderRadius:'10px', padding:'13px'}}
              required
              value={formValues?.password}
							onChange={(e) =>
									setFormValues({ ...formValues, password: e?.target?.value })
								}
              />
            </div>
            {error && <div className="text-danger">{error}</div>} {/* Display error message */}
            <button type='submit' name='login' id='login' className='btn btn-primary btn-block mb-3 w-100' 
            style={{borderRadius:'50px', padding:'13px', backgroundColor:'#0d0c22', border:'none'}}>
              {loading ? 'Loading...' : 'Sign in' }
            </button>
          </form>
        </div>
      </div>
      </div>
    </div>
  </div>
    </>
  )
}
