import React from 'react'
import './App.css';
import Navbar from './Common/Navbar';
import { HeroSection } from './HeroSection/HeroSection';
import VideoSlider from './VideoCrads/VideoCards';
import { IntroSection } from './IntroSection/IntroSection';
import { Branding } from './Branding/Branding';
import { StorySection } from './storySection/StorySection';
import { Features } from './Features/Features';
import { FeaturesCell } from './Features/FeaturesCell';
import { BottomSecton } from './Footer/BottomSecton';
import { Footer } from './Footer/Footer';
import { AuthWrapper } from './AuthWrapper'
export const HomePage = () => {
  return (
   <AuthWrapper>
    <div className="App" >
      <Navbar/>
      <HeroSection/>
      <VideoSlider/>
      <IntroSection/>
      <Branding/>
      <StorySection/>
      <Features/>
      <FeaturesCell/>
      <BottomSecton/>
      <Footer/>
    </div>
    </AuthWrapper>
    
  )
}
