/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import charity from '../assets/charity.png'
export const Branding = () => {
  return (
    <div className='Mhome-container'>
        <div className='connecting'>
        <div className='connecting-badge margin-t-32'>
        Trusted by top brands & business
        </div>
        <h2 className='connecting__heading home-type-heading'>
        Every day thousands of people rely on KWF to get 
        served with respect.
        </h2>
        <div className='home-type-body-large'>
        The Umbrella under which various humanitarian services are 
        provided such as sheltering the abandoned newborn babies 
        the Orphanage, the Old Home, the Women Shelter and Home 
        for Children with Special needs.
        </div>
        </div>
        <div className='perfect-match'>
        <div className='perfect-match__left'>
        <h3 className='home-type-subheading'>
        What have we done with your help?
        </h3>
        <div className='home-type-body margin-t-24 margin-b-32'>
        In these hard times of poverty, unemployment, low wages 
        amid escalating rate of inflation, the poor people are 
        suffering the most. They find it most difficult to earn 
        enough to feed their kith and kin and provide them with 
        clothing and education. To offer solace and relief to the poor. 
        </div>
        <div className='perfect-match__actions'>
            <a className='btn2 btn2--large'
            data-track-cta="Find a designer"
            style={{backgroundColor:'#0D0C22', color:'white'}}
            href='/donation'
            >
              Donate Now
            </a>
            {/* <a className='btn2 btn2--large tertary_btn'
            data-track-cta="Learn about hiring"
            style={{ backgroundColor: 'transparent', color: 'black', borderColor: '#e7e7e9', borderWidth: '0.5px', borderStyle: 'solid' }}

            >
            Learn About
            </a> */}
        </div>
        </div>
        <div className='perfect-match__right'>
        <img src={charity} alt='img' 
        style={{
          width:'100%', 
          height:'auto', 
          borderRadius:'30px'
          }}/>
        </div>
        </div>
    </div>
  )
}
