/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import Family from "../assets/Family_Registration.png";
import { fetchApi } from "../utlis/axios";
import { useSelector } from "react-redux";
import backArrow from "../assets/back-button.png";
import { useNavigate, useLocation } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";

export const PropertyRegistration = () => {
  const [step, setStep] = useState(1);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [type, setType] = useState("date");
  const { id } = useSelector((state) => state.authSlice.userInfo?.user);
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [documentError, setDocumentError] = useState("");
  const [familyData, setFamilyData] = useState();
  const [family, setFamily] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    type: "",
    worth: null,
    owned_since: "",
    description: "",
    pin_location: "",
    family: null,
    documents: [],
  });

  const familyId = state && state.familyId;

  const [validationErrors, setValidationErrors] = useState({
    family: "",
    documents: [],
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleFileInputChange = async (e) => {
    const newFiles = e.target.files;

    if (newFiles.length > 0) {
      setLoading(true);
      const updatedSelectedFiles = [...selectedFiles];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const data = new FormData();
        data.append("file", file);
        data.append("type", "Property Document");
        data.append("is_active", true);

        try {
          const result = await fetchApi.post("attachments/", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access}`,
            },
          });

          if (result?.status === 201) {
            updatedSelectedFiles.push({ id: result.data.id, name: file.name });
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          setDocumentError(
            "Error uploading one or more files. Please try again."
          );
          setLoading(false);
          return; // Stop further processing on error
        }
      }

      setSelectedFiles(updatedSelectedFiles);
      setLoading(false);
      setDocumentError(""); // Clear any previous error
    } else {
      setDocumentError("Please select at least one file.");
    }
  };

  const handleFileDelete = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);
    setSelectedFiles(updatedSelectedFiles);
  };

  const PropertyRegistration = async (e) => {
    e.preventDefault();
    // if (!validateForm()) {
    //     return;
    // }
    try {
      setLoading(true);
      const data = new FormData();
      data.append("id", id);
      data.append("name", formValues?.name);
      data.append("type", formValues?.type);
      data.append("worth", formValues?.worth);
      data.append("owned_since", formValues?.owned_since);
      data.append("description", formValues?.description);
      data.append("pin_location", formValues?.pin_location);
      data.append("family", formValues?.family || familyId);
      data.append("is_active", true);

      const result = await fetchApi.post("property/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 201) {
        console.log("Family data", data);
        navigate(`/familylistingdetail/${familyId || formValues?.family}`);
      }
      setFormValues({
        ...formValues,
        name: "",
        type: "",
        worth: "",
        owned_since: "",
        description: "",
        pin_location: "",
        family: null,
        documents: [],
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading back to false when API call is completed
    }
  };

  const getFamilydata = async () => {
    try {
      const result = await fetchApi.get(`family/${familyId}`, {
        headers: {
          Authorization: `Bearer ${access}`, // Replace with your API key
        },
      });
      if (result.status === 200) {
        setFamilyData(result.data);
        console.log("Family data...", result.data);
      } else {
        console.error("Failed to fetch data:", result.status);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getFamily = async () => {
    try {
      const result = await fetchApi.get("family/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setFamily(result?.data);
        console.log("Families data...", result.data);
      } else {
        console.error("Failed to fetch data:", result.status);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      family: "",
      documents: [],
    };

    if (!familyId) {
      // If familyId is not present, check if formValues.family is empty
      if (!formValues.family) {
        valid = false;
        errors.family = "Family is required";
      }
    }

    if (!selectedFiles.length) {
      valid = false;
      errors.documents = "Property Doc is required";
    }
    setValidationErrors(errors);
    return valid;
  };

  useEffect(() => {
    getFamilydata();
    getFamily();
  }, []);

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div
      class="main-container familyRegistration text-white"
      style={{ minHeight: "100vh" }}
    >
      <div className="bg-gray-700 w-full bg-opacity-70 h-screen bg-fixed overflow-y-auto">
        <div className="content">
          <button
            className="btn2 btn2--tertiary  back-btn backButton"
            style={{ backgroundColor: "white" }}
            onClick={handleGoBack}
          >
            <img src={backArrow} alt="back-button" />
          </button>
          <div className="formgroup pb-2">
            <h4 className="text-center">Proprty Registration Form</h4>
            <div className="field_Container">
              {step === 1 && (
                <>
                  <form
                    onSubmit={PropertyRegistration}
                    className="form_responsive"
                  >
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Property name</h6>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Property name"
                          className="formField"
                          value={formValues?.name}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      <div className="field-container">
                        <h6 className="field-txt">Property Type</h6>
                        <select
                          type="text"
                          id="type "
                          name="type "
                          className="formField custom-select"
                          value={formValues?.type}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              type: e.target.value,
                            })
                          }
                          required
                          style={{ paddingRight: "70px" }}
                        >
                          <option>Select</option>
                          <option value="Farming Land ">Farming Land </option>
                          <option value="5 to 10 lacs">5 to 10 lacs</option>
                          <option value="Residencial Property(House,Flat, or Plot)">
                            Residencial Property(House,Flat, or Plot)
                          </option>
                          <option value="Comercial Property(Shop,Comerical Land or plot)">
                            Comercial Property(Shop,Comerical Land or plot)
                          </option>
                          <option value="House Holds(Animals, Vehicles)">
                            House Holds(Animals, Vehicles)
                          </option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Property worth</h6>
                        <select
                          type="text"
                          id="worth "
                          name="worth "
                          className="formField custom-select"
                          value={formValues?.worth}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              worth: e.target.value,
                            })
                          }
                          required
                        >
                          <option>Select</option>
                          <option value="5 lacs or less">5 lacs or less</option>
                          <option value="5 to 10 lacs">5 to 10 lacs</option>
                          <option value="10 to 20 lacs">10 to 20 lacs</option>
                          <option value="20 to 50 lacs">20 to 50 lacs</option>
                          <option value="50 lacs or more">
                            50 lacs or more
                          </option>
                        </select>
                      </div>

                      <div className="field-container">
                        <h6 className="field-txt">Property ownd since</h6>
                        <input
                          type={type}
                          id="ownd"
                          name="ownd"
                          placeholder="Property ownd since"
                          onFocus={(event) => {
                            event.target.type = "date";
                          }}
                          className="formField"
                          onClick={(e) => setType("date")}
                          value={formValues?.owned_since}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              owned_since: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Description</h6>
                        <input
                          type="text"
                          id="Description"
                          name="Description"
                          placeholder="Description"
                          className="formField"
                          value={formValues?.description}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              description: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Location</h6>
                        <input
                          type="text"
                          id="location"
                          name="location"
                          placeholder="Property location"
                          value={formValues?.pin_location}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              pin_location: e.target.value,
                            })
                          }
                          className="formField"
                          required
                        />
                      </div>
                    </div>
                    {!familyId && (
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Select Family</h6>

                          <select
                            type="text"
                            id="Family "
                            name="Family "
                            placeholder="Select Family"
                            className="formField custom-select"
                            value={formValues?.family}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                family: e.target.value,
                              })
                            }
                            required
                          >
                            <option>Select</option>
                            {family?.results?.map((item) => {
                              return (
                                <option value={item?.id}>{item?.name}</option>
                              );
                            })}
                          </select>

                          <span className="error-message text-danger">
                            {validationErrors.family}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="next_btn">
                      <button className="Registration_btn" disabled={loading}>
                        {loading ? "Loading..." : "Register"}
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
