import React, { useRef, useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { drawImageOnCanvas } from "./utils";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../../css/Cards.css";
import Lottie from "lottie-react";
import Loader from "../../animation/Loader.json";
import { Link } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { toast } from "react-toastify";

export const Programs = () => {
  const { id } = useSelector((state) => state.authSlice.userInfo?.user);
  const [program, setProgram] = useState();
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [modal, setModal] = useState(false);
  const [isCheck, setIsCheck] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [programLoading, setProgramLoading] = useState(false);
  const [isHoveredId, setIsHoveredId] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    thumbnail: null,
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    description: "",
    thumbnail: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editProgramId, setEditProgramId] = useState(null);
  const [programMenu, setProgramMenu] = useState(false);
  const [cropModal, setCropModal] = useState(false);

  const toggle = () => {
    setIsEditing(false);
    setModal(!modal);
    setIsCheck(true);
    setFormValues({
      name: "",
      description: "",
      thumbnail: null,
    });
    setUploadSuccess(false);
    setSelectedFiles([]);
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      name: "",
      description: "",
      thumbnail: null,
    };
    if (!formValues.name) {
      valid = false;
      errors.name = "Name is required";
    }

    if (!formValues.description) {
      valid = false;
      errors.description = "description is required";
    }

    if (!selectedFiles.length) {
      valid = false;
      errors.thumbnail = "Image is required";
    }

    setValidationErrors(errors);
    return valid;
  };

  const fetchPrograms = async () => {
    setProgramLoading(true);
    try {
      const result = await fetchApi.get("programs/");
      if (result?.status === 200) {
        setProgram(result.data);
        setProgramLoading(false);
      }
    } catch (error) {
      console.log({ error });
      setProgramLoading(false);
    }
  };

  const handleEdit = (data) => {
    setIsEditing(true);
    setEditProgramId(data.id);
    setFormValues({
      name: data.name,
      description: data.description,
      thumbnail: null,
    });

    const updatedSelectedFiles = [];
    data.thumbnail.forEach((item) => {
      const shortenedName =
        item.file.length > 20 ? item.file.substring(0, 20) + "..." : item.file;
      updatedSelectedFiles.push({ id: item.id, name: shortenedName });
    });
    setSelectedFiles(updatedSelectedFiles);
    setModal(true);
  };

  const createProgram = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", formValues?.name);
      data.append("description", formValues?.description);
      selectedFiles.forEach((docId) => {
        data.append("thumbnail", docId?.id);
      });

      data.append("is_active", true);

      let result;
      if (isEditing) {
        // If editing, update the existing program
        result = await fetchApi.put(`programs/${editProgramId}/`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access}`,
          },
        });
      } else {
        // If not editing, create a new program
        result = await fetchApi.post("programs/", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access}`,
          },
        });
      }

      if (result?.status === 201 || result?.status === 200) {
        toast.success("Program created/updated");
        // console.log("Program created/updated");
        setModal(false);
        fetchPrograms();
      }
    } catch (error) {
      console.error("Error creating/updating program:", error);
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const handleDelete = async (cardId) => {
    try {
      const result = await fetchApi.delete(`programs/${cardId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 204) {
        fetchPrograms();
      }
    } catch (error) {
      console.log({ error });
    }
  };


  const handleFileDelete = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleHover = (id) => {
    setIsHoveredId(id);
  };

  const handleHoverEnd = () => {
    setIsHoveredId(null);
  };

  const [imgSrc, setImgSrc] = useState();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);

  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  const handleFileInputChange = async (file) => {
    if (!file) {
      return;
    }

    setLoading(true);
    setProgramLoading(true);

    const data = new FormData();
    data.append("file", file);
    data.append("type", "Program Thumbnail");
    data.append("is_active", true);

    try {
      const result = await fetchApi.post("attachments/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });

      if (result?.status === 201) {
        setSelectedFiles([...selectedFiles, { id: result.data.id, name: file.name }]);
        setUploadSuccess(true);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
      setProgramLoading(false);
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result);
        setCrop(null); 
        setCompletedCrop(null); 
        setCropModal(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const handleCompleteCrop = (crop) => {
    drawImageOnCanvas(imgRef.current, canvasRef.current, crop);
    setCompletedCrop(crop);
  };
  

  const handleCropDone = () => {
    if (!completedCrop || !canvasRef.current) {
      toast.error("Please crop the image first.");
      return;
    }

    canvasRef.current.toBlob((blob) => {
      const file = new File([blob], "cropped_image.jpg", { type: "image/jpeg" });
      handleFileInputChange(file);
      setCropModal(false);
    });
  };

  const setImgRef = (ref) => {
    imgRef.current = ref;
  };

  const canvasStyles = {
    width: Math.round(completedCrop?.width ?? 0),
    height: Math.round(completedCrop?.height ?? 0),
    display: completedCrop ? 'block' : 'none', // Hide the canvas initially
  };

  return (
    <>
      <section className="Admindashboard__task__wrapper ">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="dashboardCard">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h6 id="card-header" style={{ color: "black" }}>
                    Programs
                  </h6>
                  <div className="Tbtn">
                    <button
                      className="heading_btn"
                      style={{ backgroundColor: "#DE1285" }}
                      onClick={toggle}
                    >
                      Create Program
                    </button>
                  </div>
                </div>
                <div style={{ display: "block" }}>
                  {isCheck && (
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      className="modal-popups"
                    >
                      <ModalBody>
                        <h3 className="text-center text-dark">
                          Create Program
                        </h3>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                              >
                                Name:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="recipient-name"
                                style={{ borderRadius: "20px", height: "3rem" }}
                                value={formValues?.name}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    name: e.target.value,
                                  })
                                }
                              />
                              <span className="error-message text-danger">
                                {validationErrors.name}
                              </span>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                              >
                                Description:
                              </label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="recipient-name"
                                style={{ borderRadius: "20px", height: "3rem" }}
                                value={formValues?.description}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    description: e.target.value,
                                  })
                                }
                              />
                              <span className="error-message text-danger">
                                {validationErrors.description}
                              </span>
                            </div>
                            <div
                              className="form-group"
                              style={{ position: "relative" }}
                            >
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                              >
                                Upload Image:
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="recipient-name"
                                style={{
                                  borderRadius: "20px",
                                  height: "2.5rem",
                                }}
                                onChange={handleFileSelect}
                                multiple
                                accept=".jpg, .png"
                              />
                              <span className="error-message text-danger">
                                {validationErrors.thumbnail}
                              </span>
                              {loading && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "70%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <Lottie
                                    loop={true}
                                    animationData={Loader}
                                    style={{ width: "80px" }}
                                  />
                                </div>
                              )}
                              {/* Display selected files with delete icons */}
                              <div className="selected-files ms-2">
                                {selectedFiles.map((file, index) => (
                                  <div
                                    key={index}
                                    className="selected-file flex items-center gap-1"
                                  >
                                    <span>{file.name}</span>
                                    <span
                                      className="delete-icon"
                                      onClick={() => handleFileDelete(index)}
                                    >
                                      <img
                                        src={require("../../assets/delete.png")}
                                        alt="delete"
                                        width="15px"
                                        height="15px"
                                      />
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </form>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="cmn-btn">
                          <button
                            onClick={toggle}
                            style={{
                              backgroundColor: "#DE1285",
                              borderRadius: "30px",
                            }}
                          >
                            Close
                          </button>
                        </div>
                        <div className="cmn-btn">
                          <button
                            onClick={createProgram}
                            style={{
                              backgroundColor: "#DE1285",
                              borderRadius: "30px",
                            }}
                          >
                            {isEditing ? "Update" : "Create"}
                          </button>
                        </div>
                      </ModalFooter>
                    </Modal>
                  )}
                </div>
                {programLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: "70%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 40,
                    }}
                  >
                    <Lottie
                      loop={true}
                      animationData={Loader}
                      style={{ width: "150px" }}
                    />
                  </div>
                )}
                {program?.results?.length === 0 ? (
                  <div
                    style={{
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className="">
                      <b>No programs have been created.</b>
                    </div>
                  </div>
                ) : (
                  <div className="program_main">
                    {program?.results?.map((data, index) => (
                      <div
                        className="w-72 h-64 hover:shadow-lg card_main rounded-xl"
                        onMouseOver={() => handleHover(data.id)}
                        onMouseLeave={() => {
                          handleHoverEnd();
                          setProgramMenu(false);
                        }}
                        key={index}
                      >
                        <div className="card-img relative overflow-hidden">
                          {isHoveredId === data.id && (
                            <div>
                              <HiDotsVertical
                                className="absolute top-3 right-2 text-lg cursor-pointer text-[#DE1285]"
                                onClick={() => setProgramMenu(true)}
                              />
                              {programMenu && (
                                <div className="absolute text-sm right-6 top-7 bg-[#DE1285] text-white flex flex-col rounded-md text-center">
                                  <span
                                    className="p-1 cursor-pointer hover:underline"
                                    onClick={() => handleEdit(data)}
                                  >
                                    Edit
                                  </span>
                                  <hr className="m-0" />
                                  <span
                                    className="p-1 cursor-pointer hover:underline"
                                    onClick={() => handleDelete(data.id)}
                                  >
                                    Delete
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                          <Link to={`/dashboard/programDetail/${data.id}`}>
                            <img
                              className="w-72 h-40 rounded-t-xl"
                              src={data.thumbnail[0].file}
                              alt={`image ${index + 1}`}
                            />
                            <div className="card_content w-72 h-11 rounded-t-xl text-white flex justify-center items-center p-1">
                              {data?.name && data.name.length > 20
                                ? data.name.slice(0, 20) + "..."
                                : data?.name}
                            </div>
                          </Link>
                        </div>
                        <Link to={`/dashboard/programDetail/${data.id}`}>
                          <div className="p-2">
                            {data?.description && data.description.length > 100
                              ? data.description.slice(0, 100) + "....."
                              : data?.description}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={cropModal} toggle={() => setCropModal(!cropModal)}>
        <ModalBody>
          <div className="flex justify-center items-center">
            <ReactCrop
              src={imgSrc}
              crop={crop}
              onImageLoaded={setImgRef}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={handleCompleteCrop}
              aspect={2 / 1}
            >{imgSrc && (
                <img
                  ref={imgRef}
                  src={imgSrc}
                />
              )}</ReactCrop>
            <canvas
              ref={canvasRef}
              style={canvasStyles}
              hidden
            />
          </div>
        </ModalBody>
        <ModalFooter >
          <div className="flex gap-3">
          <button
            className="heading_btn"
            onClick={handleCropDone}
            style={{ backgroundColor: "#DE1285", width: "100%" }}
          >
            Done
          </button>
          <button className="heading_btn" style={{ backgroundColor: "#DE1285", width: "100%" }}  onClick={() => setCropModal(false)}>
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
