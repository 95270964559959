import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Lottie from 'lottie-react';
import Appreciate from '../animation/appreciate.json';
import { useNavigate } from 'react-router-dom';

const AppreciationModal = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();

  return (
    <div >
        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} dialogClassName="custom-modal" style={{ backgroundColor: '#F8F7F4' }}>
        <Modal.Body>
          <h3 className='text-center text-white'>Thanks for Donation</h3>
          <div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
            <Lottie
              loop={true}
              animationData={Appreciate}
              style={{ width: '300px' }}
            />
          </div>
          <div className='text-center'>
            <p className='h5 text-white'>
              Your Donation makes happiness for others!
            </p>
          </div>
          <p className='text-center text-white'>
            Thanks for your donation
          </p>

          <div className='cmn-btn' style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className="btn text-white mt-2"
              style={{
                backgroundColor: 'white',
                borderRadius: 30,
                padding: '10px',
                width: '50%',
              }}
              onClick={() => {
                setShow(false);
                navigate('/');
              }}
            >
              <p style={{ color: '#DE1285', margin: 0 }}>Return to Home</p>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AppreciationModal;
