import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";

const MapWithMarkers = () => {
  const [locations, setLocations] = useState([]);
  const { access } = useSelector((state) => state.authSlice.userInfo);

  // Inside the getLocation function
  const getLocation = async () => {
    try {
      const result = await fetchApi.get("family-graph/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      if (result.status === 200) {
        const locations = result.data
          .map((data) => {
            const pinLocation = data.pin_location;
            if (pinLocation) {
              const [latitude, longitude] = pinLocation
                .split(",")
                .map(parseFloat);
              return {
                lat: latitude,
                lng: longitude,
              };
            } else {
              return null; // Handle the case where pin_location is null
            }
          })
          .filter(Boolean); // Remove null values from the array

        setLocations(locations);
        console.log("new location data", locations);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = {
  //       data: {
  //         locations: [
  //           { id: 1, lat: 30.3753, lng: 69.3451, title: 'Location 1' },
  //           { id: 2, lat: 31.3753, lng: 70.3451, title: 'Location 2' },

  //         ],
  //       },
  //     };

  //     if (result.data.locations) {
  //       setLocations(result.data.locations);
  //       console.log("data location :",result.data.locations )
  //     }
  //   };

  //   fetchData();
  // }, []);

  const mapStyles = {
    height: "500px",
    width: "85%",
  };

  const defaultCenter = {
    lat: 30.3753,
    lng: 69.3451,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAvtic0FI99sAH_qzq0HOblwRNTBqh3UiY" >
      <GoogleMap mapContainerStyle={mapStyles} zoom={2} center={defaultCenter}>
        {locations.length > 0 && (
          <MarkerClusterer
            options={{
              imagePath:
                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            }}
          >
            {(clusterer) =>
              locations.map((location) => (
                <Marker
                  key={location.id}
                  position={{ lat: location.lat, lng: location.lng }}
                  clusterer={clusterer}
                  title={location.title}
                />
              ))
            }
          </MarkerClusterer>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapWithMarkers;
