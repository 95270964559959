import Education from '../assets/education.jpg';
import Health from '../assets/health.jpg';
import Food from '../assets/food.jpg'
import Home from '../assets/home.jpg';
import Clothe from '../assets/clothes.jpg'

const CardData = [
    { 
        type: 'image',
        // src: 'https://cdn.dribbble.com/uploads/47171/original/daniele-buffa-3.png?1689174763&format=webp&resize=640x797&vertical=center',
        src: Education,
        code: 'LHIr1,b0E2_29w-:s8a~_4ofs+M|',
        heading:'Donation for',
        text: 'Donation for Education'
      },
      {
        type: 'image',
        // src: 'https://cdn.dribbble.com/uploads/47178/original/mercedes-bazan.png?1689174566&format=webp&resize=546x680&vertical=center',
        src: Health,
        heading:'Donation for',
        code: 'LfPskysApxW;-;ayRjfQp{bbMdjZ',
        text: 'Donation for Health'
      },
      {
        type: 'image',
        // src: 'https://cdn.dribbble.com/uploads/47170/original/cd3266dde4f00a5d6a509c7375ddaecd.png?1685644840&format=webp&resize=640x797&vertical=center',
        src: Home,
        code: 'LUPGpd%OIW-:-:M_Wpn$_4awW9M|',
        heading:'Donation for',
        text: 'Donation for Shelter'
      },
      {
        type: 'image',
        // src: 'https://cdn.dribbble.com/uploads/47176/original/9b22cd83bde1809976bec0722d1f8923.jpeg?1685645213&format=webp&resize=546x680&vertical=center',
        src: Food,
        code: 'LePr,yof~Vsp-fj[I^oL?ajaMxW.',
        heading:'Donation for',
        text: 'Donation for Food'
      },
      // {
      //   type: 'image',
      //   src: 'https://cdn.dribbble.com/uploads/47173/original/Vladimir_Gruev.png?1689174896&format=webp&resize=546x680&vertical=center',
      //   heading:'Donation for',
      //   text: 'Business'
      // },
      {
        type: 'image',
        // src: 'https://cdn.dribbble.com/uploads/47172/original/d85ae8c7db2421e9a01ecac942978d4b.png?1685645079&format=webp&resize=273x340&vertical=center',
        src: Clothe,
        code: 'LqOMmVRi-;V@_NogNHfk%hWCMxof',
        heading:'Donation for',
        text: 'Donation for Clothes'
      },
      // {
      //   type: 'image',
      //   src: 'https://cdn.dribbble.com/uploads/47175/original/1fb34610061a95a007ac5e7b1dc53138.jpeg?1685645183&format=webp&resize=546x680&vertical=center',
      //   heading:'Donation for',
      //   text: 'Education'
      // }
      
];

export default CardData;
