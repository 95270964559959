import React from 'react'
import author from '../assets/author/author.jpg'
import charity from '../assets/icons8-charity-80.png'
export const StorySection = () => {
  return (
    <div className='better Story-container'>
        <h3 className='better__heading home-type-subheading'>
        Khadim Welfare Foundation <span><i style={{fontSize:'30px'}}>Humanitarian Services</i></span>
        </h3>
        <div className='better__list'>
        <div className='better__item'>
            <h4 className='better__subheading'>
            KWF MONTHLY RATION
            </h4>
            <div className='better__copy'>
            in these hard times and rising costs, 
            poor families suffer the most. KWF 
            RATION provides monthly subsistence for 
            groceries and sundry worth Rs.2,000/-
            </div>
        </div>
        <div className='better__item'>
            <h4 className='better__subheading'>
            KWF EDUCATION SUPPORT
            </h4>
            <div className='better__copy'>
           KWF focused on providing free and high quality education 
           to under privileged children. Their mission is to increase 
           literacy rate in Pakistan. It is our endeavor to provide quality 
           marketable education to all. KWF views its work as 
           nation building: by empowering our children with a solid education.
            </div>
        </div>
        <div className='better__item'>
            <h4 className='better__subheading'>
            KWF HEALTH SUPPORT
            </h4>
            <div className='better__copy'>
            In these hard times of poverty, unemployment, 
            low wages amid escalating rate of inflation, 
            the poor people are suffering the most. They 
            find it most difficult to earn enough to feed 
            their kith and kin and provide them with quality 
            health. To offer solace and relief to the poor.
            </div>
        </div>
        </div>
        <div className='better-testimonial'>
        <div className='better-testimonial__left'>
        <div className='better-testimonial__quote'>
        “A crafty one, a miser, and one who keeps reminding people of 
        what he has given, will not enter Paradise.”
        </div>
        <div className='better-testimonial__user-container'>
        {/* <img src={author} alt='img' width='40px' style={{borderRadius:50}}/> */}
        <div className='better-testimonial__user-info'>
        <div className='better-testimonial__name'>
        Prophret —
        </div>
        <div className='better-testimonial__title'>
        Muhammad (PBH)
        </div>
        </div>
        </div>
        </div>
        <div className='better-testimonial__right'>
        <img src={charity} alt='img' />
        </div>
        </div>
    </div>
  )
}
