import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { fetchApi } from '../../utlis/axios';

const MedicationChart = () => {
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [medication, setMedication] = useState([]);

  useEffect(() => {
    getMedicationData();
  }, []); // Fetch data when the component mounts

  const getMedicationData = async () => {
    try {
      const result = await fetchApi.get('members-graph/', {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      if (result.status === 200) {
        const medicationDataResponse = result.data;
        const monthlyMedication = {};
        const currentYear = new Date().getFullYear();

        medicationDataResponse.forEach((medicationData) => {
          const createdDate = new Date(medicationData.created_at);
          const medicationYear = createdDate.getFullYear();

          if (medicationYear === currentYear && medicationData.permanent_medication === true) {
            const month = createdDate.toLocaleString('en-US', { month: 'long' });

            if (monthlyMedication[month]) {
              monthlyMedication[month]++;
            } else {
              monthlyMedication[month] = 1;
            }
          }
        });

        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = new Date(null, i).toLocaleString('en-US', {
            month: 'long',
          });
          return {
            month: monthName,
            count: monthlyMedication[monthName] || 0,
          };
        });

        setMedication(allMonths);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const data = {
    labels: medication.map((entry) => entry.month),
    datasets: [
      {
        type: 'line',
        label: 'Permanent Medication',
        data: medication.map((entry) => entry.count),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category',
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        precision: 0,
      },
    },
  };

  return (
    <div className='dashboard-responsive'>
      <Bar data={data} options={options} height={230}/>
    </div>
  );
};

export default MedicationChart;