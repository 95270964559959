import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import ReceiptModel from "../Receipts/ReceiptModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineEdit } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";

const MedicineData = ({setShowModel}) => {
  const [medicines, setMedicines] = useState([]);
  const [medicineName, setMedicineName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  const [showReceiptModel, setShowReceiptModel] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const [editIndex, setEditIndex] = useState(null);

  const toggleShowmodel = () => {
    setShowReceiptModel(true);
  };

  const totalAmount = medicines.reduce(
    (acc, cur) => acc + Number(cur.amount),
    0
  );

  const handleAddMedicine = () => {
    if (medicineName && quantity && amount) {
      if (totalAmount + Number(amount) > 5000) {
        toast.error("You cannot purchase medicines more than Rs:5000");
      } else {
        const newMedicine = {
          name: medicineName,
          quantity: quantity,
          amount: amount,
        };

        if (editIndex !== null) {
          const updatedMedicines = [...medicines];
          updatedMedicines[editIndex] = newMedicine;
          setMedicines(updatedMedicines);
          setEditIndex(null);
        } else {
          setMedicines([...medicines, newMedicine]);
        }

        setMedicineName("");
        setQuantity("");
        setAmount("");
      }
    } else {
      toast.error("Please fill in all fields");
    }
  };

  const handleEdit = (index) => {
    const medicine = medicines[index];
    setMedicineName(medicine.name);
    setQuantity(medicine.quantity);
    setAmount(medicine.amount);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    const updatedMedicines = medicines.filter((_, i) => i !== index);
    setMedicines(updatedMedicines);
    setEditIndex(null)
  };

  return (
    <>
      {showReceiptModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div className="bg-gray-900 bg-opacity-80 h-full w-full fixed"></div>
          <ReceiptModel setShowReceiptModel={setShowReceiptModel} medicines={medicines} />
        </div>
      )}
      <div className="h-screen overflow-y-auto flex justify-center items-center">
        <div className="p-1">
          <div className="max-w-4xl mx-auto bg-white sm:p-8 p-2 rounded shadow-xl relative">
            <div
              className="absolute text-black right-2 top-2 cursor-pointer text-2xl"
              onClick={() => {
                setShowModel(false); 
              }}
            >
              <IoMdCloseCircle />
            </div>
            {isVerified ? (
              <div className="p-1">
                <h1 className="text-2xl font-bold mb-4 flex justify-center pt-2">
                  Add Medicines
                </h1>
                <div className="text-red-600">
                  <span className="font-bold text-black">Note: </span>
                  Patients are advised that the purchase of medicines should not
                  exceed a total of Rs:5000 for a single transaction.
                </div>
                <div className="flex md:flex-row flex-col gap-2 py-3 sm:px-3 justify-between">
                  <div>
                    <input
                      type="text"
                      placeholder="Medicine Name"
                      value={medicineName}
                      onChange={(e) => setMedicineName(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      placeholder="Quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <button
                      onClick={handleAddMedicine}
                      className="bg-gray-900 text-white py-2 px-2 rounded-md text-sm"
                    >
                      {editIndex !== null ? "Update Medicine" : "Add Medicine"}
                    </button>
                  </div>
                </div>

                {medicines.length === 0 ? (
                  <h4 className="py-20 text-center">No Medicine List Found</h4>
                ) : (
                  <>
                    <h2 className="text-xl font-semibold mb-4">
                      Medicine List
                    </h2>
                    <div className="mt-8 overflow-x-auto">
                      <table className="table align-middle mb-0 bg-white table-hover">
                        <thead className="table-light m-3">
                          <tr className="m-3">
                            <th className="px-2 py-2">No.</th>
                            <th className="px-2 py-2">Medicine Name</th>
                            <th className="px-2 py-2">Quantity</th>
                            <th className="px-2 py-2">Amount</th>
                            <th className="px-2 py-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {medicines.map((medicine, index) => (
                            <tr key={index}>
                              <td style={{ verticalAlign: "middle" }}>
                                <div className="d-flex">
                                  <div>
                                    <p className="fw-bold mb-3 mt-3">
                                      {index + 1}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <div className="dot d-flex align-items-start justify-content-start w-100">
                                  {medicine.name}
                                </div>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <span>{medicine.quantity}</span>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <span>{medicine.amount}</span>
                              </td>
                              <td style={{ verticalAlign: "middle" }} >
                                <div className="flex gap-2">
                                <MdOutlineEdit className="cursor-pointer text-2xl" onClick={() => handleEdit(index)}/> 
                                <MdOutlineDeleteOutline className="cursor-pointer text-2xl" onClick={() => handleDelete(index)}/>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-base font-semibold px-2 py-2"
                            >
                              Total:
                            </td>
                            <td className="text-base font-semibold px-4 py-2">
                              Rs:{totalAmount}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="mt-8 flex justify-end">
                      <div className="w-60">
                        <button
                          className="bg-gray-900 text-white py-2 px-2 rounded-md text-base w-full"
                          onClick={toggleShowmodel}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="p-3">
                <h1 className="text-2xl font-bold mb-4 flex justify-center pt-2">
                  Capture Fingerprint
                </h1>
                <div className="flex justify-center items-center my-3 flex-col gap-2">
                  <div className="scan">
                    <div className="fingerprint"></div>
                    <span className="border_btm"></span>
                  </div>
                  <h3 className="scanning_text">Scanning...</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MedicineData;
