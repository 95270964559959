import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { toast } from "react-toastify";

const TakeFingerprint = ({ setShowModel }) => {
  const [fingerprintData, setFingerprintData] = useState([]);
  const [capture, setCapture] = useState(false);
  const obj = { id: 123 };
  const [fingerprint, setFingerprint] = useState(null);
  const [deviceId, setDeviceId] = useState("");

  useEffect(() => {
    const initializeFingerprintSdk = async () => {
      const FingerprintSdk = () => {
        const sdk = new window.Fingerprint.WebApi();

        const getDeviceList = () => {
          return sdk.enumerateDevices();
        };

        const startCapture = async () => {
          setCapture(true);
          try {
            sdk.startAcquisition(window.Fingerprint.SampleFormat.PngImage);

            sdk.onSamplesAcquired = async function (s) {
              try {
                const samples = await JSON.parse(s.samples);
                const file =
                  "data:image/png;base64," +
                  window.Fingerprint.b64UrlTo64(samples[0]);
                setFingerprintData((prevFingerprintData) => [
                  ...prevFingerprintData,
                  file,
                ]);
              } catch (error) {
                console.error("Error capturing fingerprint", error);
              } finally {
                setCapture(false);
                sdk.stopAcquisition();
              }
            };
          } catch (error) {
            console.error("Error starting acquisition:", error);
          }
        };

        return { getDeviceList, startCapture };
      };

      const fingerprintSdk = FingerprintSdk();
      setFingerprint(fingerprintSdk);
    };

    initializeFingerprintSdk();
  }, []);

  const getInfo = async () => {
    try {
      const devices = await fingerprint.getDeviceList();
      setDeviceId(devices[0]);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleRetake = (e) => {
    if (fingerprintData.length > 0) {
      const newFingerprintData = [...fingerprintData];
      newFingerprintData.pop();
      setFingerprintData(newFingerprintData);
      fingerprint?.startCapture();
    }
  };

  const handleFingerprints = (e) => {
    e.preventDefault();
    if (fingerprintData.length < 3) {
      toast.error("You must have to add 3 fingerprints");
    }
  };

  return (
    <div className="h-screen overflow-y-auto flex justify-center items-center">
      <div className="p-1">
        <div className="max-w-md mx-auto bg-white sm:p-8 p-2 rounded shadow-xl relative">
          <div
            className="absolute text-black right-2 top-2 cursor-pointer text-2xl"
            onClick={() => setShowModel(false)}
          >
            <IoMdCloseCircle />
          </div>
          <div className="p-1">
            <h1 className="text-2xl font-bold mb-2 flex justify-center pt-2 text-black">
              Capture Fingerprint
            </h1>
            <div className="text-black">
              {deviceId ? (
                <>{console.log(fingerprintData)}
                  {fingerprintData?.map((data, index) => (
                    <div className="flex gap-2" key={index}>
                      <span>{`Fingerprint ${index + 1}`}</span>
                      {fingerprintData.length >= index && (
                        <div className="bg-green-500 h-5 w-5 rounded flex justify-center items-center">
                          <TiTick className="text-white" />
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="text-red-600">
                    <span className="font-bold text-black">Note: </span>
                    Please ensure to include a minimum of three fingerprints
                    from your hand.
                  </div>
                  {capture && (
                    <div className="flex justify-center items-center my-3 flex-col gap-2">
                      <div className="scan">
                        <div className="fingerprint"></div>
                        <span className="border_btm"></span>
                      </div>
                      <h3 className="scanning_text">Scanning...</h3>
                    </div>
                  )}
                  <div className="flex gap-2 my-2">
                    <div>
                      <button
                        className="bg-gray-900 text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded w-full md:w-auto z-10"
                        onClick={fingerprint?.startCapture}
                      >
                        Capture
                      </button>
                    </div>
                    <div>
                      {fingerprintData.length > 0 && (
                        <button
                          className="bg-gray-900 text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded w-full md:w-auto z-10"
                          onClick={handleRetake}
                        >
                          Retake
                        </button>
                      )}
                    </div>
                    <div>
                      <button
                        className="bg-gray-900 text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded w-full md:w-auto z-10"
                        onClick={handleFingerprints}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  No device connected{" "}
                  <button
                    className="bg-gray-900 text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded w-full md:w-auto z-10"
                    onClick={getInfo}
                  >
                    Retry
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakeFingerprint;
