import React from 'react'
import DonationForm from './DonationForm'
import Header from '../Common/Navbar'
import { AuthWrapper } from '../AuthWrapper'

export const DonationPage = () => {
  return (
    // <AuthWrapper>
    <div>
        <Header/>
        <DonationForm/>
    </div>
    // </AuthWrapper>
  )
}
