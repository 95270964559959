/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import Family from "../assets/family.jpg";
import { fetchApi } from "../utlis/axios";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import backArrow from "../assets/back-button.png";
import { toast } from "react-toastify";
import { AuthWrapper } from "../AuthWrapper";
import PhoneInput from "react-phone-number-input";

export const FamilyRegistration = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useSelector((state) => state.authSlice.userInfo?.user);
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    status: null,
    status_statement: null,
    address: "",
    village_or_city: "",
    tehsil: "",
    district: "",
    description: "",
    post_code: "",
    application_priority: "high",
    pin_location: "",
    father_name: "",
    mother_name: "",
    number_of_sons: "",
    number_of_daughters: "",
    contact_number: "",
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`);
          setFormValues({
            ...formValues,
            pin_location: `Latitude: ${latitude}, Longitude: ${longitude}`,
          });
        },
        (error) => {
          setLocation(`Error: ${error.message}`);
          toast.error(error.message);
        }
      );
    } else {
      setLocation("Geolocation is not supported in this browser.");
    }
  };

  const PostRegistration = async (e) => {
    e.preventDefault();
    if (!formValues.pin_location) {
      toast.error("Location required");
      return;
    }

    try {
      setLoading(true);
      const data = new FormData();
      data.append("id", id);
      data.append("name", formValues?.name);
      data.append("status", formValues?.status);
      data.append("status_statement", formValues?.status_statement);
      data.append("address", formValues?.address);
      data.append("village_or_city", formValues?.village_or_city);
      data.append("tehsil", formValues?.tehsil);
      data.append("district", formValues?.district);
      data.append("description", formValues?.description);
      data.append("post_code", formValues?.post_code);
      data.append("application_priority", formValues?.application_priority);
      data.append("pin_location", formValues?.pin_location);
      data.append("father_name", formValues?.father_name);
      data.append("mother_name", formValues?.mother_name);
      data.append("number_of_sons", formValues?.number_of_sons);
      data.append("number_of_daughters", formValues?.number_of_daughters);
      data.append("contact_number", formValues?.contact_number);
      data.append("status", "in-review");
      data.append("is_active", true);

      const result = await fetchApi.post("family/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 201) {
        // toast.success('Upload Application sucessfully!');
        console.log("Family data", data);
        navigate(`/familylistingdetail/${result?.data?.id}`);
        setIsLoading(true);
      }
      setFormValues({
        ...formValues,
        name: "",
        total_members: null,
        male_members: null,
        female_members: null,
        under_sixteen_members: null,
        over_sixty_members: null,
        status: null,
        status_statement: null,
        address: "",
        village_or_city: "",
        tehsil: "",
        district: "",
        description: "",
        post_code: "",
        application_priority: "",
        pin_location: "",
        father_name: "",
        mother_name: "",
        number_of_sons: "",
        number_of_daughters: "",
        contact_number: "",
      });
      setStep(step - 1);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false); // Set loading back to false when API call is completed
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <AuthWrapper>
      <div
        class="main-container familyRegistration text-white"
        style={{ minHeight: "100vh" }}
      >
        <div className="bg-gray-700 w-full bg-opacity-70 h-screen bg-fixed overflow-y-auto">
          <div className="content">
            <button
              className="btn2 btn2--tertiary  back-btn backButton"
              style={{ backgroundColor: "white" }}
              onClick={handleGoBack}
            >
              <img src={backArrow} alt="back-button" />
            </button>
            <div className="formgroup">
              <h4 className="text-center">Family Registration Form</h4>
              <p>
                Your generosity opens up a world of possibilities for the
                pediatric medicine of today and tomorrow.
              </p>
              <div className="field_Container pb-2">
                {step === 1 && (
                  <>
                    <form onSubmit={handleNext} className="form_responsive">
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Family Name</h6>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Family Name"
                            className="formField"
                            value={formValues?.name}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>

                        <div className="field-container">
                          <h6 className="field-txt">Status Statement</h6>
                          <input
                            type="text"
                            id="Status Statement"
                            name="Status Statement"
                            placeholder="Status Statement"
                            className="formField"
                            value={formValues?.status_statement}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                status_statement: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt"> Village</h6>
                          <input
                            type="text"
                            id="Village"
                            name="Village"
                            placeholder="Village"
                            className="formField"
                            value={formValues?.village_or_city}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                village_or_city: e.target.value,
                              })
                            }
                            required
                          />
                        </div>

                        <div className="field-container">
                          <h6 className="field-txt">Tahsil</h6>
                          <input
                            type="text"
                            id="Tahsil"
                            name="Tahsil"
                            placeholder="Tahsil"
                            className="formField"
                            value={formValues?.tehsil}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                tehsil: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">District</h6>
                          <input
                            type="text"
                            id="District"
                            name="District"
                            placeholder="District"
                            className="formField"
                            value={formValues?.district}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                district: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Post code</h6>
                          <input
                            type="text"
                            id="Post code"
                            name="Post code"
                            placeholder="Post code"
                            className="formField "
                            value={formValues?.post_code}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                post_code: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">pin-location</h6>
                          <input
                            type="button"
                            id="pin-location"
                            name="pin-location"
                            onClick={getLocation}
                            style={{ display: "none" }}
                            required
                          />
                          <input
                            type="text"
                            id="pin-location-text"
                            name="cnicfront-text"
                            placeholder="Pin Location"
                            onClick={() =>
                              document.getElementById("pin-location").click()
                            }
                            className="formField custom-field "
                            value={formValues?.pin_location}
                            readOnly
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Application Priority</h6>
                          <select
                            type="text"
                            id="Application Priority"
                            name="Application Priority"
                            placeholder="Application Priority"
                            className="formField custom-select"
                            value={formValues?.application_priority}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                application_priority: e.target.value,
                              })
                            }
                            required
                          >
                            <option>high</option>
                            <option>low</option>
                            <option>normal</option>
                          </select>
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Address</h6>
                          <input
                            type="text"
                            id="Address"
                            name="Address"
                            placeholder="Address"
                            className="formField"
                            value={formValues?.address}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                address: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Description</h6>
                          <input
                            type="text"
                            id="Description"
                            name="Description"
                            placeholder="Description"
                            className="formField"
                            value={formValues?.description}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                description: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="next_btn">
                        <button className="Registration_btn">Next</button>
                      </div>
                    </form>
                  </>
                )}
                {step === 2 && (
                  <>
                    <form
                      onSubmit={PostRegistration}
                      className="form_responsive"
                    >
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Father Name</h6>
                          <input
                            type="text"
                            id="father_name"
                            name="father_name"
                            placeholder="Father Name"
                            className="formField"
                            value={formValues?.father_name}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                father_name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>

                        <div className="field-container">
                          <h6 className="field-txt">Mother Name</h6>
                          <input
                            type="text"
                            id="mother_name"
                            name="mother_name"
                            placeholder="Mother Name"
                            className="formField"
                            value={formValues?.mother_name}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                mother_name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Number of Sons</h6>
                          <input
                            type="number"
                            id="Number of Sons"
                            name="Number of Sons"
                            placeholder="Number of Sons"
                            className="formField "
                            value={formValues?.number_of_sons}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 3);
                              setFormValues({
                                ...formValues,
                                number_of_sons: inputValue,
                              });
                            }}
                            maxLength="3"
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Number of Daughters</h6>
                          <input
                            type="number"
                            id="Number of Daughter"
                            name="Number of Daughter"
                            placeholder="Number of Daughters"
                            className="formField "
                            value={formValues?.number_of_daughters}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 3);
                              setFormValues({
                                ...formValues,
                                number_of_daughters: inputValue,
                              });
                            }}
                            maxLength="3"
                            required
                          />
                        </div>
                      </div>
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Contact Number</h6>
                          <PhoneInput
                            type="text"
                            value={formValues?.contact_number}
                            name="contact_number"
                            placeholder="Enter your phone"
                            className="formField "
                            required
                            onChange={(value) =>
                              setFormValues({
                                ...formValues,
                                contact_number: value,
                              })
                            }
                            style={{
                              display: "flex",
                              background: "white",
                              borderRadius: "30px",
                              padding: "0px 10px",
                              border: "2px solid #f4f4f4",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                        {/* <div className='field-container'>
          <h6 className='field-txt'>No. of Sons</h6>
          <input 
            type='text' 
            id='Status Statement' 
            name='Status Statement' 
            placeholder='Status Statement' 
            className='formField' 
            value={formValues?.status_statement}
            onChange={(e)=> setFormValues({...formValues, status_statement:e.target.value})}
            required/>
        </div> */}
                        <div className="field-container"></div>
                      </div>
                      <div className="btn_conatiner">
                        <button
                          onClick={handleBack}
                          className="Registration_Backbtn"
                        >
                          Back
                        </button>
                        <button className="Registration_btn" disabled={loading}>
                          {loading ? "Loading..." : "Register"}
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};
