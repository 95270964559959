import React, { useState } from "react";
import { toast } from "react-toastify";
import { fetchApi } from "../utlis/axios";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import backArrow from "../assets/back-button.png";
import { IoMdCloseCircle } from "react-icons/io";
import { MdAddPhotoAlternate } from "react-icons/md";
import { MdDriveFolderUpload } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const PostCase = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [caseFiles, setCaseFiles] = useState([]);
  const [caseLoading, setCaseLoading] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const validationError = [];
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
  const videoExtensions = [
    "mp4",
    "avi",
    "mov",
    "mkv",
    "wmv",
    "flv",
    "webm",
    "mpeg",
    "m4v",
  ];

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    pin_address: "",
    manual_complete_address: "",
    required_amount: "",
    category: "healthcare",
    contact_detail: [
      {
        name: "",
        email: "",
        phonenumber: "",
      },
    ],
    attachment_before_completion: [],
  });

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`);
          setFormValues({
            ...formValues,
            pin_address: `Latitude: ${latitude}, Longitude: ${longitude}`,
          });
        },
        (error) => {
          setLocation(`Error: ${error.message}`);
          toast.error(error.message);
        }
      );
    } else {
      setLocation("Geolocation is not supported in this browser.");
    }
  };

  const validateForm = () => {
    let valid = true;
    let images = [];
    let videos = [];
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
    const videoExtensions = [
      "mp4",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "flv",
      "webm",
      "mpeg",
      "m4v",
    ];
    if (caseFiles?.length === 0) {
      validationError.push("Upload a picture and video");
      valid = false;
    } else {
      caseFiles.forEach((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        if (imageExtensions.includes(fileExtension)) {
          images.push(file);
        } else if (videoExtensions.includes(fileExtension)) {
          videos.push(file);
        }
      });
      if (images?.length === 0) {
        validationError.push("You must have to upload a image");
        valid = false;
      } else if (videos?.length === 0) {
        validationError.push("You must have to upload a video");
        valid = false;
      } else if (videos?.length === 2) {
        validationError.push("You cannot upload two videos");
        valid = false;
      }
    }
    return valid;
  };

  const PostRegistration = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error(validationError[0]);
      return;
    }
    try {
      setLoading(true);
      const data = new FormData();
      data.append("title", formValues?.title);
      data.append("description", formValues?.description);
      data.append("pin_address", formValues?.pin_address);
      data.append(
        "manual_complete_address",
        formValues?.manual_complete_address
      );
      data.append("required_amount", formValues?.required_amount);
      data.append("contact_detail", JSON.stringify(formValues?.contact_detail));
      caseFiles.forEach((docId) => {
        data.append("attachment_before_completion", docId?.id);
      });

      const result = await fetchApi.post("case/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (result?.status === 201) {
        toast.success("Your case post successfully");
        navigate("/casesfeeds",{
          state:{
            previousUrl: '/postcase',
          }
        });
      }
      setFormValues({
        ...formValues,
        title: "",
        description: "",
        pin_address: "",
        manual_complete_address: "",
        required_amount: "",
        category: "healthcare",
        contact_detail: [],
        attachment_before_completion: [],
      });
      setPreviews([]);
      setCaseFiles([]);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false); // Set loading back to false when API call is completed
    }
  };

  const handleFileDelete = (index) => {
    const updateCaseFiles = [...caseFiles];
    updateCaseFiles.splice(index, 1);
    setCaseFiles(updateCaseFiles);
    const updatedPreviews = [...previews];
    updatedPreviews.splice(index, 1);
    setPreviews(updatedPreviews);
    document.getElementById("Case attachment").value = ""; // Reset file input to allow re-upload
  };

  const sendFiles = async (e) => {
    const newFiles = e.target.files;

    if (newFiles.length > 0) {
      setCaseLoading(true);
      const updatedSelectedFiles = [...caseFiles];
      const updatedPreviews = [...previews];
      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const data = new FormData();
        data.append("file", file);
        data.append("type", "case");
        data.append("is_active", true);

        try {
          const result = await fetchApi.post("case-attachments/", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (result?.status === 201) {
            updatedSelectedFiles.push({ id: result.data.id, name: file.name });
            const reader = new FileReader();
            reader.onload = (e) => {
              updatedPreviews.push(e.target.result);
              setPreviews([...updatedPreviews]);
            };
            reader.readAsDataURL(file);
          }
        } catch (error) {
          toast.error("Something went wrong");
          console.error("Error uploading file:", error);
          setCaseLoading(false);
          document.getElementById("Case attachment").value = ""; // Reset file input to allow re-upload
          return; // Stop further processing on error
        }
      }

      setCaseFiles(updatedSelectedFiles);
      setCaseLoading(false);
    } 
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const handlePopup = () => {
    setShowModel(true);
  };

  return (
    <>
      {showModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div
            className="bg-gray-900 bg-opacity-80 h-full w-full fixed"
          ></div>
          <div className="h-screen flex justify-center items-center">
            <div className="p-1">
              <div className="max-w-md mx-auto bg-white sm:p-8 p-2 rounded shadow-xl relative">
                <div
                  className="absolute text-gray-400 right-2 top-2 cursor-pointer text-2xl"
                  onClick={() => setShowModel(false)}
                >
                  <IoMdCloseCircle />
                </div>
                <div className="p-1">
                  <h1 className="text-2xl font-bold pt-1 flex justify-center  text-black">
                    Upload
                  </h1>
                  <hr />
                  <input
                    type="file"
                    id="Case attachment"
                    name="Case attachment"
                    onChange={sendFiles}
                    style={{ display: "none" }}
                    multiple
                    accept="image/*, video/*"
                    capture="environment"
                  />
                  <div className="border flex justify-center items-center rounded-2xl">
                    {caseFiles?.length >= 1 ? (
                      <div className="flex justify-center items-center p-2">
                        {(() => {
                          let images = [];
                          let videos = [];
                          previews?.forEach((file) => {
                            if (file !== null) {
                              const fileNameParts = file.match(
                                /data:[a-zA-Z0-9]+\/([a-zA-Z0-9-.+]+).*,.*/
                              );
                              const fileExtension = fileNameParts[1];
                              if (imageExtensions.includes(fileExtension)) {
                                images.push(file);
                              } else if (
                                videoExtensions.includes(fileExtension)
                              ) {
                                videos.push(file);
                              }
                            }
                          });
                          return (
                            <>
                              {<div className="flex flex-col gap-1 sm:w-96 sm:h-80 w-72 h-60 max-h-[430px] overflow-y-auto">
                                  {images?.map((img, index) => (
                                    <div className="object-cover relative" key={index}>
                                      <img
                                        src={img}
                                        alt="post-img"
                                        className="sm:w-96 sm:h-80 w-72 h-60 rounded-2xl object-cover"
                                      />
                                      <div
                                        onClick={() => handleFileDelete(index)}
                                        className="absolute w-5 h-5 flex justify-center items-center rounded-full bg-gray-300 text-black right-1 top-1 cursor-pointer text-lg"
                                      >
                                        <RxCross2 />
                                      </div>
                                    </div>
                                  ))}
                                  {videos?.map((vid, index) => (
                                    <div className="object-cover relative" key={index}>
                                      <video
                                        className="sm:w-96 sm:h-80 w-72 h-60 rounded-2xl object-cover"
                                        src={vid}
                                        controls
                                      />
                                      <div
                                        onClick={() =>
                                          handleFileDelete(
                                            index + images.length
                                          )
                                        }
                                        className="absolute w-5 h-5 flex justify-center items-center rounded-full bg-gray-300 text-black right-1 top-1 cursor-pointer text-lg"
                                      >
                                        <RxCross2 />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              }
                            </>
                          );
                        })()}
                      </div>
                    ) : (
                      <div
                        className="flex justify-center items-center flex-col bg-gray-200 sm:w-80 w-60 h-48 m-2 rounded-2xl hover:bg-gray-300 cursor-pointer relative"
                        onClick={() =>
                          document.getElementById("Case attachment").click()
                        }
                        value={caseFiles[0]?.name}
                      >
                        <MdAddPhotoAlternate className="text-4xl" />
                        <span className="font-bold">Add Photo/Video</span>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-between mt-1 px-1">
                    <button
                      className="bg-[#0d0c22] text-white p-2 rounded-3xl w-1/4 flex justify-center text-xl items-center"
                      onClick={() =>
                        document.getElementById("Case attachment").click()
                      }
                      disabled={caseLoading}
                      value={caseFiles[0]?.name}
                    >
                      <MdDriveFolderUpload />
                    </button>
                    <button
                      className="bg-[#0d0c22] text-white p-2 px-3 rounded-3xl w-auto"
                      disabled={loading}
                      onClick={() => {
                        setShowModel(false);
                      }}
                    >
                      {caseLoading ? "Loading..." : "Post"}
                    </button>
                  </div>
                  {caseLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Lottie
                        loop={true}
                        animationData={Loader}
                        style={{ width: "200px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="main-container postCase text-white"
        style={{ minHeight: "100vh" }}
      >
        <div className="bg-gray-800 w-full bg-opacity-60 h-screen bg-fixed overflow-y-auto">
          <div className="content">
            <button
              className="btn2 btn2--tertiary  back-btn backButton"
              style={{ backgroundColor: "white" }}
              onClick={handleGoBack}
            >
              <img src={backArrow} alt="back-button" />
            </button>
            <div className="formgroup">
              <h4 className="text-center">Post Your Case</h4>
              <div className="field_Container">
                <>
                  <form onSubmit={PostRegistration} className="form_responsive">
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Full Name</h6>
                        <input
                          type="text"
                          id="Full name"
                          name="name"
                          placeholder="Full Name"
                          className="formField"
                          value={formValues?.contact_detail?.name}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              contact_detail: {
                                ...formValues.contact_detail,
                                name: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Email</h6>
                        <input
                          type="email"
                          id="Email"
                          name="Email"
                          placeholder="Email"
                          className="formField"
                          value={formValues?.contact_detail?.email}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              contact_detail: {
                                ...formValues.contact_detail,
                                email: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Phone Number</h6>
                        <input
                          type="number"
                          id="Phone Number"
                          name="phonenumber"
                          placeholder="Enter phone number"
                          className="formField"
                          value={formValues?.contact_detail?.phonenumber}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              contact_detail: {
                                ...formValues.contact_detail,
                                phonenumber: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Case Title</h6>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Enter Case title"
                          className="formField"
                          value={formValues?.title}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              title: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Description</h6>
                        <input
                          type="text"
                          id="description"
                          name="description"
                          placeholder="Enter description"
                          className="formField"
                          value={formValues?.description}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              description: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Pin Location</h6>
                        <input
                          type="button"
                          id="pin-address"
                          name="pin-address"
                          onClick={getLocation}
                          style={{ display: "none" }}
                          required
                        />
                        <input
                          type="text"
                          id="pin-address-text"
                          name="pin-address"
                          placeholder="Pin Location"
                          onClick={() =>
                            document.getElementById("pin-address").click()
                          }
                          className="formField custom-field"
                          value={formValues?.pin_address}
                          readOnly
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Manual Address</h6>
                        <input
                          type="text"
                          id="manual_complete_address"
                          name="manual_complete_address"
                          placeholder="Enter address"
                          className="formField"
                          value={formValues?.manual_complete_address}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              manual_complete_address: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Required Amount</h6>
                        <input
                          type="number"
                          id="required_amount"
                          name="required_amount"
                          placeholder="Enter required amount"
                          className="formField"
                          value={formValues?.required_amount}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              required_amount: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Category</h6>
                        <select
                          type="text"
                          id="category"
                          name="category"
                          placeholder="category"
                          className="formField custom-select"
                          value={formValues?.category}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              category: e.target.value,
                            })
                          }
                          required
                        >
                          <option>healthcare</option>
                          <option>education</option>
                          <option>nutrition_and_food_security</option>
                          <option>infrastructure</option>
                          <option>economic_empowerment</option>
                          <option>enviornmental_conversation</option>
                        </select>
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Case attachments</h6>
                        <div className="upload-field">
                          <div
                            className="bg-white p-[11px] text-gray-400 cursor-pointer"
                            style={{
                              borderRadius: "30px",
                              border: "2px solid #f4f4f4",
                              width: "90%",
                            }}
                            onClick={handlePopup}
                          >
                            {previews.length >= 1 ? (
                              <span className="text-black font-semibold">
                                {previews.length === 1 ? (
                                  "1 file uploaded"
                                ) : (
                                  <>{previews.length} files uploaded</>
                                )}
                              </span>
                            ) : (
                              "Upload image/video"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn_conatiner">
                      <button className="Registration_btn" disabled={loading} type="submit">
                        {loading || caseLoading ? "Loading..." : "Post"}
                      </button>
                    </div>
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCase; 
