import React, { useState, useEffect } from "react";
import Header from "../Common/Navbar";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchApi } from "../utlis/axios";
import { useSelector } from 'react-redux';
import backArrow from '../assets/back-button.png'
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import Loader from '../animation/Loader.json'

export const ViewFamilyMember = () => {
  const [familyData, setFamilyData] = useState([]); // Initialize as an object with an empty results array
  const { access } = useSelector(state => state.authSlice.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(null)
  

  const { id } = useParams();
  const navigate = useNavigate();

  const MemberData = async()=>{
    try{
      const result = await fetchApi.get(`member/?family__id=${id}`,{
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      })
      if(result?.status === 200)
      setFamilyData(result?.data); // Store the member data in stat
      console.log("family members:", result?.data)

    } catch(error){
      console.log({error})
    }
  }

 useEffect(() => {
    MemberData();
  }, [searchQuery, access]);

  const handleTableRowClick = (id) => {
    console.log("Clicked on row with data:",id);
    navigate(`/memberlistingdetail/${id}`);
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(familyData.count / itemsPerPage);

  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;
    
    try {
      const result = await fetchApi.get(
        `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      return result;
    } catch (error) {
      throw error;
    }
  };

  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;
  
    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );
  
      for (let i = 2; i < totalPages; i++) {
        if (
          i >= currentPage - 1 &&
          i <= currentPage + 2
        ) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
  
      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  
    return pageButtons;
  };

  const handlePageClick = (pageNumber) => {
    console.log("pageNumbber", pageNumber)
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);
  
      // Fetch data for the selected page
      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            
            setFamilyData(result?.data);
            // console.log("family data....", familyData)
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };


 const nextPageData = async () => {
  const nextPage = currentPage + 1;
  const newOffset = nextPage * itemsPerPage;

  setIsLoading(true);

  try {
    const result = await fetchApi.get(
      `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );

    if (result.status === 200) {
      // Concatenate the new data with the existing data
      setFamilyData(result?.data);

      setCurrentPage(nextPage);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

const prevPageData = async () => {
  const prevPage = currentPage - 1;
  const newOffset = (prevPage - 1) * itemsPerPage;

  setIsLoading(true);

  try {
    const result = await fetchApi.get(
      `member/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );

    if (result.status === 200) {
      setFamilyData(result?.data);
      setCurrentPage(prevPage);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

const Registrationfrom = (id) =>{
  console.log("Family id", id)
  navigate('/memberRegistration', { state: { familyId: id } });
}

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      <Header />
      <div className="Table_container md:px-10 px-5" style={{ minHeight: '100vh' }}>
      <button className='btn2 btn2--tertiary  back-btn backButton' 
      style={{backgroundColor:'white'}}
      onClick={handleGoBack} >
        <img src={backArrow} alt='back-button' />
      </button>
        <div className="Table_heading">
          <div className='sidebar-search d-flex justify-content-between'>
            <form className="formSearch">
              <input
                type='search'
                name='search'
                className='px-3 Search_input'
                placeholder='Search Here'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className="Tbtn">
            
              <button className="heading_btn" onClick={()=> Registrationfrom(id)}>Register Member</button>
           
          </div>
        </div>
        <div className="Table_Section">
          <h5 className=" p-3">Family Member Table</h5>
          {
      isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Lottie
        loop={true}
        animationData={Loader}
        style={{ width: '200px' }}
        className='auth-sidebar-video'
      />
    </div>
  ) : familyData?.results ? (familyData?.results?.length > 0 ? (
    <>
    <div className="table-responsive">
      <table className="table align-middle mb-0 bg-white table-hover">
        <thead className="table-light m-3">
          <tr className="m-3">
            <th style={{ padding: "10px" }}>Name</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>Education</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>Monthly Income</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>Medical Condition</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>Family </th>
          </tr>
        </thead>
        <tbody>
        {familyData?.results?.map((data, value) => {
                return (
                    <tr
                    key={value}
                    className="table-row" // Add this class for styling
                    onClick={() => handleTableRowClick(data?.id)}
                    >
                    <td style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="fw-bold mb-3 mt-3 ">{data?.first_name}</p>
                        </div>
                      </div>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <div
                        className='dot d-flex align-items-center justify-content-center w-100'
                      >
                        <span className='span-badge'></span>
                        {data?.education_or_qualification}
                      </div>
                    </td>
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      {data?.monthly_income}
                    </td>
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>{data?.medical_condition}</td>
                    <td style={{ verticalAlign: 'middle' }}>
                         <p className="fw-normal mb-1 text-center">{data?.family?.name}</p>
                    </td>
                  </tr>
                )
              })}
        </tbody>
      </table>
    </div>
     <div className="d-flex justify-content-between align-items-center mt-3">
     <div className="d-flex justify-content-between align-items-center mt-3">
     <Pagination>
     <Pagination.Prev
       onClick={prevPageData}
       disabled={currentPage === 1}
     />
    {renderPaginationItems()}
     <Pagination.Next
      onClick={nextPageData}
       disabled={currentPage === totalPages}
     />
   </Pagination>
     </div>
   </div>
   </>
  ) : (
    <h6 className="text-center my-5 py-2">Members are not Registered !</h6>
  )) : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Lottie
        loop={true}
        animationData={Loader}
        style={{ width: '200px' }}
        className='auth-sidebar-video'
      />
    </div>
  )
}
        </div>
      </div>
    </>
  )
}
