/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Common/Navbar';
import { fetchApi } from '../utlis/axios';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import Loader from '../animation/Loader.json'
import backArrow from '../assets/back-button.png'
import { toast } from 'react-toastify'

export const Propertylistingdetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(null)
    const [data, setData] = useState();
    const { access } = useSelector(state => state.authSlice.userInfo);
    const [isEditing, setIsEditing] = useState(false);
    const [step, setStep] = useState(1);
    const [type, setType] = useState('text');
    const [familyData, setFamilyData] = useState();
    const [editedData, setEditedData] = useState({});
    const [familyid, setFamilyId] = useState();
    const [documentId, setDocumentId] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async ()=>{
      setIsLoading(true)
      try{
        const result = await fetchApi.get(`property/${id}/`,{
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        })
        if(result.status === 200)
        {
          setData(result?.data)
          console.log("member detail :", result?.data)
          setIsLoading(false)
        }
      } catch(error){
        setIsLoading(false)
        console.log({error})
      }
    }

    useEffect(()=>{
      fetchData();      
    },[])

    const handleEditClick = (id) => {
      setIsEditing(true);
      setEditedData({ ...data });
      setFamilyId(id)
    };

    const handleAddProperty =(id)=>{
      navigate('/propertyRegistration', { state: { familyId: id } });
    }

    const getFamilydata = async () => {
      try {
        const result = await fetchApi.get(`family/${familyid}`, {
          headers: {
              'Authorization': `Bearer ${access}`, // Replace with your API key
          },
        });
        if (result.status === 200) {
          setFamilyData(result.data);
          console.log("Family data...", result.data);
        } else {
          console.error("Failed to fetch data:", result.status);
        }
      } catch (error) {
        console.log({ error });
      }
    };

  

    useEffect(()=>{
      getFamilydata();
     },[familyid])

    const handleUpdate = async () => {
      setIsLoading(true);
      try {
        const data = new FormData()
        data.append('id',id )
        data.append('name', editedData?.name)
        data.append('type', editedData?.type)
        data.append('worth', editedData?.worth)
        data.append('owned_since', editedData?.owned_since)
        data.append('description', editedData?.description)
        data.append('pin_location', editedData?.pin_location)
        data.append('family', familyid)
        data.append('is_active', true)
       
        const result = await fetchApi.patch(`property/${id}/`,data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${access}`,
          },
        });
  
        if (result.status === 200) {
          setData({ ...data, ...editedData });
          setIsEditing(false);
          setEditedData({});
          console.log("updated data", result?.data)
        }
        fetchData();

      } catch (error) {
        console.error(error);
        toast.error(error?.response?.data?.detail)
      } finally {
        setIsLoading(false);
      }
    }


    
    const handleGoBack = () => {
      navigate(-1); 
    };

  const renderEditFields = () => {
    return (
      <>
      <div class="main-container" style={{ minHeight: '100vh',backgroundColor:'white' }}>
    <div className='content'>
    <button className='btn2 btn2--tertiary  back-btn backButton' 
      style={{backgroundColor:'white'}}
      onClick={handleGoBack} >
        <img src={backArrow} alt='back-button' />
      </button>
      <div className='formgroup'>
      <h4 className='text-center'>Property Registration Form</h4>
      {loading && 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Lottie
          loop={true}
          animationData={Loader}
          style={{ width: '200px' }}
          className='auth-sidebar-video'
        />
      </div>
      }
      {
       !loading && (
        <form onSubmit={handleUpdate}>
      <div className='field_Container'>
        {step === 1 && (
          <>
        <div className='group-fields'>
        <div className='field-container'>
        <h6 className='field-txt'>Property name</h6>
        <input 
        type='text' 
        id='name' 
        name='name' 
        placeholder='Property name' 
        className='formField' 
        value={editedData?.name}
        onChange={(e)=> setEditedData({...editedData, name:e.target.value})}
        required/>
        </div>
        <div className='field-container'>
        <h6 className='field-txt'>Property Type</h6>
        <select
            type="text"
            id="type "
            name="type "
            className="formField custom-select"
            value={editedData?.type}
            onChange={(e)=> setEditedData({...editedData, type:e.target.value})}
            required
            style={{paddingRight:'70px'}}
          >
         <option>Select</option>
         <option value="Farming Land ">Farming Land </option>
         <option value="5 to 10 lacs">5 to 10 lacs</option>
         <option value="Residencial Property(House,Flat, or Plot)">
          Residencial Property(House,Flat, or Plot)</option>
         <option value="Comercial Property(Shop,Comerical Land or plot)">
          Comercial Property(Shop,Comerical Land or plot)</option>
         <option value="House Holds(Animals, Vehicles)">
          House Holds(Animals, Vehicles)</option>
          <option value="Others">
          Others</option>
       </select>
      </div>
      </div>

      <div className='group-fields'>
        <div className='field-container'>
        <h6 className='field-txt'>Property worth</h6>
        <select
            type="text"
            id="worth "
            name="worth "
            className="formField custom-select"
            value={editedData?.worth}
            onChange={(e)=> setEditedData({...editedData, worth:e.target.value})}
            required
          >
         <option>Select</option>
         <option value="5 lacs or less">5 lacs or less</option>
         <option value="5 to 10 lacs">5 to 10 lacs</option>
         <option value="10 to 20 lacs">10 to 20 lacs</option>
         <option value="20 to 50 lacs">20 to 50 lacs</option>
         <option value="50 lacs or more">50 lacs or more</option>
       </select>
        </div>
        <div className='field-container'>
        <h6 className='field-txt'>Property ownd since</h6>
        <input 
        type={type} 
        id='ownd' 
        name='ownd' 
        placeholder='Property ownd since' 
        onFocus={(event) => {
          event.target.type = 'date'
        }}
        className='formField' 
        onClick={(e) => setType('date')}
        value={editedData?.owned_since}
        onChange={(e)=> setEditedData({...editedData, owned_since:e.target.value})}
        required/>
        </div>
      </div> 

      <div className='group-fields'>
      <div className='field-container'>
        <h6 className='field-txt'>Description</h6>
        <input 
        type='text' 
        id='Description' 
        name='Description' 
        placeholder='Description' 
        className='formField' 
        value={editedData?.description}
        onChange={(e)=> setEditedData({...editedData, description:e.target.value})}
        required
        />
        </div>
       <div className='field-container'>
        <h6 className='field-txt'>Location</h6>
        <input 
        type='text' 
        id='location' 
        name='location' 
        placeholder='Property location'
        value={editedData?.pin_location} 
        onChange={(e)=> setEditedData({...editedData, pin_location:e.target.value})}
        className='formField' 
        required/>
      </div>  
      </div>

      <div className='group-fields'>
       
        <div className='field-container'>
          <h6 className='field-txt'>family</h6>
        <input 
        type='text' 
        id='family' 
        name='family' 
        placeholder='family' 
        className='formField' 
        value={familyData?.name}
        // onChange={(e)=> setEditedData({...editedData, description:e.target.value})}
        required
        disabled
        />
        </div>
      </div>
      <div className='next_btn'>
        <button className="Registration_btn">
        Update
        </button>
        </div>
          </>
        )}      
      </div>
      </form>
       ) 
      }
    </div>
    
    </div>
  </div>
        </>
      );
    };


  return (
    <>
    <Header/>
    <div style={{backgroundColor:'#F8F7F4', minHeight:'100vh'}} className='Table_container md:px-10 px-5'>
    <button className='btn2 btn2--tertiary  back-btn backButton' 
      style={{backgroundColor:'white'}}
      onClick={handleGoBack} >
        <img src={backArrow} alt='back-button' />
      </button>
    <div className='Table_Section '>
      {
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Lottie
              loop={true}
              animationData={Loader}
              style={{ width: '200px' }}
              className='auth-sidebar-video'
            />
          </div>
        ) : (
          <>
          {
            isEditing ?(
              renderEditFields()
            ):(
              <>
      <div className='container-small job-details-page-container'>
      <div className='main-content-container'>
      <div className='font-label'>
        Property Detail Page
      </div>
      <h1 className='margin-t-16 job-details-title'>
        {data?.name}
      </h1>
      <div className='margin-t-16 job-details-description'>
        <div className='detail_MainConatiner'>
        <div className='detailDiv'>
        <div>
          <b>Property Type</b>
          <p>{data?.type}</p>
        </div>
        <div >
          <b style={{marginRight:'30px'}}>Property Worth</b>
          <p>{data?.worth}</p>
        </div>
       </div>
       <div className='detailDiv mt-3'>
        <div>
          <b>Property Since</b>
          <p>{data?.owned_since}</p>
        </div>
        <div >
          <b style={{marginRight:'15px'}}>Property Description</b>
          <p>{data?.description}</p>
        </div>
       </div>
       <div className='detailDiv mt-3'>
        <div>
          <b>Property Location</b>
          <p>{data?.pin_location}</p>
        </div>
       </div>
        </div>
      </div>
      </div>
      <div className='sidebar-container'>
      <div style={{
          display:'flex', 
          flexDirection:'row',
          justifyContent:'end',
          gap:'10px'
          }}>
      <div className='sidebar_edit'>
           <button className='edit_btn' onClick={()=> handleEditClick(data?.family?.id)}>Edit</button>
      </div>
        {/* <div className='sidebar_edit'>
           <button className='edit_btn' onClick={()=> handleAddProperty(data?.family?.id)}>Add Property</button>
        </div> */}
        </div>
      <div className='sidebar-content-container'>
      <div className='margin-t-24'>
        <div className='font-sublabel'>
         <b>Family</b> 
        </div>
        <div className='font-label margin-t-8'>
          {data?.family?.name}
        </div>
      </div>
      <div className='margin-t-24 family-status' >
        <div className='font-sublabel mt-3' >
         <b> Family Status</b>
        </div>
        <div className='font-label margin-t-8'>
        <a 
        className='btn' 
        style={{
          backgroundColor:'#82EADD', 
          padding:'3px 15px',
          borderRadius:'30px'
        }}
        >
        {data?.family?.status}<span></span>
        </a>
        </div>
      </div>
      </div>
      </div>
        </div>
              </>
            )
          }
          </>
        )
      }
    </div>
    </div>
    </>
  )
}
