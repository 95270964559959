/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export const FeaturesCell = () => {
  return (
    <div className='call-outs Chome-container'>
    <div className='call-out'>
    <p className='Chome-badge Chome-badge--teal'>
    Looking For Donation?
    </p>
    <h3 className='home-type-subheading margin-t-32'>
    Feeding for the Poor People
    </h3>
    <p className='call-out__copy'>
        KWF RATION provides monthly subsistence for groceries 
    </p>
    <div className='call-out__actions'>
    <a className='btn2 btn2--large' 
    style={{backgroundColor:'#0D0C22', color:'white'}}
    href='/donation'
    >
        Donate Now
    </a>
    </div>
    </div>
    <div className='call-out'>
    <p className='Chome-badge Chome-badge--teal' style={{backgroundColor:'#ffabe7'}}>
    Here for inspiration?
    </p>
    <h3 className='home-type-subheading margin-t-32'>
    Take care of Orphan Children's
    </h3>
    <p className='call-out__copy'>
    KWF Provide help to childrens in distress who are orphaned .
    </p>
    <div className='call-out__actions'>
    <a className='btn2 btn2--large' 
    style={{backgroundColor:'#0D0C22', color:'white'}}
    href='/donation'
    >
        Donate Now
    </a>
    </div>
    </div>
    </div>
 )
}
