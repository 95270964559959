/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "../assets/kwf-logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toggleAuth } from "../Redux/Slices/auth-slice";
import commingSoon from "../assets/coming_soon.png";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isLogin, userInfo } = useSelector((state) => state.authSlice || {});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogout = () => {
    dispatch(toggleAuth({ isLogin: false, userInfo: null }));
    navigate("/");
    localStorage.removeItem("activeTab");
  };

  return (
    <div className={`topnav ${isNavOpen ? "open" : ""}`}>
      <div className="header-container">
        <div className="topnav-centered mt-2">
          <Link to="/">
            <img src={Logo} alt="logo" width="140px" />
          </Link>
        </div>
        <div className="hamburger-icon" onClick={toggleNav}>
          <div className="bar "></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      <div className={`nav-links ${isNavOpen ? "show" : ""}`}>
        <Link to="/">Home</Link>
        {/* <div className="commingsoon" style={{position:"relative"}}>
          <a
            class="nav-link disabled"
            href="#donation"
            tabIndex="-1"
            aria-disabled="true"
          >
            Donations
          </a>
          <span className="commingsoon_icon">
            <img src={commingSoon} alt="img" width="60px" />
          </span>
        </div> */}
        <a href="/programs">Programs</a>
        <a href="/aboutus">About us</a>
        <a href="/casesfeeds">Projects</a>
        <div className="topnav-right">
          {isLogin ? (
            userInfo?.user?.role === "admin" ? (
              <>
                <a href="/dashboard">Dashboard</a>
                <a href="/familylisting">Family Listing</a>
                <a href="/memberlisting"> Member Listing</a>
                <a href="/propertydetailpage"> Property Listing</a>
                <a onClick={handleLogout} style={{ cursor: "pointer" }}>
                  Logout
                </a>
              </>
            ) : (
              userInfo?.user?.role === "medicalstaff" ? 
              <>
                <a href="/dashboard/verify_fingerprint">Dashboard</a>
                <a onClick={handleLogout} style={{ cursor: "pointer" }}>
                  Logout
                </a>
              </>
              :
              <>
                <a href="/familylisting">Family Listing</a>
                <a href="/memberlisting"> Member Listing</a>
                <a href="/propertydetailpage"> Property Listing</a>
                <a onClick={handleLogout} style={{ cursor: "pointer" }}>
                  Logout
                </a>
              </>
            )
          ) : (
            <>
              <a href="/login">Login</a>
              <div className="btn-navbar">
                <a
                  href="/donation"
                  className="btn text-white px-3 py-2 mx-3 mt-2"
                  style={{ backgroundColor: "#DE1285", borderRadius: 30 }}
                >
                  DONATE
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
