import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { fetchApi } from '../../utlis/axios'

const FamilyStatusChart = () => {
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [statusData, setStatusData] = useState({
    accepted: 0,
    rejected: 0,
    inReview: 0,
    hold: 0,
  });

  const getFamilyStatus = async () => {
    try {
      const result = await fetchApi.get("family-graph/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        const apiData = result.data;

        // Calculate the count for each status
        const statusCounts = apiData.reduce((counts, item) => {
          counts[item.status] = (counts[item.status] || 0) + 1;
          return counts;
        
        }, {});

        setStatusData({
          accepted: statusCounts.accepted || 0,
          rejected: statusCounts.rejected || 0,
          inReview: statusCounts["in-review"] || 0,
          hold: statusCounts.hold || 0,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getFamilyStatus();
  }, []);

  const data = {
    labels: ["Accepted", "Rejected", "in-Review", "Hold"],
    datasets: [
      {
        data: [statusData.accepted, statusData.rejected, statusData.inReview, statusData.hold],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
  };

  return (
    <div className="pie-chart dashboard-responsive">
      <Pie data={data} options={options} height={230} />
    </div>
  );
};

export default FamilyStatusChart;