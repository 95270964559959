import React, { useState } from "react";
import TakeFingerprint from "./TakeFingerprint";

const FingerPrintPage = ({ handlefingerPrintPage }) => {
  const [showModel, setShowModel] = useState(false);
  const handlePopup = () => {
    setShowModel(true);
  };

  const fingerprint = [1];

  return (
    <>
      {showModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div
            className="bg-gray-900 bg-opacity-80 h-full w-full fixed"
            onClick={() => {
              setShowModel(false);
            }}
          ></div>
          <TakeFingerprint setShowModel={setShowModel} />
        </div>
      )}
      <form>
        <div className="group-fields">
          <div className="field-container">
            <h6 className="field-txt">Person Name</h6>
            <input
              type="text"
              id="Person Name"
              name="Person Name"
              placeholder="Enter Person Name"
              className="formField"
              required
            />
          </div>
          <div className="field-container">
            <h6 className="field-txt">Choose Relationship</h6>
            <select
              type="text"
              id="Choose Relationship"
              name="Choose Relationship"
              placeholder="Choose Relationship"
              className="formField custom-select"
              // value={editedData.education_status}
              // onChange={(e) =>
              //   setEditedData({
              //     ...editedData,
              //     education_status: e.target.value,
              //   })
              // }
              required
            >
              <option>MySelf</option>
              <option>Mother</option>
              <option>Father</option>
              <option>Wife</option>
              <option>Husband</option>
              <option>Son</option>
              <option>Daughter</option>
              <option>Sister</option>
              <option>Brother</option>
            </select>
          </div>
        </div>
        <div className="group-fields">
          <div className="field-container">
            <h6 className="field-txt">Add Fingerprint</h6>
            <button className="Register_btn mb-2" onClick={handlePopup}>
              Add
            </button>

            <>
              <section>
                <h1 className="md:text-3xl text-lg sm:text-xl font-bold text-center pt-1 capture_fingerprint">
                  Fingerprint Data
                </h1>
                <div className="flex flex-wrap justify-evenly p-4">
                  {fingerprint.length === 0 ? (
                    <h4 className="py-20">No Fringerprint data Found</h4>
                  ) : (
                    <div className="table-responsive">
                      <table className="table align-middle mb-0 bg-white table-hover">
                        <thead className="table-light m-3">
                          <tr className="m-3">
                            <th style={{ padding: "5px" }}>Person</th>
                            <th style={{ padding: "5px" }}>Relationship</th>
                            <th style={{ padding: "5px" }}>Fringerprint</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fingerprint.map((data, index) => (
                            <tr
                              key={index}
                              className="table-row" // Add this class for styling
                              // onClick={() => handleTableRowClick(data?.id)}
                            >
                              <td style={{ verticalAlign: "middle" }}>
                                <div className="d-flex ">
                                  <div className="">
                                    <p className="fw-bold mb-3 mt-3 ">
                                      {data?.name}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <div className="dot d-flex align-items-start justify-content-start w-100">
                                  {data?.application_priority}
                                </div>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <button className="bg-gray-900 text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded w-full md:w-auto z-10">
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </section>
            </>
            <button
              className="Register_btn mb-2"
              onClick={handlefingerPrintPage}
            >
              Back
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FingerPrintPage;
