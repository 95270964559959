import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { fetchApi } from '../../utlis/axios'; 

const DonationDetail = () => {
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [donation, setDonation] = useState([]);

  const getStatus = async () => {
    try {
      const result = await fetchApi.get('/donation-graph', {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      if (result.status === 200) {
        const donationDataResponse = result.data;
        const monthlyDonation = {};
        const currentYear = new Date().getFullYear();

        donationDataResponse.forEach((donation) => {
          const createdDate = new Date(donation.created_at);
          const donationYear = createdDate.getFullYear();

          if (donationYear === currentYear && donation.type) {
            const month = createdDate.toLocaleString('en-US', { month: 'long' });

            if (monthlyDonation[month]) {
              if (donation.type === 'food') {
                monthlyDonation[month].food++;
              } else if (donation.type === 'education') {
                monthlyDonation[month].education++;
              } else if (donation.type === 'health') {
                monthlyDonation[month].health++;
              } else if (donation.type === 'bussiness') {
                monthlyDonation[month].business++;
              } else if (donation.type === 'others') {
                monthlyDonation[month].other++;
              }
            } else {
              monthlyDonation[month] = {
                food: 0,
                education: 0,
                health: 0,
                business: 0,
                other: 0,
              };
              if (donation.type === 'Food') {
                monthlyDonation[month].food++;
              } else if (donation.type === 'Education') {
                monthlyDonation[month].education++;
              } else if (donation.type === 'Health') {
                monthlyDonation[month].health++;
              } else if (donation.type === 'Business') {
                monthlyDonation[month].business++;
              } else if (donation.type === 'Other') {
                monthlyDonation[month].other++;
              }
            }
          }
        });

        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = new Date(null, i).toLocaleString('en-US', {
            month: 'long',
          });
          return {
            month: monthName,
            ...monthlyDonation[monthName],
          };
        });

        setDonation(allMonths);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  const data = {
    labels: donation.map((entry) => entry.month),
    datasets: [
      {
        label: 'Food',
        data: donation.map((entry) => entry.food),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        type: 'line',
      },
      {
        label: 'Education',
        type: 'line',
        data: donation.map((entry) => entry.education),
        borderColor: 'orange',
        fill: false,
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Business',
        type: 'line',
        data: donation.map((entry) => entry.business),
        borderColor: 'rgba(75, 192, 75, 1)',
        fill: false,
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Health',
        type: 'line',
        data: donation.map((entry) => entry.health),
        borderColor: 'rgba(255, 99, 132, 1)', // Adjust color as needed
        fill: false,
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Other',
        type: 'line',
        data: donation.map((entry) => entry.other),
        borderColor: 'rgba(0, 0, 255, 1)', // Adjust color as needed
        fill: false,
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const options = {
    scales: {
      type: 'category',
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className='dashboard-responsive'>
      <Bar data={data} options={options} height={230} />
    </div>
  );
};

export default DonationDetail;
