/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../../Common/Navbar";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import Loader from '../../animation/Loader.json'

export const DonationFilters = () => {
  const [donationData, setDonationData] = useState([]); // Initialize as an object with an empty results array
  const { access } = useSelector(state => state.authSlice.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedToggle, setSelectedToggle] = useState("education");
  const [isLoading, setIsLoading] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState({
    'education': false,
    'food': false,
    'health': false,
    'bussiness': false,
    'others':false
  });
  const [selectedPriority, setSelectedPriority] = useState({
    'high': false,
    'low': false,
    'normal': false,
  });

  const navigate = useNavigate();

  const getFamilyData = async () => {
    setIsLoading(true)
    try {
      const result = await fetchApi.get(`donation/?search=${searchQuery}`, {
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setDonationData(result?.data);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log({ error });
    }
  }

 useEffect(() => {
    getFamilyData();
  }, [searchQuery, access, selectedToggle]);

  const handleTableRowClick = (id) => {
    // navigate(`/familylistingdetail/${id}`);
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(donationData.count / itemsPerPage);

  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;
    try {
      const selectedStatusList = Object.keys(selectedStatus).filter(
        (status) => selectedStatus[status]
      );

      const selectedPriorityList = Object.keys(selectedPriority).filter(
        (priority) => selectedPriority[priority]
      );
  
      const result = await fetchApi.get(
        `donation/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedStatusList.join(',')}&application_priority=${selectedPriorityList.join(',')}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      return result;
    } catch (error) {
      throw error;
    }
  };
  
  
  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;
  
    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );
  
      for (let i = 2; i < totalPages; i++) {
        if (
          i >= currentPage - 1 &&
          i <= currentPage + 2
        ) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
  
      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  
    return pageButtons;
  };
  
  const handlePageClick = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);

      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            setDonationData(result?.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  
  const nextPageData = async () => {
    const nextPage = currentPage + 1;
    const newOffset = (nextPage - 1) * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `donation/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        setDonationData(result?.data);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const prevPageData = async () => {
    const prevPage = currentPage - 1;
    const newOffset = (prevPage - 1) * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `donation/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        setDonationData(result?.data);
        setCurrentPage(prevPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleToggleClick = (toggleName) => {
    setSelectedToggle(toggleName);
  };

  const handleCheckboxChange = (status) => {
    const updatedStatus = { ...selectedStatus, [status]: !selectedStatus[status] };
    setSelectedStatus(updatedStatus);
  }
  useEffect(()=>{
    if (Object.values(selectedStatus).some((value) => value)) {
      setIsLoading(true);
      fetchDataForPage(currentPage)
        .then((result) => {
          if (result.status === 200) {
            setDonationData(result?.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  },[selectedStatus, currentPage])
    
   
  const handlePriorityChange = (priority) => {
    const updatedPriority = { ...selectedPriority, [priority]: !selectedPriority[priority] };
    setSelectedPriority(updatedPriority);
  };
  useEffect(() => {
    if (Object.values(selectedPriority).some((value) => value)) {
      setIsLoading(true);
  
      fetchDataForPage(currentPage)
        .then((result) => {
          if (result.status === 200) {
            setDonationData(result?.data);
            console.log("fetchDataForPage", result?.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedPriority, currentPage]);
  
  
  


  return (
    <>
      <Header />
      <div className="container-fluid">
      <div className="row">
      <div className="filter_Container">
      <div className="col-12 col-md-3" style={{paddingTop:'160px', paddingBottom:'20px'}}>
      <div  className="filter_sidebar" >
      <h6>Filters</h6>
      <div className="line_tag"></div>
      <heading className='heading'>Donation Type</heading>
    <div className="filter_box">
    {Object.keys(selectedStatus).map(status => (
    <label key={status} className="filter_label">
    <input
        type="checkbox"
        checked={selectedStatus[status]}
        onChange={() => handleCheckboxChange(status)}
        style={{ marginRight: '5px', width: '20px', height: '20px' }}
    />
    {status}
    </label>
    ))}
    </div>
    {/* <heading className='heading'>Application Priority</heading>
    <div className="filter_box">
    {Object.keys(selectedPriority).map(priority => (
    <label key={priority} className="filter_label">
    <input
        type="checkbox"
        checked={selectedPriority[priority]}
        onChange={() => handlePriorityChange(priority)}
        style={{ marginRight: '5px', width: '20px', height: '20px' }}
    />
    {priority}
    </label>
    ))}
    </div> */}
     </div>
     </div>
     <div className="col-12 col-md-9">
     <div className="Table_container" style={{ minHeight: '100vh' }}>
     <div className="Table_heading">
      <div className='sidebar-search d-flex justify-content-between'>
      <form className="formSearch">
        <input
          type='search'
          name='search'
          className='px-3 Search_input'
          placeholder='Search Here'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </form>
      </div>
      {/* <div className="Tbtn">
      <Link to="/familyRegistration">
        <button className="heading_btn">Register Family</button>
      </Link>
      </div> */}
     </div>
     <div className="Table_Section">
      <h5 className=" p-3">Donation Listing Table</h5>
      {
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Lottie
            loop={true}
            animationData={Loader}
            style={{ width: '200px' }}
            className='auth-sidebar-video'
          />
        </div>
        ) : (
          <>
            <div className="table-responsive">
      <table className="table align-middle mb-0 bg-white table-hover">
        <thead className="table-light m-3">
          <tr className="m-3 ">
            <th style={{ padding: "10px", }}>Family</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>Program</th>
            <th style={{ padding: "10px",}}>Type</th>
            <th style={{ padding: "10px", textAlign: 'center' }}>Amount</th>
            {/* <th style={{ padding: "10px", textAlign: 'center' }}>Status Statement</th> */}
          </tr>
        </thead>
        <tbody>

          {donationData?.results?.map((data, value) => {

            return (
              <tr
                key={value}
                className="table-row" // Add this class for styling
                onClick={() => handleTableRowClick(data?.id)}
                >
                <td>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="fw-bold mb-3 mt-3 ">{data?.family?.name}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    className='dot d-flex align-items-center justify-content-center w-100'
                  >
                    <span className='span-badge'></span>
                    {data?.program?.name}
                  </div>
                </td>
                <td>
                  <span className="table_status">{data?.type}</span>
                </td>
                <td className="text-center">{data?.amount}</td>
                {/* <td>
                  <p className="fw-normal mb-1 text-center">{data?.status_statement}</p>
                </td> */}
              </tr>
            )
          })}
        </tbody>
      </table>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Pagination>
        <Pagination.Prev
          onClick={prevPageData}
          disabled={currentPage === 1}
        />
        {renderPaginationItems()}
        <Pagination.Next
          onClick={nextPageData}
          disabled={currentPage === totalPages}
        />
      </Pagination>
        </div>
      </div>
          </>
        )
      }
     </div>
      </div>
      </div>
      
      </div>
      </div>
      </div>
    </>
  )
}
