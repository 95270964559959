/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import img1 from '../assets/partner/partner-2.png'
import img2 from '../assets/partner/partner-3.png'
import img3 from '../assets/partner/partner-5.png'
import img4 from '../assets/partner/partner-6.png'
import img5 from '../assets/partner/partner-7.png'
import img6 from '../assets/partner/partner-9.png'
import bugdev from '../assets/bugdev.png'
export const IntroSection = () => {
  return (
    <div className='intro home-container'>
        <div className='intro__logos'>
          <div className='intro__trusted-by '>
          Sponserd by 
            </div>
            <a href='https://www.bugdev.co.uk/'>
            <img src={bugdev} alt="img" width='110px' />
            </a>
            
            {/* <img src={img2} alt="img" width='80px' />
            <img src={img3} alt="img" width='80px' /> */}
            {/* <img src={img4} alt="img" width='80px' />
            <img src={img5} alt="img" width='80px' />
            <img src={img1} alt="img" width='80px' />
            <img src={img6} alt="img" width='80px' /> */}
        
        </div>
        <div className='intro__content'>
        <div className='intro__heading'>
        KWF only the best people trusted foundation
        </div>
        <div className='intro__main'>
            <div>
            Khadim Welfare Foundation, a non-profit welfare organization in Pakistan, 
            imbued with a noble mission, having sincere love and affection 
            for the humanity and a strong commitment to serve the COMMON 
            PEOPLE without any discrimination.
            </div>
            <a className='btn2 btn2--large'
            data-track-cta="Get started"
            style={{backgroundColor:'#0D0C22', color:'white'}}
            href='/donation'
            >
            Donate Now
            </a>
        </div>
        </div>  
    </div>
  )
}
