import React from "react";
import { Link } from "react-router-dom";
import RegisteredFamilyChart from "./RegisteredFamilyChart";
import FamilyStatusChart from "./FamilyStatusChart";
import FamilyExpenseChart from "./FamilyExpenseChart";
import FamilyMap from "./FamilyMap";

export const FamilyDashboard = () => {
  return (
    <>
      <section className="Admindashboard__task__wrapper ">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <p className="title">Family Dashboard</p>
            </div>
          </div>
          <div className="mb-5">
            <div className="mt-2">
              <div className="dashboardCard">
                <Link to="/familydetailpage">
                  <div className="">
                    <p style={{ color: "black", fontSize: "20px" }}>
                      Registered Families
                    </p>
                  </div>
                  <RegisteredFamilyChart />
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <div className="dashboardCard cardTop">
                <Link to="/familydetailpage">
                  <div className="">
                    <p style={{ color: "black", fontSize: "20px" }}>
                      Families Status
                    </p>
                    <FamilyStatusChart />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="dashboardCard">
                <Link to="/donationdetailpage">
                  <div className="">
                    <p style={{ color: "black", fontSize: "20px" }}>
                      Family Revenue
                    </p>
                  </div>
                  <FamilyExpenseChart />
                </Link>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="dashboardCard">
                <div className="">
                  <p style={{ color: "black", fontSize: "20px" }}>
                    Registered Family Map
                  </p>
                  <FamilyMap  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
