import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../../animation/Loader.json";

export const Application = () => {
  const [familyData, setFamilyData] = useState([]); // Initialize as an object with an empty results array
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedToggle, setSelectedToggle] = useState("in-review");
  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();

  const getFamilyData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get(
        `family/?search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      if (result.status === 200) {
        setFamilyData(result?.data);
        console.log("result data", result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    getFamilyData();
  }, [searchQuery, access, selectedToggle]);

  const handleTableRowClick = (id) => {
    navigate(`/applicationdetail/${id}`);
  };

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(familyData.count / itemsPerPage);

  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;

    try {
      const result = await fetchApi.get(
        `family/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      return result;
    } catch (error) {
      throw error;
    }
  };

  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );

      for (let i = 2; i < totalPages; i++) {
        if (i >= currentPage - 1 && i <= currentPage + 2) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }

      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (pageNumber) => {
    console.log("pageNumbber", pageNumber);
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);

      // Fetch data for the selected page
      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            setFamilyData(result?.data);
            // console.log("family data....", familyData)
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const nextPageData = async () => {
    const nextPage = currentPage + 1;
    const newOffset = (nextPage - 1) * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `family/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        setFamilyData(result?.data);
        console.log("next data", result.data.results);
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const prevPageData = async () => {
    const prevPage = currentPage - 1;
    const newOffset = (prevPage - 1) * itemsPerPage;

    setIsLoading(true);

    try {
      const result = await fetchApi.get(
        `family/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}&status=${selectedToggle}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result.status === 200) {
        setFamilyData(result?.data);
        setCurrentPage(prevPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleClick = (toggleName) => {
    setSelectedToggle(toggleName);
  };
  return (
    <>
      <div className="Table_container "
        style={{ minHeight: "100vh",}}>
        <div className="flex justify-center">
          <button
            className={`toggle-button accepted_btn ${
              selectedToggle === "in-review" ? "active" : ""
            }`}
            onClick={() => handleToggleClick("in-review")}
            style={{
              backgroundColor:
                selectedToggle === "in-review" ? "#de1285" : "white",
              color: selectedToggle === "in-review" ? "white" : "black",
            }}
          >
            Review
          </button>
          <button
            className={`toggle-button hold_btn ${
              selectedToggle === "accepted" ? "active" : ""
            }`}
            onClick={() => handleToggleClick("accepted")}
            style={{
              backgroundColor:
                selectedToggle === "accepted" ? "#de1285" : "white",
              color: selectedToggle === "accepted" ? "white" : "black",
            }}
          >
            Accepted
          </button>
          <button
            className={`toggle-button hold_btn ${
              selectedToggle === "hold" ? "active" : ""
            }`}
            onClick={() => handleToggleClick("hold")}
            style={{
              backgroundColor: selectedToggle === "hold" ? "#de1285" : "white",
              color: selectedToggle === "hold" ? "white" : "black",
            }}
          >
            Hold
          </button>
          <button
            className={`toggle-button rejected_btn ${
              selectedToggle === "rejected" ? "active" : ""
            }`}
            onClick={() => handleToggleClick("rejected")}
            style={{
              backgroundColor:
                selectedToggle === "rejected" ? "#de1285" : "white",
              color: selectedToggle === "rejected" ? "white" : "black",
            }}
          >
            Rejected
          </button>
        </div>
        <div className="Table_heading">
          <div className="sidebar-search d-flex justify-content-between">
            <form className="formSearch">
              <input
                type="search"
                name="search"
                className="px-3 Search_input"
                placeholder="Search Here"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
        </div>
        <div className="Table_Section">
          <h5 className="p-3">Applications Table</h5>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Lottie
                loop={true}
                animationData={Loader}
                style={{ width: "200px" }}
                className="auth-sidebar-video"
              />
            </div>
          ) : (
            <>
              <div className="table-responsive">
                <table className="table align-middle mb-0 bg-white table-hover">
                  <thead className="table-light m-3">
                    <tr className="m-3 ">
                      <th style={{ padding: "10px" }}>Name</th>
                      <th style={{ padding: "10px" }}>Application Priority</th>
                      <th style={{ padding: "10px" }}>Status</th>
                      <th style={{ padding: "10px" }}>Registered Date</th>
                      <th style={{ padding: "10px" }}>Status Statement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {familyData?.results?.map((data, value) => {
                      return (
                        <tr
                          key={value}
                          className="table-row" // Add this class for styling
                          onClick={() => handleTableRowClick(data?.id)}
                        >
                          <td style={{ verticalAlign: "middle" }}>
                            <div className="d-flex ">
                              <div className="">
                                <p className="fw-bold mb-3 mt-3 ">
                                  {data?.name}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <div className="dot d-flex align-items-start justify-content-start w-100">
                              {/* <span className='span-badge'></span> */}
                              {data?.application_priority}
                            </div>
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <span className="table_status">{data?.status}</span>
                          </td>
                          <td className="" style={{ verticalAlign: "middle" }}>
                            {moment
                              .utc(data?.updated_at?.toString())
                              .format("MM/DD/YYYY")}
                          </td>
                          <td style={{ verticalAlign: "middle" }}>
                            <p className="fw-normal mb-1 ">
                              {data?.status_statement}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <Pagination>
                    <Pagination.Prev
                      onClick={prevPageData}
                      disabled={currentPage === 1}
                    />
                    {renderPaginationItems()}
                    <Pagination.Next
                      onClick={nextPageData}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </div>
            </>
          )}
        </div>
        
      </div>
    </>
  );
};
