import React, { useState } from "react";
import { AuthWrapper } from "../AuthWrapper";
import backArrow from "../assets/back-button.png";
import { toast } from "react-toastify";
import { fetchApi } from "../utlis/axios";
// import Lottie from "lottie-react";
// import Loader from "../animation/Loader.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";

const RegisterMedicalStore = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { access } = useSelector((state) => state.authSlice.userInfo);

  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    userName: "",
    email: "",
    medicalStoreName: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
  });

  const PostRegistration = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const data = new FormData();
      data.append("first_name", formValues?.first_name);
      data.append("last_name", formValues?.last_name);
      data.append("username", formValues?.userName);
      data.append("email", formValues?.email);

      const result = await fetchApi.post("pharmacist/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 201) {
        try {
          const formData = new FormData();
          formData.append("user", result?.data?.id);
          formData.append("name", formValues?.medicalStoreName);
          formData.append("phone_number", formValues?.phoneNumber);
          formData.append("address", formValues?.address);
          formData.append("city", formValues?.city);
          formData.append("state", formValues?.state);
          formData.append("is_active", true);

          await fetchApi.post("medical-store/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access}`,
            },
          });

          if (result?.status === 201) {
            toast.success("Successfully Register");
            navigate("/dashboard/kwf_medical_staff");
          }
          setFormValues({
            ...formValues,
            first_name: "",
            last_name: "",
            userName: "",
            email: "",
            medicalStoreName: "",
            phoneNumber: "",
            address: "",
            city: "",
            state: "",
          });
        } catch (error) {
          console.log({ error });
        }
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.username?.[0]);
      toast.error(error?.response?.data?.email?.[0]);
    } finally {
      setLoading(false); // Set loading back to false when API call is completed
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <AuthWrapper>
      <div
        class="main-container medicalStoreRegistration  text-white"
        style={{ minHeight: "100vh" }}
      >
        <div className="bg-gray-800 w-full bg-opacity-60 h-screen bg-fixed overflow-y-auto">
          <div className="content">
            <button
              className="btn2 btn2--tertiary  back-btn backButton"
              style={{ backgroundColor: "white" }}
              onClick={handleGoBack}
            >
              <img src={backArrow} alt="back-button" />
            </button>
            <div className="formgroup">
              <h4 className="text-center">MedicalStore Registration Form</h4>
              <div className="field_Container">
                <>
                  <form onSubmit={PostRegistration} className="form_responsive">
                    <div className="font-semibold py-2 text-xl">
                      Manager Detail
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">First name</h6>
                        <input
                          type="text"
                          id="First name"
                          name="First name"
                          placeholder="First Name"
                          className="formField"
                          value={formValues?.first_name}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              first_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Last Name</h6>
                        <input
                          type="text"
                          id="Last Name"
                          name="Last Name"
                          placeholder="Last Name"
                          className="formField"
                          value={formValues?.last_name}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              last_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">User Name</h6>
                        <input
                          type="text"
                          id="userName"
                          name="userName"
                          placeholder="User Name"
                          className="formField"
                          value={formValues?.userName}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              userName: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Email</h6>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          className="formField"
                          value={formValues?.email}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              email: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="font-semibold py-2 text-xl">
                      Meddical Store Detail
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Name</h6>
                        <input
                          type="text"
                          id="medicalStorename"
                          name="medicalStorename"
                          placeholder="Medical Store name"
                          className="formField"
                          value={formValues?.medicalStoreName}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              medicalStoreName: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div
                        className="field-container"
                      >
                        <h6 className="field-txt">Phone Number</h6>
                        <PhoneInput
                          type="text"
                          value={formValues?.phoneNumber}
                          name="phoneNumber"
                          placeholder="Enter phone number"
                          className="formField"
                          required
                          onChange={(value) =>
                            setFormValues({
                              ...formValues,
                              phoneNumber: value,
                            })
                          }
                          style={{
                            display: "flex",
                            background: "white",
                            borderRadius: "30px",
                            padding: "0px 10px",
                            border: "2px solid #f4f4f4",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">City</h6>
                        <input
                          type="text"
                          id="City"
                          name="City"
                          placeholder="City"
                          className="formField"
                          value={formValues?.city}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              city: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Address</h6>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="address"
                          className="formField"
                          value={formValues?.address}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              address: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">State</h6>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          placeholder="State"
                          className="formField"
                          value={formValues?.state}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              state: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container"></div>
                    </div>
                    <div className="btn_conatiner">
                      <button className="Registration_btn" disabled={loading}>
                        {loading ? "Loading..." : "Register"}
                      </button>
                    </div>
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default RegisterMedicalStore;
