import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from 'react-toastify';
import { fetchApi } from "../utlis/axios";

export default function CheckoutForm({ CaseId, donationAmount }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCaseDonation = async () => {
    const donationAmountInt = parseInt(donationAmount, 10);
    if (isNaN(donationAmountInt)) {
      console.error("Invalid donationAmount: ", donationAmount);
      return;
    }
    const caseData = new FormData();
    caseData.append("donation_amount", donationAmountInt);
    caseData.append("is_active", true);
    caseData.append("case_id", CaseId);

    try {
      await fetchApi.post("case-specific-donation/", caseData);
    } catch (error) {
      console.error("Error in handleCaseDonation: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://khadimwelfarefoundation.com/appericiation",
      },
      redirect: "if_required",
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
        toast.error(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else if (paymentIntent) {
      switch (paymentIntent.status) {
        case "succeeded":
          if (CaseId !== undefined && CaseId !== null) {
            await handleCaseDonation();
          }
          window.location.href = "https://khadimwelfarefoundation.com/appericiation";
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    defaultCollapsed: false,
  };

  return (
    <div>
      <h4 className="text-center mb-4">Donation Form</h4>
      <form id="payment-form" onSubmit={handleSubmit} className="form_stripe">
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
