import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../Common/Navbar";
import { fetchApi } from "../utlis/axios";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import backArrow from "../assets/back-button.png";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const ProgramDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [program, setProgram] = useState(null);
  const [subProgram, setSubProgram] = useState(null);
  const [sortedSubProgram, setSortedSubProgram] = useState(null); // Add state for sorted sub-programs
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("All");

  const getSubProgram = async () => {
    try {
      const result = await fetchApi.get(`sub-programs/`);
      if (result.status === 200) {
        setSubProgram(result?.data); 
        setSortedSubProgram(result?.data); // Set the sortedSubProgram initially
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error fetching program:", error);
      setIsLoading(false);
    }
  };

  const getProgram = async () => {
    try {
      const result = await fetchApi.get(`programs/${id}`);
      if (result.status === 200) {
        setProgram(result?.data); 
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error fetching program:", error);
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    getSubProgram();
    getProgram();
  }, [id]);

  useEffect(() => {
    if (subProgram) {
      let sortedData = [...subProgram.results];
      if (currentTab === "Sort by budget") {
        sortedData.sort((a, b) => b.budget - a.budget);
      } else if (currentTab === "Sort by year") {
        sortedData.sort((a, b) => b.year - a.year);
      }
      setSortedSubProgram({ ...subProgram, results: sortedData });
    }
  }, [currentTab, subProgram]);

  return (
    <>
      <Header />
      <div
        style={{ backgroundColor: "#F8F7F4", minHeight: "100vh" }}
        className="Table_container"
      > 
        <button
          className="btn2 btn2--tertiary back-btn backButton"
          style={{ backgroundColor: "white" }}
          onClick={handleGoBack}
        >
          <img src={backArrow} alt="back-button" />
        </button>
        <div className="Table_Section">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Lottie
                loop={true}
                animationData={Loader}
                style={{ width: "200px" }}
                className="auth-sidebar-video"
              />
            </div>
          ) : (
            <>
              {program?.thumbnail?.length === 0 ? (
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="">
                    <b>No programs have been created.</b>
                  </div>
                </div>
              ) : (
                <>
                  <div className="mt-5 flex justify-center flex-col items-center">
                    <Swiper
                      className="w-11/12 relative rounded-2xl"
                      rewind={true}
                      loop={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      grabCursor={true}
                      modules={[Autoplay]}
                    >
                      {program?.thumbnail?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div className="flex justify-center items-center h-full">
                            <img
                              className="w-full md:h-96 h-64"
                              src={item.file}
                              alt={`Slide ${index + 1}`}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                      <div className="program_detail_slider rounded-2xl z-40"></div>
                      <div className="absolute md:top-16 sm:top-14 top-10 sm:left-16 left-6 z-50 text-white sm:w-1/3 w-1/2">
                        <h1 className="md:text-2xl sm:text-lg text-sm">
                          {program?.name}
                        </h1>
                        <p className="md:text-base text-xs">
                          {program?.description}
                        </p>
                      </div>
                    </Swiper>
                  </div>
                  <div className="mt-5">
                    <h3>Related Programs</h3>
                    <div className="mt-3">
                      <ul className="list-inline flex overflow-x-auto md:gap-5 gap-3 mb-5 relative highlighted_underline">
                        <li
                          className={`feedbutton ${
                            currentTab === "All" ? "active" : ""
                          } relative transition-all`}
                        >
                          <p
                            className="font-semibold text-black bg-transparent cursor-pointer whitespace-nowrap"
                            onClick={() => {
                              setCurrentTab("All");
                            }}
                          >
                            All Programs
                          </p>
                        </li>
                        <li
                          className={`feedbutton ${
                            currentTab === "Sort by budget" ? "active" : ""
                          } `}
                        >
                          <p
                            className="font-semibold whitespace-nowrap text-black bg-transparent cursor-pointer"
                            onClick={() => {
                              setCurrentTab("Sort by budget");
                            }}
                          >
                            Sort by budget
                          </p>
                        </li>
                        <li
                          className={`feedbutton ${
                            currentTab === "Sort by year" ? "active" : ""
                          } `}
                        >
                          <p
                            className="font-semibold whitespace-nowrap text-black bg-transparent cursor-pointer"
                            onClick={() => {
                              setCurrentTab("Sort by year");
                            }}
                          >
                            Sort by year
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="program_main">
                      {sortedSubProgram?.results?.map((data, index) => (
                          <Link to={`/programDetail/${data?.program?.id}`}>
                        <div className="w-72 h-[295px] hover:shadow-lg card_main rounded-xl" key={index}>
                          <div className="card-img relative overflow-hidden ">
                            <img
                              className="w-72 h-40 rounded-t-xl"
                              src={data?.program?.thumbnail?.[0]?.file}
                              alt=""
                            />
                            <div className="card_content w-72 h-11 rounded-t-xl text-white flex justify-center items-center p-1">{data?.program?.name}</div>
                          </div>
                            <div className="p-2">
                              <p className="mb-0"><span className="font-semibold">Year: </span>{data?.year}</p>
                              <p className="mb-0"><span className="font-semibold">Budget: </span>{data.budget}</p>
                              <p>{data?.program?.description && data?.program?.description?.length > 100
                                ? data?.program?.description.slice(0, 100) + "....."
                                : data?.program?.description}
                              </p>
                            </div>
                        </div>
                          </Link>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
