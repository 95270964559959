import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const CasePostModel = ({ setShowModel, postImages, postVideos }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="">
        <div className="sm:p-1 pt-4 relative">
          <div
            className="absolute text-gray-600 right-7 top-5 cursor-pointer text-2xl z-50"
            onClick={() => {
              setShowModel(false);
            }}
          >
            <ImCross />
          </div>

          <div className="max-w-4xl mx-auto ">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              loop={true}
              className="h-full w-full"
            >
              {postImages?.map((img, index) => (
                <SwiperSlide
                  key={index}
                  className="flex justify-center items-center"
                >
                  <img
                    src={img}
                    className="block w-full h-screen object-cover"
                    alt={`Slide ${index + 1}`}
                  />
                </SwiperSlide>
              ))}
              {postVideos?.map((vid, index) => (
                <SwiperSlide
                  className="flex justify-center items-center h-auto"
                  key={index}
                >
                  <video
                    src={vid}
                    controls
                    className="block w-full h-[95%] object-cover"
                    onError={(e) => {
                      console.error(
                        `Error loading video at index ${index}:`,
                        e.target.error
                      );
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
  );
};

export default CasePostModel;
