/* eslint-disable no-const-assign */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
import Loader from "../../animation/Loader.json";
import backArrow from "../../assets/back-button.png";
import dropdown from "../../assets/dropdown.png";
import { toast } from "react-toastify";
import Header from "../../Common/Navbar";
import Author from "../../assets/author/author.jpg";

export const ApplicationDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const { role } = useSelector((state) => state.authSlice.userInfo.user);
  const [members, setMembers] = useState();
  const [isLoading, setIsLoading] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [memberData, setMemberData] = useState();
  const [willingToStudyCount, setWillingToStudyCount] = useState(0);
  const [permanentMedicationCount, setPermanentMedicationCount] = useState(0);
  const [overSixteenCount, setOverSixteenCount] = useState(0);
  const [underSixteenCount, setUnderSixteenCount] = useState(0);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get(`family/${id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setData(result?.data);
        console.log("family detail :", result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
      toast.error(error?.response?.data?.detail);
    }
  };

  const fetchFamilyData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get(`family_detail/${id}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setMembers(result?.data);
        console.log("member result :///", result?.data);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.detail);
    }
  };

  const fetchFamilyMembers = async () => {
    try {
      const result = await fetchApi.get(`member/?family__id=${id}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) setMemberData(result?.data);
      // Counting variables
      setWillingToStudyCount(0);
      setPermanentMedicationCount(0);
      setOverSixteenCount(0);
      setUnderSixteenCount(0);

      // Calculate counts
      result.data.results.forEach((member) => {
        setWillingToStudyCount((prevCount) =>
          member?.willing_to_study ? prevCount + 1 : prevCount
        );
        setPermanentMedicationCount((prevCount) =>
          member?.permanent_medication ? prevCount + 1 : prevCount
        );
        setOverSixteenCount((prevCount) =>
          member?.age > 16 ? prevCount + 1 : prevCount
        );
        setUnderSixteenCount((prevCount) =>
          member?.age <= 16 ? prevCount + 1 : prevCount
        );
      });

      // Set counts in state or wherever needed
      console.log("Willing to Study Count:", willingToStudyCount);
      console.log("Permanent Medication Count:", permanentMedicationCount);
      console.log("Over Sixteen Count:", overSixteenCount);
      console.log("Under Sixteen Count:", underSixteenCount);
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchFamilyData();
    fetchFamilyMembers();
  }, []);

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const ViewMemberfunc = (id) => {
    navigate(`/applicationMember/${id}`);
    console.log("id", id);
  };

  console.log("user Role", role);

  const handleStatus = async (e) => {
    setData((prev) => ({ ...prev, status: e.target.id }));
    const updatedStatus = e.target.id;
    const data = new FormData();
    data.append("status", updatedStatus);
    try {
      const result = await fetchApi.patch(`family/${id}/`, data, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.detail);
    }
  };

  return (
    <>
      <Header />
      <div
        style={{ backgroundColor: "#F8F7F4", minHeight: "100vh" }}
        className="Table_container"
      >
        <button
          className="btn2 btn2--tertiary  back-btn backButton"
          style={{ backgroundColor: "white" }}
          onClick={handleGoBack}
        >
          <img src={backArrow} alt="back-button" />
        </button>
        <div className="Table_Section">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Lottie
                loop={true}
                animationData={Loader}
                style={{ width: "200px" }}
                className="auth-sidebar-video"
              />
            </div>
          ) : (
            <>
              {
                <>
                  <div className="container-small job-details-page-container">
                    <div className="main-content-container">
                      <div className="font-label">Application Detail</div>

                      <h1 className="margin-t-16 job-details-title">
                        {data?.name}
                      </h1>
                      <div className="js-share-actions margin-t-20 display-flex">
                        <a className="btn2 btn2--tertiary facebook-btn margin-r-16">
                          <b style={{ marginRight: "20px" }}>Male</b>
                          {members?.male_members}
                        </a>
                        <a className="btn2 btn2--tertiary facebook-btn margin-r-16">
                          <b style={{ marginRight: "20px" }}>Female</b>
                          {members?.female_members}
                        </a>
                      </div>
                      <div className="margin-t-16 job-details-description">
                        <div className="detail_MainConatiner">
                          <div className="detailDiv">
                            <div>
                              <b>Willing to Study</b>
                              <p>{willingToStudyCount}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "85px" }}>
                                Parmanent Medication
                              </b>
                              <p>{permanentMedicationCount}</p>
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div>
                              <b>Over Sixteen</b>
                              <p>{overSixteenCount}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "50px" }}>
                                Under Sixteen
                              </b>
                              <p>{underSixteenCount}</p>
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div>
                              <b>Pin Location</b>
                              <p>{data?.pin_location}</p>
                            </div>
                            <div>
                              <b>Status Statement</b>
                              <p>{data?.status_statement}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar-container">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          gap: "10px",
                        }}
                      ></div>
                      <div className="sidebar-content-container">
                        <div className="margin-t-24 family-status">
                          <div className="font-sublabel mt-3">
                            <b> Status</b>
                          </div>
                          <div className="font-label margin-t-8">
                            {role === "admin" ? (
                              <>
                                <a
                                  className="btn"
                                  style={{
                                    backgroundColor: "#82EADD",
                                    padding: "3px 8px",
                                    borderRadius: "30px",
                                  }}
                                  onClick={() => {
                                    setIsOpen((prevState) => !prevState);
                                  }}
                                >
                                  {data?.status}
                                  <span>
                                    <img src={dropdown} alt="img" />
                                  </span>
                                </a>
                                <div
                                  className="status-dropdown"
                                  style={{
                                    opacity: isOpen ? 1 : 0,
                                    visibility: isOpen ? "visible" : "hidden",
                                  }}
                                >
                                  <p
                                    className=" text-capitalize accepted "
                                    id="accepted"
                                    onClick={handleStatus}
                                  >
                                    accepted
                                  </p>
                                  <p
                                    className=" text-capitalize rejected"
                                    id="rejected"
                                    onClick={handleStatus}
                                  >
                                    rejected
                                  </p>
                                  <p
                                    className=" text-capitalize  in-review"
                                    id="in-review"
                                    onClick={handleStatus}
                                  >
                                    in-review
                                  </p>
                                  <p
                                    className=" text-capitalize  hold"
                                    id="hold"
                                    onClick={handleStatus}
                                  >
                                    hold
                                  </p>
                                </div>
                              </>
                            ) : (
                              <a
                                className="btn"
                                style={{
                                  backgroundColor: "#82EADD",
                                  padding: "3px 8px",
                                  borderRadius: "30px",
                                }}
                              >
                                {data?.status}
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="margin-t-24">
                          <div className="font-sublabel">
                            <b>Total Member</b>
                          </div>
                          <div className="font-label margin-t-8">
                            {members?.total_members}
                          </div>
                        </div>
                        <div className="margin-t-24">
                          <div className="font-sublabel">
                            <b>Application Priority</b>
                          </div>
                          <div className="font-label margin-t-8">
                            {data?.application_priority}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container px-md-0 mt-4 mb-4">
                    <h4>Members</h4>
                    <div className="row">
                      {memberData?.results?.map((item) => {
                        return (
                          <>
                            <div className="col-md-5 gy-4 " key={item?.id}>
                              <div className="userCard position-relative">
                                <div
                                  className="position-absolute d-none user-action_btn"
                                  style={{ right: "25px" }}
                                >
                                  <button
                                    className="border-0 bg-transparent"
                                    // onClick={() => deleteUser(user?.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                        fontSize: "22px",
                                      }}
                                    ></i>
                                  </button>
                                </div>
                                <div className="card_title justify-content-center">
                                  <img
                                    src={require(`../../assets/${
                                      item?.gender === "Male" ? "man" : "woman"
                                    }.png`)}
                                    alt=""
                                    className="profile"
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div className="mainCard">
                                  <div className="infoContainer">
                                    <div className="infoCard">
                                      <h6>Name</h6>
                                      <p>{item?.first_name}</p>
                                    </div>
                                    <div className="infoCard">
                                      <h6>CNIC</h6>
                                      <p>{item?.cnic_number}</p>
                                    </div>
                                  </div>
                                  <div className="infoContainer">
                                    <div className="infoCard">
                                      <h6>Education</h6>
                                      <p>{item?.education_or_qualification}</p>
                                    </div>
                                    <div className="infoCard">
                                      <h6>Willing to Study</h6>
                                      <p>
                                        {item?.willing_to_study ? "Yes" : "No"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="infoContainer">
                                    <div className="infoCard">
                                      <h6 style={{ width: "100px" }}>
                                        Parmanent Medication
                                      </h6>
                                      <p>
                                        {item?.permanent_medication
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                    <div
                                      className="infoCard"
                                      style={{ marginRight: "90px" }}
                                    >
                                      <h6>Age</h6>
                                      <p>{item?.age}</p>
                                    </div>
                                  </div>
                                  <div className="cmn-btn justify-content-center">
                                    <a
                                      onClick={() => ViewMemberfunc(item?.id)}
                                      className="btn text-white px-3 py-2 "
                                      style={{
                                        backgroundColor: "#DE1285",
                                        borderRadius: 30,
                                        width: "100%",
                                      }}
                                    >
                                      View
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              }
            </>
          )}
        </div>
      </div>
    </>
  );
};
