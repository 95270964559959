/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../Common/Navbar";
import { fetchApi } from "../utlis/axios";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import backArrow from "../assets/back-button.png";
import { toast } from "react-toastify";
import FingerPrintPage from "../Fingerprint/FingerPrintPage";

export const MemberListingdetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState();
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [isEditing, setIsEditing] = useState(false);
  const [step, setStep] = useState(1);
  const [type, setType] = useState("text");
  const [cnicFront, setCnicFront] = useState();
  const [cnicBack, setCnicBack] = useState();
  const [workExperience, setWorkExperience] = useState([]);
  const [educationalDoc, setEducationalDoc] = useState([]);
  const [familyData, setFamilyData] = useState();
  const [editedData, setEditedData] = useState({});
  const [familyid, setFamilyId] = useState();
  const [loading, setLoading] = useState(false);
  const [disablefield, setDisableField] = useState(false);
  const [fingerprintpage, setFringerprintpage] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get(`member/${id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setData(result?.data);
        console.log("member detail :", result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (id) => {
    setIsEditing(true);
    setEditedData({ ...data });
    setFamilyId(id);
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const getFamilydata = async () => {
    try {
      const result = await fetchApi.get(`family/${familyid}`, {
        headers: {
          Authorization: `Bearer ${access}`, // Replace with your API key
        },
      });
      if (result.status === 200) {
        setFamilyData(result.data);
        console.log("Family data...", result.data);
      } else {
        console.error("Failed to fetch data:", result.status);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getFamilydata();
  }, [familyid]);

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("first_name", editedData?.first_name);
      formData.append("last_name", editedData?.last_name);
      formData.append("age", editedData?.age);
      formData.append("cnic_number", editedData?.cnic_number);
      formData.append("date_of_birth", editedData?.date_of_birth);
      formData.append(
        "education_or_qualification",
        editedData?.education_or_qualification
      );
      formData.append("education_status", editedData?.education_status);
      formData.append(
        "education_status_statement",
        editedData?.education_status_statement
      );
      formData.append("willing_to_study", editedData?.willing_to_study);
      formData.append("job", editedData?.job);
      formData.append("monthly_income", editedData?.monthly_income);
      formData.append("medical_condition", editedData?.medical_condition);
      formData.append("permanent_medication", editedData?.permanent_medication);
      formData.append(
        "permanent_medication_description",
        editedData?.permanent_medication_description
      );
      formData.append("gender", editedData?.gender);
      formData.append("skills", editedData?.skills);
      formData.append("num_of_childrens", editedData?.num_of_childrens);
      formData.append("marital_status", editedData?.marital_status);
      formData.append("family", familyid);
      formData.append("address", editedData?.address);
      formData.append("is_active", true);
      if (cnicFront) {
        formData.append("cnic_front", cnicFront);
      }

      if (cnicBack) {
        formData.append("cnic_back", cnicBack);
      }

      educationalDoc.forEach((docId) => {
        formData.append("education_documents", docId?.id);
      });

      workExperience.forEach((docId) => {
        formData.append("work_experience_documents", docId?.id);
      });

      const result = await fetchApi.patch(`member/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });

      if (result.status === 200) {
        setData({ ...data, ...editedData });
        setIsEditing(false);
        setEditedData({});
        console.log("updated data", result?.data);
      }
      fetchData();
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.detail);
    } finally {
      setIsLoading(false);
    }
  };

  const handleWorkChange = async (e) => {
    const newFiles = e.target.files;

    if (newFiles.length > 0) {
      setLoading(true);
      const updatedSelectedFiles = [...workExperience];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const data = new FormData();
        data.append("file", file);
        data.append("type", "Experience Document");
        data.append("is_active", true);

        try {
          const result = await fetchApi.post("attachments/", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access}`,
            },
          });

          if (result?.status === 201) {
            updatedSelectedFiles.push({ id: result.data.id, name: file.name });
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          setLoading(false);
          return; // Stop further processing on error
        }
      }

      setWorkExperience(updatedSelectedFiles);
      setLoading(false);
    } else {
      console.log("Please select at least one file.");
    }
  };

  const handleworkFileDelete = (index) => {
    const updatedworkDoc = [...workExperience];
    updatedworkDoc.splice(index, 1);
    setWorkExperience(updatedworkDoc);
  };

  const handleEducationalChange = async (e) => {
    const newFiles = e.target.files;

    if (newFiles.length > 0) {
      setLoading(true);
      const updatedSelectedFiles = [...educationalDoc];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const data = new FormData();
        data.append("file", file);
        data.append("type", "Property Document");
        data.append("is_active", true);

        try {
          const result = await fetchApi.post("attachments/", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access}`,
            },
          });

          if (result?.status === 201) {
            updatedSelectedFiles.push({ id: result.data.id, name: file.name });
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          setLoading(false);

          return; // Stop further processing on error
        }
      }

      setEducationalDoc(updatedSelectedFiles);
      setLoading(false);
    } else {
      console.log("Please select at least one file.");
    }
  };

  const handleFileDelete = (index) => {
    const updatedEducationalDoc = [...educationalDoc];
    updatedEducationalDoc.splice(index, 1);
    setEducationalDoc(updatedEducationalDoc);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditedData({ ...editedData, cnic_front: file.name });
      setCnicFront(file);
    } else {
      setEditedData({ ...editedData, cnic_back: null });
    }
  };

  const handleFileCnicBackChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditedData({ ...editedData, cnic_back: file.name });
      setCnicBack(file);
    } else {
      setEditedData({ ...editedData, cnic_back: null });
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  const handlefingerPrintPage = () => {
    setFringerprintpage(!fingerprintpage);
  };

  const renderEditFields = () => {
    return (
      <>
        <div class="main-container">
          <div className="content" style={{ backgroundColor: "white" }}>
            <button
              className="btn2 btn2--tertiary  back-btn backButton"
              style={{ backgroundColor: "white" }}
              onClick={handleGoBack}
            >
              <img src={backArrow} alt="back-button" />
            </button>
            <div className="formgroup">
              <h4 className="text-center">Member Registration Form</h4>
              <div className="field_Container">
                {step === 1 && (
                  <>
                    <form onSubmit={handleNext}>
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">First name</h6>
                          <input
                            type="text"
                            id="First name"
                            name="First name"
                            placeholder="First Name"
                            className="formField"
                            value={editedData.first_name}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                first_name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Last name</h6>
                          <input
                            type="text"
                            id="Last Name"
                            name="Last Name"
                            placeholder="Last Name"
                            className="formField"
                            value={editedData.last_name}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                last_name: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Date of Birth</h6>
                          <input
                            type={type}
                            id="date"
                            name="DOB"
                            placeholder="Date of Birth"
                            onFocus={(event) => {
                              event.target.type = "date";
                            }}
                            className="formField"
                            onClick={(e) => setType("date")}
                            value={editedData.date_of_birth}
                            //onChange={(e)=> setEditedData({...editedData, date_of_birth:e.target.value})}
                            onChange={handleDateChange}
                            max={getCurrentDate()}
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">CNIC</h6>
                          <input
                            type="text"
                            id="CNIC"
                            name="CNIC"
                            placeholder="CNIC"
                            className="formField"
                            value={editedData.cnic_number}
                            onChange={(e) => {
                              const inputValue = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 14); // Remove non-digit characters
                              setEditedData({
                                ...editedData,
                                cnic_number: inputValue,
                              });
                            }}
                            pattern="\d*"
                            maxLength="14"
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Age</h6>
                          <input
                            type="number"
                            id="Age"
                            name="Age"
                            placeholder="Age"
                            className="formField"
                            value={editedData.age}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 3); // Limit input to 3 characters
                              setEditedData({ ...editedData, age: inputValue });
                            }}
                            maxLength="3"
                            required
                            disabled
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Education</h6>
                          <select
                            type="text"
                            id="Education"
                            name="Education"
                            placeholder="Education"
                            className="formField custom-select"
                            value={editedData?.education_or_qualification}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                education_or_qualification: e.target.value,
                              })
                            }
                            required
                          >
                            <option>none</option>
                            <option>high_school</option>
                            <option>college</option>
                            <option>university</option>
                          </select>
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Education status choice</h6>
                          <select
                            type="text"
                            id="Education status choice"
                            name="Education status choice"
                            placeholder="Education status choice"
                            className="formField custom-select"
                            value={editedData.education_status}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                education_status: e.target.value,
                              })
                            }
                            required
                          >
                            <option>on_hold</option>
                            <option>completed</option>
                            <option>quitted</option>
                            <option>ongoing</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">
                            Education status statement
                          </h6>
                          <input
                            type="text"
                            id="Education status statement"
                            name="Education status statement"
                            placeholder="Education status statement"
                            className="formField"
                            value={editedData.education_status_statement}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                education_status_statement: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Willing to Study</h6>
                          <select
                            type="text"
                            id="Willing to Study"
                            name="Willing to Study"
                            placeholder="Willing to Study"
                            className="formField custom-select"
                            value={editedData.willing_to_study}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                willing_to_study: e.target.value,
                              })
                            }
                            required
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Job</h6>
                          <input
                            type="text"
                            id="Job"
                            name="Job"
                            placeholder="Job"
                            className="formField"
                            value={editedData.job}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                job: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="next_btn">
                        <button className="Registration_btn">Next</button>
                      </div>
                    </form>
                  </>
                )}
                {step === 2 && (
                  <>
                    <form onSubmit={handleNext} encType="multipart/form-data">
                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Monthly income</h6>
                          <input
                            type="number"
                            id="Monthly income"
                            name="Monthly income"
                            placeholder="Monthly income"
                            className="formField"
                            value={editedData.monthly_income}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                monthly_income: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Permanent medication</h6>
                          <select
                            type="text"
                            id="Permanent medication"
                            name="Permanent medication"
                            placeholder="Permanent medication"
                            className="formField custom-select"
                            value={editedData.permanent_medication}
                            onChange={(e) => {
                              const value = e.target.value;
                              setEditedData({
                                ...editedData,
                                permanent_medication: value,
                              });
                              setDisableField(value === "true");
                            }}
                            required
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Medical condition</h6>
                          <input
                            type="text"
                            id="Medical condition"
                            name="Medical condition"
                            placeholder="Medical condition"
                            className="formField"
                            value={editedData.medical_condition}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                medical_condition: e.target.value,
                              })
                            }
                            disabled={
                              editedData.permanent_medication === "false"
                            }
                            required
                          />
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Medication description</h6>
                          <input
                            type="text"
                            id="Medication description"
                            name="Medication description"
                            placeholder="Medication description"
                            className="formField"
                            value={editedData.permanent_medication_description}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                permanent_medication_description:
                                  e.target.value,
                              })
                            }
                            required
                            disabled={
                              editedData.permanent_medication === "false"
                            }
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Gender</h6>
                          <select
                            type="text"
                            id="Gender"
                            name="Gender"
                            placeholder="Gender"
                            className="formField custom-select"
                            value={editedData.gender}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                gender: e.target.value,
                              })
                            }
                            required
                          >
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Skills</h6>
                          <input
                            type="text"
                            id="Skills"
                            name="Skills"
                            placeholder="Skills"
                            className="formField "
                            value={editedData.skills}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                skills: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="group-fields">
                        <div className="field-container">
                          <h6 className="field-txt">Martial status</h6>
                          <select
                            type="text"
                            id="Martial status"
                            name="Martial status"
                            placeholder="Martial status"
                            className="formField custom-select"
                            value={editedData.marital_status}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                marital_status: e.target.value,
                              })
                            }
                            required
                          >
                            <option>single</option>
                            <option>married</option>
                            <option>committed</option>
                            <option>divorced</option>
                            <option>widow</option>
                          </select>
                        </div>
                        <div className="field-container">
                          <h6 className="field-txt">Number of childrens</h6>
                          <input
                            type="number"
                            id="Number of childrens"
                            name="Number of childrens"
                            placeholder="Number of childrens"
                            className="formField"
                            value={editedData.num_of_childrens}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 3); // Limit input to 3 characters
                              setEditedData({
                                ...editedData,
                                num_of_childrens: inputValue,
                              });
                            }}
                            maxLength="3"
                            required
                            disabled={
                              editedData.marital_status === "single" ||
                              editedData.marital_status === "committed"
                            }
                          />
                        </div>
                      </div>

                      <div className="btn_conatiner">
                        <button
                          onClick={handleBack}
                          className="Registration_Backbtn"
                        >
                          Back
                        </button>
                        <button className="Register_btn">Next</button>
                      </div>
                    </form>
                  </>
                )}

                {step === 3 && (
                  <>
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                        }}
                      >
                        <Lottie
                          loop={true}
                          animationData={Loader}
                          style={{ width: "200px" }}
                          className="auth-sidebar-video"
                        />
                      </div>
                    )}
                    {!loading && (
                      <form onSubmit={handleUpdate}>
                        {fingerprintpage ? (
                          <FingerPrintPage handlefingerPrintPage={handlefingerPrintPage}/>
                        ) : (
                          <>
                            <div className="group-fields">
                              <div className="field-container">
                                <h6 className="field-txt">
                                  Educational attachment
                                </h6>
                                <div className="upload-field">
                                  <input
                                    type="file"
                                    id="Educational attachment"
                                    name="Educational attachment"
                                    onChange={handleEducationalChange}
                                    accept=".pdf"
                                    style={{ display: "none" }}
                                    multiple
                                  />
                                  <input
                                    type="text"
                                    id="Educational attachment-text"
                                    name="Educational attachment-text"
                                    placeholder="Upload Educational Doc"
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          "Educational attachment"
                                        )
                                        .click()
                                    }
                                    className="formField with-icon"
                                    value={
                                      editedData?.education_documents?.length >
                                      0
                                        ? editedData.education_documents[0]
                                            ?.file
                                        : "" || educationalDoc[0]?.name
                                    }
                                    readOnly
                                    required
                                  />
                                  <div className="selected-files ms-2">
                                    {educationalDoc.map((file, index) => (
                                      <div
                                        key={index}
                                        className="selected-file"
                                      >
                                        {/* <img src={file.url} alt={file.name} /> */}
                                        <span>{file.name}</span>
                                        <span
                                          className="delete-icon"
                                          onClick={() =>
                                            handleFileDelete(index)
                                          }
                                        >
                                          <img
                                            src={require("../assets/delete.png")}
                                            alt="delete"
                                            width="15px"
                                            height="15px"
                                          />
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div className="field-container">
                                <h6 className="field-txt">
                                  Work Experience attachment
                                </h6>
                                <div className="upload-field">
                                  <input
                                    type="file"
                                    id="Work Experience"
                                    name="Work Experience"
                                    onChange={handleWorkChange}
                                    accept=".pdf"
                                    style={{ display: "none" }} // Hide the file input
                                    multiple
                                  />
                                  <input
                                    type="text"
                                    id="Work Experience-text"
                                    name="Work Experience-text"
                                    placeholder="Upload Work Experience Doc"
                                    onClick={() =>
                                      document
                                        .getElementById("Work Experience")
                                        .click()
                                    } // Trigger the file input click when text input is clicked
                                    className="formField with-icon"
                                    value={
                                      editedData?.work_experience_documents
                                        ?.length > 0
                                        ? editedData
                                            .work_experience_documents[0]?.file
                                        : "" || workExperience[0]?.name
                                    }
                                    readOnly // Make the text input read-only
                                    required
                                  />
                                  <div className="selected-files ms-2">
                                    {workExperience?.map((file, index) => (
                                      <div
                                        key={index}
                                        className="selected-file"
                                      >
                                        {/* <img src={file.url} alt={file.name} /> */}
                                        <span>{file.name}</span>
                                        <span
                                          className="delete-icon"
                                          onClick={() =>
                                            handleworkFileDelete(index)
                                          }
                                        >
                                          <img
                                            src={require("../assets/delete.png")}
                                            alt="delete"
                                            width="15px"
                                            height="15px"
                                          />
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="group-fields">
                              <div className="field-container">
                                <h6 className="field-txt">Cnic-front</h6>
                                <div className="upload-field">
                                  <input
                                    type="file"
                                    id="cnic-front"
                                    name="cnic-front"
                                    onChange={handleFileInputChange}
                                    accept=".jpg, .jpeg, .png"
                                    style={{ display: "none" }}
                                  />
                                  <input
                                    type="text"
                                    id="cnic-front-text"
                                    name="cnicfront-text"
                                    placeholder="Upload CNIC Front"
                                    onClick={() =>
                                      document
                                        .getElementById("cnic-front")
                                        .click()
                                    }
                                    className="formField with-icon"
                                    value={editedData.cnic_front}
                                    readOnly
                                    required
                                  />
                                </div>
                              </div>
                              <div className="field-container">
                                <h6 className="field-txt">Cnic-back</h6>
                                <div className="upload-field">
                                  <input
                                    type="file"
                                    id="cnic-back"
                                    name="cnic-back"
                                    onChange={handleFileCnicBackChange}
                                    accept=".jpg, .jpeg, .png"
                                    style={{ display: "none" }} // Hide the file input
                                  />
                                  <input
                                    type="text"
                                    id="cnic-back-text"
                                    name="cnicback-text"
                                    placeholder="Upload CNIC Back"
                                    onClick={() =>
                                      document
                                        .getElementById("cnic-back")
                                        .click()
                                    } // Trigger the file input click when text input is clicked
                                    className="formField with-icon"
                                    value={editedData.cnic_back}
                                    readOnly // Make the text input read-only
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="group-fields">
                              <div className="field-container">
                                <h6 className="field-txt">Select Family</h6>
                                <input
                                  type="text"
                                  id="family"
                                  name="family"
                                  placeholder="family"
                                  className="formField"
                                  value={familyData?.name}
                                  // onChange={(e)=> setEditedData({...editedData, description:e.target.value})}
                                  required
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="group-fields">
                              <div className="field-container">
                                <h6 className="field-txt">
                                  Add Family member fingerprints
                                </h6>
                                <button
                                  className="Register_btn"
                                  onClick={handlefingerPrintPage}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="btn_conatiner">
                          {!fingerprintpage &&(<button
                            onClick={handleBack }
                            className="Registration_Backbtn"
                          >
                            Back
                          </button>)}
                          
                          <button className="Register_btn" type="submit">
                            Update
                          </button>
                        </div>
                      </form>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getCurrentDate = () => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = today.getDate().toString().padStart(2, "0");
    return `${today.getFullYear()}-${month}-${day}`;
  };
  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  };

  const handleDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setEditedData({
      ...editedData,
      age: age,
      date_of_birth: dateOfBirth,
    });
  };

  return (
    <>
      <Header />
      <div
        style={{ backgroundColor: "#F8F7F4", minHeight: "100vh" }}
        className="Table_container md:px-10 px-5"
      >
        <button
          className="btn2 btn2--tertiary  back-btn backButton"
          style={{ backgroundColor: "white" }}
          onClick={handleGoBack}
        >
          <img src={backArrow} alt="back-button" />
        </button>
        <div className="Table_Section">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Lottie
                loop={true}
                animationData={Loader}
                style={{ width: "200px" }}
                className="auth-sidebar-video"
              />
            </div>
          ) : (
            <>
              {isEditing ? (
                renderEditFields()
              ) : (
                <>
                  <div
                    className="container-small job-details-page-container"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "-13px",
                        top: "-5px",
                      }}
                    >
                      <button
                        className="edit_btn"
                        onClick={() => handleEditClick(data?.family?.id)}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="main-content-container">
                      <div className="font-label">Member Detail Page</div>
                      <div>
                        <h1 className="margin-t-16 job-details-title">
                          {data?.first_name} {data?.last_name}
                        </h1>
                      </div>
                      <div className="js-share-actions margin-t-20 display-flex">
                        <a className="btn2 btn2--tertiary facebook-btn margin-r-16">
                          {/* <img src={female} alt='img' width='30px' style={{marginRight:'20px'}}/> */}
                          <b style={{ marginRight: "20px" }}>Gender</b>
                          {data?.gender}
                        </a>
                        <a
                          className="btn2 btn2--tertiary facebook-btn margin-r-16"
                          style={{ backgroundColor: "#82EADD" }}
                        >
                          {data?.marital_status}
                        </a>
                      </div>
                      <div className="js-share-actions margin-t-20 display-flex">
                        <a className="btn2 btn2--tertiary facebook-btn margin-r-16">
                          {/* <img src={male} alt='img' width='30px' style={{marginRight:'20px'}}/> */}
                          <b style={{ marginRight: "20px" }}>Age</b>
                          {data?.age}
                        </a>
                        <a className="btn2 btn2--tertiary facebook-btn margin-r-16">
                          {/* <img src={female} alt='img' width='30px' style={{marginRight:'20px'}}/> */}
                          <b style={{ marginRight: "20px" }}>D.O.B</b>
                          {data?.date_of_birth}
                        </a>
                      </div>
                      <div className="margin-t-16 job-details-description">
                        <div className="detail_MainConatiner">
                          <div className="detailDiv">
                            <div>
                              <b>Job</b>
                              <p>{data?.job}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "15px" }}>
                                Monthly Income
                              </b>
                              <p>{data?.monthly_income}</p>
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div>
                              <b>Education</b>
                              <p>{data?.education_or_qualification}</p>
                            </div>
                            <div>
                              <b>Education Status</b>
                              <p>{data?.education_status}</p>
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div>
                              <b>Education Statement</b>
                              <p>{data?.education_status_statement}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "10px" }}>
                                Willing to Study
                              </b>

                              <p>{data?.willing_to_study ? "yes" : "no"}</p>
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div>
                              <b>Medical Condition</b>
                              <p>{data?.medical_condition}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "95px" }}>CNIC</b>
                              {data?.cnic_number !== "" ? (
                                <p>{data?.cnic_number}</p>
                              ) : (
                                <p>Null</p>
                              )}
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div>
                              <b>Permanent Medication</b>
                              <p>{data?.permanent_medication ? "yes" : "no"}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "50px" }}>Description</b>
                              <p>{data?.permanent_medication_description}</p>
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "20px",
                              }}
                            >
                              <b>CNIC Front</b>
                              <img
                                src={data?.cnic_front}
                                alt="cinc img"
                                width="100%"
                                height="70px"
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <b style={{ marginRight: "50px" }}>CNIC Back</b>
                              <img
                                src={data?.cnic_back}
                                alt="cinc img"
                                width="100%"
                                height="70px"
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                          </div>
                          <div className="detailDiv">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <b>Education Document</b>
                              <div className="d-flex flex-row gap-2">
                                {data?.education_documents !== null &&
                                data?.education_documents.length > 0 ? (
                                  data?.education_documents.map(
                                    (document, index) => (
                                      <a
                                        key={index}
                                        href={document.file}
                                        className="href_button mt-2"
                                        style={{ color: "white" }}
                                        target="_blank"
                                      >
                                        View
                                      </a>
                                    )
                                  )
                                ) : (
                                  <p>null</p>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <b style={{ marginRight: "0px" }}>
                                Work Experience
                              </b>
                              <div className="d-flex flex-row gap-2">
                                {data?.work_experience_documents !== null &&
                                data?.work_experience_documents.length > 0 ? (
                                  data?.work_experience_documents.map(
                                    (document, index) => (
                                      <a
                                        key={index}
                                        href={document.file}
                                        className="href_button mt-2"
                                        style={{ color: "white" }}
                                        target="_blank"
                                      >
                                        View
                                      </a>
                                    )
                                  )
                                ) : (
                                  <p>null</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar-container">
                      <div className="sidebar-content-container">
                        <div className="margin-t-24">
                          <div className="font-sublabel">
                            <b>Family</b>
                          </div>
                          <div className="font-label margin-t-8">
                            {data?.family?.name}
                          </div>
                        </div>

                        <div className="margin-t-24">
                          <div className="font-sublabel">
                            <b>Number of Childrens</b>
                          </div>
                          <div className="font-label margin-t-8">
                            {data?.num_of_childrens}
                          </div>
                        </div>
                        <div className="margin-t-24">
                          <div className="font-sublabel">
                            <b>Skills</b>
                          </div>
                          <div className="font-label margin-t-8">
                            {data?.skills}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
