import React,{useState,useEffect} from 'react'
import { Pagination, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import Loader from '../../animation/Loader.json';
import { fetchApi } from "../../utlis/axios";
import { toast } from 'react-toastify'

export const FamilyProgram = () => {
  const [programData, setProgramData] = useState([]);
  const { id } = useSelector((state) => state.authSlice.userInfo?.user); 
  const { access } = useSelector(state => state.authSlice.userInfo);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFamilies, setSelectedFamilies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId]=useState("");
  const [selectedFamiliesInModal, setSelectedFamiliesInModal] = useState([]);
  const [familyModel, setFamilyModel] = useState();
  const [allFamilies, setAllFamilies] = useState([]);
  const [modal, setModal] = useState(false);
  const [isCheck, setIsCheck] = useState(true);
  const [allProgram, setAllProgram] = useState([])
  const [formValues, setFormValues] = useState({
    budget: '',
    year:'',
    program:'',
    family:[]
  });
  const [validationErrors, setValidationErrors] = useState({
    budget: '',
    year:'',
    program: '',
    family:[]
  });


  const getFamilyProgramData = async () => {
    setIsLoading(true)
    try {
      const result = await fetchApi.get(`sub-programs/?search=${searchQuery}`, {
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setProgramData(result?.data);
        console.log('result data.......', result?.data);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log({ error });
    }
  }

 useEffect(() => {
  getFamilyProgramData();
  }, [searchQuery, access]);

  useEffect(() => {
    const fetchAllFamilies = async () => {
      try {
        const result = await fetchApi.get('family/?status=accepted&limit=1000', {
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        });
        if (result.status === 200) {
          setAllFamilies(result.data);
          console.log("All familes...///",result.data )
        }
      } catch (error) {
        console.error("Error fetching all families:", error);
      }
    };
    fetchAllFamilies();
  }, [access]);
  
  const toggle = () => {
    setModal(!modal);
    setIsCheck(true);
    setFormValues({
    budget: '',
    year:'',
    program: '',
    family:[]
    });
  };

  const handleTableRowClick = (id) => {
    console.log("id", id)
    setRowId(id)
    };

    const handleViewButtonClick = (families) => {
      setSelectedFamilies(families);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setSelectedFamilies([]);
      setShowModal(false);
    };

    const handleCloseFamilyModel=()=>{
      setFamilyModel(false);
    }

    const showFamilyModel=()=>{
      setFamilyModel(true)
    }

    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const totalPages = Math.ceil(programData.count / itemsPerPage);
  
    const fetchDataForPage = async (pageNumber) => {
      const newOffset = (pageNumber - 1) * itemsPerPage;
      
      try {
        const result = await fetchApi.get(
          `sub-programs/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          }
        );
    
        return result;
      } catch (error) {
        throw error;
      }
    };

    const renderPaginationItems = () => {
      const pageButtons = [];
      const maxVisibleButtons = 4;
    
      if (totalPages <= maxVisibleButtons) {
        for (let i = 1; i <= totalPages; i++) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      } else {
        pageButtons.push(
          <Pagination.Item
            key={1}
            active={1 === currentPage}
            onClick={() => handlePageClick(1)}
          >
            1
          </Pagination.Item>
        );
    
        for (let i = 2; i < totalPages; i++) {
          if (
            i >= currentPage - 1 &&
            i <= currentPage + 2
          ) {
            pageButtons.push(
              <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => handlePageClick(i)}
              >
                {i}
              </Pagination.Item>
            );
          }
        }
    
        pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);
  
        pageButtons.push(
          <Pagination.Item
            key={totalPages}
            active={totalPages === currentPage}
            onClick={() => handlePageClick(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    
      return pageButtons;
    };

    const handlePageClick = (pageNumber) => {
      console.log("pageNumbber", pageNumber)
      if (pageNumber !== currentPage) {
        setCurrentPage(pageNumber);
        setIsLoading(true);
    
        // Fetch data for the selected page
        fetchDataForPage(pageNumber)
          .then((result) => {
            if (result.status === 200) {
              
              setProgramData(result?.data);
              // console.log("family data....", familyData)
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };
    
    
      const nextPageData = async () => {
      const nextPage = currentPage + 1;
      const newOffset = (nextPage - 1) * itemsPerPage;
    
      setIsLoading(true);
    
      try {
        const result = await fetchApi.get(
          `sub-programs/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          }
        );
    
        if (result.status === 200) {
          setProgramData(result?.data);
          console.log('next data',result.data.results )
          setCurrentPage(nextPage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    const prevPageData = async () => {
      const prevPage = currentPage - 1;
      const newOffset = (prevPage - 1) * itemsPerPage;
    
      setIsLoading(true);
    
      try {
        const result = await fetchApi.get(
          `sub-programs/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          }
        );
    
        if (result.status === 200) {
          setProgramData(result?.data);
          setCurrentPage(prevPage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleDeleteFamily = async (familyId) => {
      // Remove the family from selectedFamilies
      const updatedFamilies = selectedFamilies.filter(family => family.id !== familyId);
      setSelectedFamilies(updatedFamilies);
      // Send a PATCH request to update the data on the server
      try {
        const result = await fetchApi.patch(`sub-programs/${rowId}/`, {
          family: updatedFamilies.map(family => family.id),
        }, {
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        });
    
        if (result.status === 200) {
          console.log("Family id removed")
        }
        getFamilyProgramData();
      } catch (error) {
        console.error("Error deleting family:", error);
      }
    };

    const handleFamilySelectionInModal = (familyId) => {
      const isSelected = selectedFamiliesInModal.includes(familyId);
      if (isSelected) {
        // If the family is already selected, remove it
        setSelectedFamiliesInModal(selectedFamiliesInModal.filter(id => id !== familyId));
      } else {
        // If the family is not selected, add it
        setSelectedFamiliesInModal([...selectedFamiliesInModal, familyId]);
      }
    };
  

    const handleSaveSelectedFamiliesInModal = async () => {
      try {
        // Filter out the IDs of the previously selected families that are not in the selectedFamiliesInModal array
        const previousFamilyIds = selectedFamilies
          .filter(family => !selectedFamiliesInModal.includes(family.id))
          .map(family => family.id);
        
        // Combine the previously selected family IDs with the IDs of the newly selected families
        const familyIds = [...previousFamilyIds, ...selectedFamiliesInModal];
    
        // Send a PATCH request to update the families associated with the program
        const result = await fetchApi.patch(`sub-programs/${rowId}/`, {
          family: familyIds,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access}`,
          },
        });
    
        if (result.status === 200) {
          console.log("Selected families saved successfully.");
          setFamilyModel(false);
          setShowModal(false);
          getFamilyProgramData();
          window. location. reload();
        }
      } catch (error) {
        console.error("Error saving selected families:", error);
      }
    };
    
    const GetAllProgram = async  () => {
      try{
        const result = await fetchApi.get('programs/',{
          headers: {
            'Authorization': `Bearer ${access}`,
          },
        })
        if(result.status === 200)
        {
          setAllProgram(result?.data)
        }
      } catch(error){
        console.log({error})
      }
    }

    useEffect(()=>{
      GetAllProgram();
    },[])

    const validateForm = () => {
      let valid = true;
      const errors = {
        budget: '',
        year:'',
        program: '',
      
      };
  
      if (!formValues.budget) {
        valid = false;
        errors.budget = 'Budget is required';
      }
  
      if (!formValues.year) {
        valid = false;
        errors.year = 'year is required';
      }
  
      if (!formValues.program ) {
        valid = false;
        errors.program = 'program is required';
      }
     
  
      setValidationErrors(errors);
      return valid;
    };

    const createProgram = async () => {
      if (!validateForm()) {
        return;
      }
      
      try {
        const data = new FormData();
        data.append('id', id);
        data.append('budget', formValues?.budget);
        data.append('year', formValues?.year);
        data.append('program', formValues?.program);
        data.append('is_active', true);
        if (formValues.family.length > 0) {
          // Append the family array directly to the FormData object
          formValues.family.forEach((familyId, index) => {
            data.append('family', [familyId]);
          });
        }
    
        const result = await fetchApi.post('sub-programs/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${access}`,
          },
        });
    
        if (result?.status === 201) {
          console.log('posted program');
          setModal(false);
        }
    
        getFamilyProgramData();
      } catch (error) {
        console.error('Error in createProgram:', error);
        toast.error(error?.response?.data?.detail);
      }
    };
    
    

    return (
      <>
        <div className="Table_container" style={{ minHeight: '100vh', paddingTop:'0px'}}>
          <div className="Table_heading">
          <div className='sidebar-search d-flex justify-content-between'>
            <form className="formSearch">
            <input
              type='search'
              name='search'
              className='px-3 Search_input'
              placeholder='Search Here'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            </form>
          </div>
          </div>
          <div className="Table_Section">
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className="">Sub Programs</h5>
            <div className="Tbtn">
              <button className="heading_btn" style={{ backgroundColor: '#DE1285' }} onClick={toggle}>
               Add Family
              </button>
              </div>
              </div>
              <div style={{ display: 'block' }}>
              {isCheck && (
                <Modal show={modal}  onHide={toggle} className="modal-popups">
                <Modal.Body>
                  <h3 className="text-center text-dark">Create Program</h3>
                  <div className="modal-body"> 
                    <form>
                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">
                          Budget:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="recipient-name"
                          style={{ borderRadius: '20px', height: '3rem' }}
                          value={formValues?.budget}
                          onChange={(e) => setFormValues({ ...formValues, budget: e.target.value })}
                        />
                        <span className="error-message text-danger">{validationErrors.budget}</span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">
                          Year:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="recipient-name"
                          style={{ borderRadius: '20px', height: '3rem' }}
                          value={formValues?.year}
                          onChange={(e) => setFormValues({ ...formValues, year: e.target.value })}
                        />
                        <span className="error-message text-danger">{validationErrors.year}</span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">
                        Program:
                        </label>
                        <select
                        type="text"
                        id="program"
                        name="program"
                        placeholder="program"
                        className="formField custom-select"
                        value={formValues?.program}
                        onChange={(e) => setFormValues({ ...formValues, program: e.target.value })}
                        style={{width:'100%', borderRadius:'20px'}}
                        required
                      >
                      <option>Select</option>
                      { allProgram?.results?.map((item)=>{
                          return <option value={item?.id}>{item?.name}</option>
                        })}
                      </select>
                        <span className="error-message text-danger">{validationErrors.description}</span>
                      </div>
                      <div className="form-group">
                      <label htmlFor="recipient-name" className="col-form-label">
                        Family:
                      </label>
                      <select
                        id="family"
                        name="family"
                        className="formField "
                        value={formValues?.family}
                        onChange={(e) => setFormValues({ ...formValues, family: Array.from(e.target.selectedOptions, option => option.value) })}
                        style={{width:'100%', borderRadius:'20px'}}
                        required
                        multiple // Add multiple attribute here
                      >
                        { allFamilies?.results?.map((item)=>{
                          return <option value={item?.id}>{item?.name}</option>
                        })}
                      </select>
                      <span className="error-message text-danger">{validationErrors.family}</span>
                    </div>
                    </form>
                    
                  </div>
                </Modal.Body>
                <Modal.Footer>
                <div className="cmn-btn">
                  <button onClick={toggle}
                    style={{backgroundColor:'#DE1285',
                    borderRadius:'30px'
                  }}
                  >Close</button>
                </div>
                <div className="cmn-btn">
                  <button
                  onClick={createProgram}
                    style={{backgroundColor:'#DE1285',
                    borderRadius:'30px'
                  }}
                  >Create</button>
                </div>

                  </Modal.Footer>
                </Modal>
              )}
            </div>
            {
              isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <Lottie
                    loop={true}
                    animationData={Loader}
                    style={{ width: '200px' }}
                    className='auth-sidebar-video'
                  />
                </div>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table align-middle mb-0 mt-2 bg-white table-hover">
                      <thead className="table-light m-3">
                        <tr className="m-3 ">
                          <th style={{ padding: "10px", }}>Program</th>
                          <th style={{ padding: "10px", textAlign: 'center' }}>Budget</th>
                          <th style={{ padding: "10px", }}>Year</th>
                          <th style={{ padding: "10px", }}>Family</th>
                        </tr>
                      </thead>
                      <tbody>
                        {programData?.results?.map((data, value) => (
                          <tr
                            key={value}
                            className="table-row" // Add this class for styling
                            onClick={() => handleTableRowClick(data?.id)}
                          >
                            <td style={{ verticalAlign: 'middle' }}>
                              <div className="d-flex align-items-center">
                                <div className="">
                                  <p className="fw-bold mb-1 ">{data?.program?.name}</p>
                                </div>
                              </div>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              <div
                                className='dot d-flex align-items-center justify-content-center w-100'
                              >
                                <span className='span-badge'></span>
                                {data?.budget}
                              </div>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              <p className="fw-normal mb-1 ">{data?.year}</p>
                            </td>
                            <td className="text-center" style={{ verticalAlign: 'middle' }}>
                              <button className='href_button mt-2' style={{ color: 'white' }} onClick={() => handleViewButtonClick(data?.family)}>
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex justify-content-between align-items-center mt-3">
                    <Pagination>
                  <Pagination.Prev
                    onClick={prevPageData}
                    disabled={currentPage === 1}
                  />
                {renderPaginationItems()}
                  <Pagination.Next
                  onClick={nextPageData}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal} centered style={{ maxWidth: '600px', margin: 'auto' }}>
        <Modal.Header closeButton style={{ display: 'block' }}>
          <Modal.Title style={{ textAlign: 'center' }}>Family Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
            <div className='d-flex justify-content-end mb-2'>
              <button className="href_button" onClick={showFamilyModel}>Add Family</button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Family Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedFamilies.map((family, index) => (
                  
                  <tr key={index}>
                    <td>{family.name}</td>
                    
                    <td>
                    <button className="href_button bg-danger" onClick={() => handleDeleteFamily(family.id)}>
                      Delete
                    </button>
                  </td>
                  </tr>
                ))}
                 
                
                                
              </tbody>
            </table>
          </div>
        </Modal.Body>
        </Modal>

        <Modal show={familyModel} onHide={handleCloseFamilyModel}>
        <Modal.Header closeButton style={{ display: 'block' }}>
          <Modal.Title style={{ textAlign: 'center' }}>Select Families</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: '350px', overflowY: 'auto', padding:'0px 20px' }}>
            {allFamilies?.results?.map((family) => (
              <div key={family.id} className='border-bottom mt-2 mb-2 d-flex  justify-content-between'>
                <label>{family.name}</label>
                {selectedFamiliesInModal.includes(family.id) ? (
                  <button
                    style={{ 
                    width: '80px', 
                    backgroundColor: 'green',
                    color: 'white', 
                    marginLeft: '10px', 
                    textAlign:'center',
                    marginBottom:'5px'
                  }}
                    onClick={() => handleFamilySelectionInModal(family.id)}
                  >
                    Added
                  </button>
                ) : (
                  <button
                    style={{ 
                    width: '80px', 
                    backgroundColor: '#DE1285', 
                    color: 'white', 
                    marginLeft: '10px',
                    marginBottom:'5px'
                  }}
                    onClick={() => handleFamilySelectionInModal(family.id)}
                  >
                    Add
                  </button>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button style={{ backgroundColor: '#DE1285' }} onClick={handleSaveSelectedFamiliesInModal}>Save</button>
        </Modal.Footer>
      </Modal>


      </>
    )
}
