import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { fetchApi } from "../utlis/axios";
import CheckoutForm from "./CheckoutForm";
import "./App.css";
import { toast } from "react-toastify";


const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_SECRET_KEY,
  {
    stripeAccount: process.env.REACT_APP_STRIP_ACCOUNT_KEY,
  }
);

export default function App({ newAmount, CaseId }) {
  const [clientSecret, setClientSecret] = useState("");
  const [donationAmount, setDonationAmount] = useState();


  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("price", newAmount * 100);
        const result = await fetchApi.post("stripe-intent/", formData);
        const Sdata = result?.data;  
        setClientSecret(Sdata?.clientSecret);
        setDonationAmount(newAmount * 100)
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(error || "An unexpected error occurred");
      }
    };
    fetchData();

  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm options={options} CaseId={CaseId} donationAmount={donationAmount}/>
        </Elements>
      )}
    </div>
  );
}
