import React, {useRef} from "react";
import { IoMdCloseCircle } from "react-icons/io";
import logo from "../../assets/kwf-logo.png";
import {useReactToPrint} from 'react-to-print'

const MonthlyReportModel = ({ setShowModel, medicines }) => {
  const printRef =useRef()

  const handlePrint = useReactToPrint({
    content:()=>printRef.current,
  })

  // Calculate the total sum of data.amount
  const totalAmount = medicines.reduce((acc, cur) => acc + Number(cur.amount), 0);



  return (
    <div className="h-screen  overflow-y-auto">
      <div className="p-4 ml-10 ">
        <div className="max-w-md mx-auto bg-white sm:p-8 p-5 rounded shadow-lg relative">
          <div
            className="absolute text-black right-2 top-2 cursor-pointer text-2xl"
            onClick={() => {
              setShowModel(false);
            }}
          >
            <IoMdCloseCircle />
          </div>
          
          <div className="max-w-md mx-auto" ref={printRef}>
          <div className="w-full flex justify-center items-center py-2">
            <img className="h-16 " src={logo} alt="" />
          </div>
            <div className="flex justify-between">
              <p>
                <span className="font-bold">Medical Store: </span>ABC
              </p>
              <p>
                <span className="font-bold">Month: </span>April
              </p>
              </div>
              <div className="flex justify-between">
              <p>
                <span className="font-bold">Address: </span>asd
              </p>
              <p>
                <span className="font-bold">Status: </span>yes
              </p>
            </div>
            <div className="flex justify-between">
              <p>
                <span className="font-bold">Date: </span>asd
              </p>
              <p>
                <span className="font-bold">Transaction by: </span>yes
              </p>
            </div>
            <div className="border-t border-b border-gray-300 py-2">
              <div className="flex flex-col">
                <p className="text-gray-700">Total amount: </p>
              </div>
              {medicines.map((data) => (
                <div className="flex justify-between items-center mt-2">
                  <p>{data.name}</p>
                  <p>{data.quantity}</p>
                  <p>{data.amount}</p>
                </div>
              ))}
            </div>
          </div>
          <button
              className="bg-gray-900 text-white py-2 px-4 mt-6 rounded-md "
              onClick={handlePrint}
            >
              Print Receipt
            </button>
        </div>
      </div>
    </div>
  );
};

export default MonthlyReportModel;
