// Sidebar.js

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";


const Sidebar = () => {
  const { role } = useSelector((state) => state.authSlice.userInfo?.user);
  const [isCollapsed, setCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState("dashboard");
  const location = useLocation();


  
  const [menu, setMenu] = useState([
    {
      role: "admin",
      name: "Dashboard",
      href: "/dashboard_graph",
      activeTab: "dashboard",
    },
    {
      role: "admin",
      name: "Applications",
      href: "/applications",
      activeTab: "application",
    },
    {
      role: "admin",
      name: "Programms",
      href: "/program",
      activeTab: "program",
    },
    {
      role: "admin",
      name: "Sub Programms",
      href: "/familyProgram",
      activeTab: "familyProgram",
    },
    {
      role: "admin",
      name: "Family Donation",
      href: "/family_donation",
      activeTab: "family_donation",
    },
    {
      role: "admin",
      name: "Donation",
      href: "/kwf_donation",
      activeTab: "kwf_donation",
    },
    {
      role: "admin",
      name: "Medical Stores",
      href: "/kwf_medical_staff",
      activeTab: "kwf_medical_staff",
    },
    {
      role: "pharmacist",
      name: "Verify Fingerprint",
      href: "/verify_fingerprint",
      activeTab: "verify_fingerprint",
    },
    {
      role: "pharmacist",
      name: "Receipts",
      href: "/kwf_receipts",
      activeTab: "kwf_receipts",
    },
    {
      role: "pharmacist",
      name: "Monthly Report",
      href: "/kwf_monthlyreport",
      activeTab: "kwf_monthlyreport",
    },
    // {
    //   name:'Families',
    //   href:'/familydashboard',
    //   activeTab:'family'
    // },
    // {
    //   name: 'Members',
    //   href:'/memberdashboard',
    //   activeTab:'member'
    // }
  ]);

  useEffect(() => {
    const pathname = location.pathname.replace("/dashboard", ""); // Remove '/dashboard' prefix
    const matchingTab = menu.find((item) => pathname.startsWith(item.href));

    if (matchingTab) {
      setActive(matchingTab.activeTab);
      localStorage.setItem("activeTab", matchingTab.activeTab);
    }
  }, [location.pathname, menu]);

  // const handleToggle = () => {
  //   setCollapsed(!isCollapsed);
  // };

  const handleToggleClick = (toggleName) => {
    setActive(toggleName);
    localStorage.setItem("activeTab", toggleName);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 850) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        onClick={toggleSidebar}
        className={`flex justify-center items-center z-10 sidebartoglle bg-black text-white  ${
          isOpen ? "absolute left-52 top-2  z-20" : "fixed top-2 -left-2  "
        }`}
      >
        <MdOutlineKeyboardArrowRight
          className={`${isOpen ? "rotate-180" : ""}`}
        />
      </div>

      <nav
        className={`sidebar transition-all ease-in-out ${
          isCollapsed ? "collapsed" : ""
        } ${!isOpen ? "absolute -left-64" : "fixed left-0 "}`}
      >
        <ul className="list-unstyled components">
          {menu.map(
            (item, index) =>
              // Add a condition to check if item.role is equal to role
              item.role === role && (
                <Link
                  key={index}
                  to={`/dashboard${item.href}`}
                  style={{
                    color: active === item.activeTab ? "white" : "black",
                  }}
                >
                  <li
                    className={active === item.activeTab ? "active" : ""}
                    onClick={() => handleToggleClick(item.activeTab)}
                    style={{
                      backgroundColor:
                        active === item.activeTab ? "#DE1285" : "#CECECE",
                    }}
                  >
                    {item.name}
                  </li>
                </Link>
              )
          )}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
