import React from 'react'

export const HeroSection = () => {
  return (
    <div className='w-100 hero_Section' style={{backgroundColor:"#F8F7F4"}}>
      <div className='home_Container'>
      <div className='home-badge'> 
    Over 1 million ready-to-collaborate in our community !
    </div>
    <div className='hero__heading'>
      <h1>Donations Even if it is a small can Bring Bigger</h1>
    </div>
    <div className='container_Ltext'>
      <p>We know only too well that what we are doing is nothing more than a drop in the ocean. But if the drop were not there, the ocean would be missing something.</p>
    </div>
    <div className='mt-3'>
    <a  href='/donation'
            className="btn text-white px-3 py-2 mx-3"
            style={{ backgroundColor: "#DE1285", borderRadius: 30 }}
          >
            DONATE NOW
    </a>
    </div>
      </div>
    </div>
  )
}
