/* eslint-disable no-lone-blocks */
import React, {useState, useEffect} from "react";
import { Bar } from "react-chartjs-2";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";

const RegisteredMemberChart = () => {
  const [member, setMember] = useState([]);
  const { access } = useSelector(state => state.authSlice.userInfo);

  const getMemberData = async() => {
    try {
      const result = await fetchApi.get('members-graph/', {
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
    
      if (result.status === 200) {
        const memberDataResponse = result.data;
        console.log('memberDataResponse', result.data);
        const monthlyRegister = {};
        const currentYear = new Date().getFullYear();
    
        memberDataResponse.forEach((register) => {
          const createdDate = new Date(register.created_at);
          const registerYear = createdDate.getFullYear();
    
          if (registerYear === currentYear) {
            const month = createdDate.toLocaleString('en-US', { month: 'long' });
    
            if (monthlyRegister[month]) {
              monthlyRegister[month]++; // Increment the count for the month
            } else {
              monthlyRegister[month] = 1; // Initialize count to 1 for the month
            }
          }
        });
    
        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = new Date(currentYear, i).toLocaleString('en-US', {
            month: 'long',
          });
          return {
            month: monthName,
            totalRegisrer: monthlyRegister[monthName] || 0,
          };
        });
    
        setMember(allMonths);
      }
    } catch (error) {
      console.log({ error });
    }
    
  }

  useEffect(()=>{
    getMemberData();
  },[])

  const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1
  const data = {
    labels: member.slice(0, currentMonth)?.map((label) => label?.month),
    datasets: [
      {
        label: "Member Registered",
        data: member.slice
              (0, currentMonth)
              ?.map((label) => label?.totalRegisrer),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      type: 'category',
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="family-registration-chart dashboard-responsive">
      <Bar data={data} options={options}  height={230}/>
    </div>
  );
};

export default RegisteredMemberChart;
