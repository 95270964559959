/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../Common/Navbar";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
import Loader from "../../animation/Loader.json";
import backArrow from "../../assets/back-button.png";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import ReceiptModel from "../Receipts/ReceiptModel";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const MedicalStoredetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(null);
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState();
  const [manegerData, setManegerData] = useState();
  const [editedData, setEditedData] = useState({});
  const [editedManegerData, setEditedManegerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [disablefield, setDisableField] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModel, setShowModel] = useState(false);
  const medicines = [];
  const patientdetail = [1];

  const toggleShowmodel = () => {
    setShowModel(true);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get(`medical-store/${id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setData(result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const fetchManegerData = async () => {
    try {
      const result = await fetchApi.get(`pharmacist/${data?.user?.id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setManegerData(result?.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchManegerData();
  }, [data]);
 
  const handleEditClick = (id) => {
    setIsEditing(true);
    setEditedManegerData({ ...manegerData });
    setEditedData({ ...data });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("first_name", editedManegerData?.first_name);
      formData.append("last_name", editedManegerData?.last_name);
      formData.append("username", editedManegerData?.username);
      formData.append("email", editedManegerData?.email);

      const result = await fetchApi.patch(
        `pharmacist/${data?.user?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      if (result?.status === 200) {
        try {
          const data = new FormData();
          data.append("name", editedData?.name);
          data.append("phone_number", editedData?.phone_number);
          data.append("address", editedData?.address);
          data.append("city", editedData?.city);
          data.append("state", editedData?.state);
          data.append("is_active", true);

          await fetchApi.patch(`medical-store/${id}`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access}`,
            },
          });

          if (result?.status === 200) {
            setData({ ...data, ...editedData });
            setManegerData({ ...manegerData, ...editedManegerData });
            setIsEditing(false);
            setEditedData({});
            setEditedManegerData({});
            toast.success("Successfully Updated")
          }
        } catch (error) {
          console.log({ error });
        }
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.username?.[0]);
      toast.error(error?.response?.data?.email?.[0]);
    } finally {
      setLoading(false); // Set loading back to false when API call is completed
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const renderEditFields = () => {
    return (
      <>
        <div class="main-container">
          <div className="content" style={{ backgroundColor: "white" }}>
            <button
              className="btn2 btn2--tertiary  back-btn backButton"
              style={{ backgroundColor: "white" }}
              onClick={handleGoBack}
            >
              <img src={backArrow} alt="back-button" />
            </button>
            <div className="formgroup">
              <h4 className="text-center">MedicalStore Registration Form</h4>
              <div className="field_Container">
                <>
                  <form onSubmit={handleUpdate}>
                    <div className="font-semibold py-2 text-xl">
                      Manager Detail
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">First name</h6>
                        <input
                          type="text"
                          id="First name"
                          name="First name"
                          placeholder="First Name"
                          className="formField"
                          value={editedManegerData?.first_name}
                          onChange={(e) =>
                            setEditedManegerData({
                              ...editedManegerData,
                              first_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Last Name</h6>
                        <input
                          type="text"
                          id="Last Name"
                          name="Last Name"
                          placeholder="Last Name"
                          className="formField"
                          value={editedManegerData?.last_name}
                          onChange={(e) =>
                            setEditedManegerData({
                              ...editedManegerData,
                              last_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">User Name</h6>
                        <input
                          type="text"
                          id="userName"
                          name="userName"
                          placeholder="User Name"
                          className="formField"
                          value={editedManegerData?.username}
                          onChange={(e) =>
                            setEditedManegerData({
                              ...editedManegerData,
                              username: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Email</h6>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          className="formField"
                          value={editedManegerData?.email}
                          onChange={(e) =>
                            setEditedManegerData({
                              ...editedManegerData,
                              email: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="font-semibold py-2 text-xl">
                      Meddical Store Detail
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">Name</h6>
                        <input
                          type="text"
                          id="medicalStorename"
                          name="medicalStorename"
                          placeholder="Medical Store name"
                          className="formField"
                          value={editedData?.name}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Phone Number</h6>
                        <PhoneInput
                          type="text"
                          value={editedData?.phone_number}
                          name="phoneNumber"
                          placeholder="Enter phone number"
                          className="formField"
                          required
                          onChange={(value) =>
                            setEditedData({
                              ...editedData,
                              phone_number: value,
                            })
                          }
                          style={{
                            display: "flex",
                            background: "white",
                            borderRadius: "30px",
                            padding: "0px 10px",
                            border: "2px solid #f4f4f4",
                          }}
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">City</h6>
                        <input
                          type="text"
                          id="City"
                          name="City"
                          placeholder="City"
                          className="formField"
                          value={editedData?.city}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              city: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container">
                        <h6 className="field-txt">Address</h6>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="address"
                          className="formField"
                          value={editedData?.address}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              address: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="group-fields">
                      <div className="field-container">
                        <h6 className="field-txt">State</h6>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          placeholder="State"
                          className="formField"
                          value={editedData?.state}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              state: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="field-container"></div>
                    </div>
                    <div className="btn_conatiner">
                      <button className="Register_btn">Update</button>
                    </div>
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Header />
      {showModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div
            className="bg-gray-900 bg-opacity-80 h-full w-full fixed"
            onClick={() => {
              setShowModel(false);
            }}
          ></div>
          <ReceiptModel setShowModel={setShowModel} medicines={medicines} />
        </div>
      )}
      <div
        style={{ backgroundColor: "#F8F7F4", minHeight: "100vh" }}
        className="Table_container md:px-10 px-5"
      >
        <button
          className="btn2 btn2--tertiary  back-btn backButton"
          style={{ backgroundColor: "white" }}
          onClick={handleGoBack}
        >
          <img src={backArrow} alt="back-button" />
        </button>
        <div className="Table_Section">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Lottie
                loop={true}
                animationData={Loader}
                style={{ width: "200px" }}
                className="auth-sidebar-video"
              />
            </div>
          ) : (
            <>
              {isEditing ? (
                renderEditFields()
              ) : (
                <>
                  <div className="container-small job-details-page-container">
                    <div className="main-content-container">
                      <div className="font-label">
                        Medical Store Detail Page
                      </div>
                      <h1 className="margin-t-16 job-details-title">
                        {data?.name}
                      </h1>
                      <div className="margin-t-16 job-details-description">
                        <div className="detail_MainConatiner">
                          <div className="sm:flex gap-64">
                            <div>
                              <b>Address</b>
                              <p>{data?.address}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "30px" }}>City</b>
                              <p>{data?.city}</p>
                            </div>
                          </div>
                          <div className="sm:flex gap-64">
                            <div>
                              <b style={{ marginRight: "15px" }}>
                                State
                              </b>
                              <p>{data?.state}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "20px" }}>Phone Number</b>
                              <p>{data?.phone_number}</p>
                            </div>
                          </div>
                        </div>
                        <div className="detail_MainContainer">
                          <h6 className="margin-t-16 font-semibold text-2xl">
                            Manager Details
                          </h6>
                          <div className="sm:flex gap-64">
                            <div>
                              <b>Full Name</b>
                              <p>{data?.user?.first_name} {data?.user?.last_name}</p>
                            </div>
                            <div>
                              <b style={{ marginRight: "30px" }}>Email</b>
                              <p>{data?.user?.email}</p>
                            </div>
                          </div>
                          <div className="sm:flex gap-64">
                            <div>
                              <b>Address</b>
                              <p>{data?.father_name} sasd</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar_edit">
                      <button
                        className="edit_btn"
                        onClick={() => handleEditClick(data?.id)}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MedicalStoredetail;
