import React, { useState, useEffect } from "react";
import { fetchApi } from "../utlis/axios";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import { Link } from "react-router-dom";
import Navbar from "../Common/Navbar";

export const Programs = () => {
  const [program, setProgram] = useState();
  const [programLoading, setProgramLoading] = useState(false);

  const fetchPrograms = async () => {
    setProgramLoading(true);
    try {
      const result = await fetchApi.get("programs/");
      if (result?.status === 200) {
        setProgram(result.data);
        setProgramLoading(false);
      }
    } catch (error) {
      console.log({ error });
      setProgramLoading(false);
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  return (
    <>
      <Navbar />
      <section className="Admindashboard__task__wrapper">
        <div className="dashboardCard">
          <h1 className="text-center py-2">Programs</h1>
          {programLoading && (
            <div
              style={{
                position: "absolute",
                top: "70%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 40,
              }}
            >
              <Lottie
                loop={true}
                animationData={Loader}
                style={{ width: "150px" }}
              />
            </div>
          )}
          {program?.results?.length === 0 ? (
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className="">
                <b>No programs have been created.</b>
              </div>
            </div>
          ) : (
            <div className="program_main">
              {program?.results?.map((data, index) => (
                  <Link to={`/programDetail/${data.id}`}>
                <div className="w-72 h-64 hover:shadow-lg card_main rounded-xl">
                  <div
                    className="card-img relative overflow-hidden "
                    key={index}
                  >
                    <img
                      className="w-72 h-40 rounded-t-xl "
                      src={data.thumbnail[0].file}
                      alt={`image ${index + 1}`}
                    />
                    <div className="card_content w-72 h-11 rounded-t-xl text-white flex justify-center items-center p-1">
                      {data?.name && data.name.length > 20
                        ? data.name.slice(0, 20) + "..."
                        : data?.name}
                    </div>
                  </div>
                    <div className="p-2">
                      {data?.description && data?.description?.length > 100
                        ? data?.description.slice(0, 100) + "....."
                        : data?.description}
                    </div>
                </div>
                  </Link>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
