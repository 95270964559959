import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Common/Navbar";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import Loader from "../animation/Loader.json";
import CasePostModel from "./CasePostModel";
import { fetchApi } from "../utlis/axios";
import { BsFillSendFill } from "react-icons/bs";
import { FaDonate } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { IoIosShareAlt } from "react-icons/io";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PiPaperclipFill } from "react-icons/pi";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";

const CasesFeeds = () => {
  const { role } = useSelector(
    (state) => state?.authSlice?.userInfo?.user || {}
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [currentTab, setCurrentTab] = useState("Approved");
  const [showModel, setShowModel] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [editFeed, setEditFeed] = useState({});
  const [comments, setComments] = useState([]);
  const [isShowComment, setIsShowComment] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const initialRender = useRef(true);
  const [commentsCounts, setCommentsCounts] = useState({});
  const [commentsPage, setCommentsPage] = useState({});
  const [hasMoreComments, setHasMoreComments] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [donationsCounts, setDonationsCounts] = useState({});
  const [isHoveredId, setIsHoveredId] = useState(null);
  const [shareMenu, setShareMenu] = useState(false);
  const [postImages, setPostImages] = useState([]);
  const [postVideos, setPostVideos] = useState([]);
  const [caseMenu, setCaseMenu] = useState(false);
  const itemPerPage = 5;

  const [formValues, setFormValues] = useState({
    status: editFeed?.status,
    approved: editFeed?.is_approved,
  });

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.previousUrl === "/postcase") {
      setCurrentTab("New");
    }
  }, []);

  const toggleShowmodel = (img, vid) => {
    setPostImages(img);
    setPostVideos(vid);
    setShowModel(true);
  };

  const submitComment = async (e, caseID) => {
    e.preventDefault();
    try {
      const data = {
        case_id: caseID,
        is_active: true,
        text: commentValue,
      };
      const result = await fetchApi.post("case-comment/", data);
      if (result?.status === 201) {
        // Prepend the new comment to the comments array
        setComments((prevComments) => ({
          ...prevComments,
          [caseID]: [result.data, ...(prevComments[caseID] || [])],
        }));
        setCommentValue("");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const toggleComments = async (postId) => {
    if (!comments[postId]) {
      await fetchComments(postId, 1);
    }
    setCommentsPage((prevState) => ({
      ...prevState,
      [postId]: 1,
    }));
  };

  const fetchComments = async (postId, page) => {
    const newOffset = (page - 1) * 10;
    setCommentLoading(true);
    const response = await fetchApi.get(
      `case-comment/?ordering=-created_at&case_id=${postId}&offset=${newOffset}&page=${page}`
    );
    setComments((prevState) => ({
      ...prevState,
      [postId]: [...(prevState[postId] || []), ...response?.data?.results],
    }));
    setCommentLoading(false);
    setHasMoreComments(response?.data?.next != null);
  };

  const loadMoreComments = async (postId) => {
    const nextPage = commentsPage[postId] + 1;
    await fetchComments(postId, nextPage);
    setCommentsPage((prevState) => ({
      ...prevState,
      [postId]: nextPage,
    }));
  };

  useEffect(() => {
    const GetCases = async () => {
      const newOffset = (page - 1) * itemPerPage;
      setIsLoading(true);
      try {
        let result;
        if (currentTab === "Approved") {
          result = await fetchApi.get(
            `case/?ordering=created_at&is_approved=true&limit=${itemPerPage}&offset=${newOffset}&page=${page}&pageSize=${itemPerPage}`
          );
        } else {
          result = await fetchApi.get(
            `case/?ordering=created_at&status=${currentTab.toLowerCase()}&limit=${itemPerPage}&offset=${newOffset}&page=${page}&pageSize=${itemPerPage}`
          );
        }
        if (result?.status === 200) {
          let results = result?.data?.results;
          setFeeds((prevFeeds) =>
            page === 1 ? results : [...prevFeeds, ...results]
          );
          setHasMore(result?.data?.results?.length > 0);
        }
      } catch (error) {
        console.log({ error });
      }
      setIsLoading(false);
    };

    if (initialRender.current) {
      initialRender.current = false;
      GetCases();
    } else {
      GetCases();
    }
  }, [page, currentTab]);

  useEffect(() => {
    // Reset the page to 1 whenever the currentTab changes
    setPage(1);
  }, [currentTab]);

  useEffect(() => {
    const fetchCommentsCounts = async () => {
      const counts = {};
      for (const feed of feeds) {
        const response = await fetchApi.get(
          `case-comment/?case_id=${feed?.id}`
        );
        counts[feed.id] = response?.data?.count;
      }
      setCommentsCounts(counts);
    };
    fetchCommentsCounts();
  }, [feeds, comments]);

  useEffect(() => {
    const fetchDonationsCounts = async () => {
      const counts = {};
      for (const feed of feeds) {
        const response = await fetchApi.get(
          `case-specific-donation/?case_id=${feed?.id}`
        );
        counts[feed.id] = response?.data?.count;
      }
      setDonationsCounts(counts);
    };
    fetchDonationsCounts();
  }, [feeds]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const toggleDescription = (index) => {
    setShowFullDescription((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleEdit = (data) => {
    setIsEditing(true);
  };

  const handleEditData = async () => {
    const data = {};
    const result = await fetchApi.post("/", data);
  };

  const formatDate = (d) => {
    const dateString = d;
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const handleDeleteCmnt = async (id) => {
    try {
      const response = await fetchApi.delete(`case-comment/?case_id=${id}`);
    } catch (error) {
      toast.error("Something went wrong while delete comment");
    }
  };

  const renderMediaContent = (attachments) => {
    let images = [];
    let videos = [];
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
    const videoExtensions = [
      "mp4",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "flv",
      "webm",
      "mpeg",
      "m4v",
    ];
    attachments.forEach((file) => {
      if (file?.file !== null) {
        const fileNameParts = file?.file?.split(".");
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        if (imageExtensions.includes(fileExtension)) {
          images.push(file?.file);
        } else if (videoExtensions.includes(fileExtension)) {
          videos.push(file?.file);
        }
      }
    });
    return (
      <>
        {images?.length === 1 && videos?.length === 1 ? (
          <div className="flex gap-2">
            <img
              src={images[0]}
              onClick={() => toggleShowmodel(images, videos)}
              key={98}
              className="w-1/2 h-60 object-cover cursor-pointer"
              alt="post-img"
              element-index={0}
            />
            <video
              onClick={() => toggleShowmodel(images, videos)}
              className="w-1/2 h-60 object-cover cursor-pointer"
              src={videos[0]}
              controls
            />
          </div>
        ) : (
          <div className="flex gap-2">
            {images?.slice(0, 2).map((image, index) => (
              <div
                className={`image-overlays w-1/2 h-60 object-cover relative `}
                key={index}
              >
                <img
                  src={image}
                  onClick={() => toggleShowmodel(images, videos)}
                  id={image.id}
                  key={index}
                  alt="post-img"
                  element-index={index}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
                {images?.length > 1 && index === 1 && (
                  <div
                    className={`overlay overlay-${index + 1}`}
                    element-index={index}
                    onClick={() => toggleShowmodel(images, videos)}
                  >
                    + {images?.length - 1}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {showModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div
            className="bg-gray-950 bg-opacity-95 h-full w-full fixed"
            onClick={() => {
              setShowModel(false);
            }}
          ></div>
          <CasePostModel
            setShowModel={setShowModel}
            postImages={postImages}
            postVideos={postVideos}
          />
        </div>
      )}
      {isEditing && (
        <div className="z-20 top-0 left-0 right-0 fixed">
          <div className="bg-gray-950 bg-opacity-95 h-full w-full fixed"></div>
          <div className="flex justify-center items-center h-screen w-full">
            <div className="md:w-1/2 w-11/12 bg-white p-8  rounded shadow-lg relative">
              <h2 className="text-center">Edit Case</h2>
              <div className="flex items-center gap-2">
                <span className="font-semibold">Title:</span>
                {editFeed?.title}
              </div>
              <div className="flex gap-2 items-center">
                <span className="font-semibold">Created date:</span>
                {formatDate(editFeed?.created_at)}
              </div>
              <form onSubmit={handleEditData} className="form_responsive">
                <div className="field-container py-2">
                  <h6>Status</h6>
                  <select
                    type="text"
                    id="status"
                    name="status"
                    placeholder="Status"
                    className="formField custom-select w-100"
                    value={formValues?.status}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        status: e.target.value,
                      })
                    }
                    defaultValue={editFeed?.status}
                    required
                  >
                    <option value="completed">completed</option>
                    <option value="declined">reject</option>
                    <option value="in-progress">in-progress</option>
                  </select>
                </div>
                <div className="field-container py-2">
                  <h6 className="">Approved</h6>
                  <select
                    type="text"
                    id="approved"
                    name="approved"
                    placeholder="Approved"
                    className="formField custom-select w-100"
                    value={formValues?.approved}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        approved: e.target.value,
                      })
                    }
                    defaultValue={editFeed?.is_approved}
                    required
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="flex gap-2 justify-end">
                  <button
                    className="bg-[#DE1285] w-auto rounded-3xl"
                    type="submit"
                  >
                    save
                  </button>
                  <button
                    className="bg-[#DE1285] w-auto rounded-3xl"
                    onClick={() => setIsEditing(false)}
                  >
                    cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <Navbar />
      <div className="bg-[#f8f7f4]">
        <div className="sm:flex justify-between items-center p-5">
          <div className="text-4xl font-bold">Cases Feeds</div>
          <div className="Tbtn">
            <Link to="/postcase">
              <button className="bg-[#DE1285] rounded-3xl">
                Post new case
              </button>
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className=" lg:w-2/4 w-3/4 mx-2">
            <div className="relative flex justify-center">
              <input
                type="search"
                name="search"
                className="px-3 Search_input w-3/4 border border-black"
                placeholder="Search Here"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="mt-5">
              <ul className="list-inline flex overflow-x-auto gap-2 justify-around mb-5 relative highlighted_underline">
                {["Approved", "In-progress", "New", "Completed"].map((tab) => (
                  <li
                    key={tab}
                    className={`feedbutton ${
                      currentTab === tab ? "active" : ""
                    } `}
                  >
                    <p
                      className="font-semibold text-black bg-transparent cursor-pointer"
                      onClick={() => {
                        setCurrentTab(tab);
                      }}
                    >
                      {tab}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            {isLoading && page === 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Lottie
                  loop={true}
                  animationData={Loader}
                  style={{ width: "200px" }}
                  className="auth-sidebar-video"
                />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={feeds.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Lottie
                      loop={true}
                      animationData={Loader}
                      style={{ width: "100px" }}
                      className="auth-sidebar-video"
                    />
                  </div>
                }
              >
                {feeds.length === 0 ? (
                  <div className="h-full w-full text-center">
                    <p className="text-4xl font-semibold py-5">No data found</p>{" "}
                  </div>
                ) : (
                  feeds
                    .filter((item) => {
                      return (
                        searchQuery.trim().toLowerCase() === "" ||
                        item.title
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      );
                    })
                    .map((item, index) => (
                      <div
                        className="bg-white w-full rounded-xl shadow-md p-4 my-3"
                        id={item?.id}
                        element-index={index}
                        key={index}
                        onMouseOver={() => setIsHoveredId(item?.id)}
                        onMouseLeave={() => {
                          setIsHoveredId(null);
                          setShareMenu(false);
                          setCaseMenu(false);
                        }}
                      >
                        <div className="flex items-center relative">
                          {role === "admin" && (
                            <div className="text-2xl absolute -right-2 -top-2">
                              <BsThreeDotsVertical
                                className="cursor-pointer"
                                onClick={() => setCaseMenu(true)}
                              />
                              {caseMenu && isHoveredId === item.id && (
                                <div className="absolute text-sm right-4 top-5 bg-[#DE1285] text-white flex flex-col rounded-md text-center z-10 p-2">
                                  <span
                                    className="cursor-pointer hover:underline"
                                    onClick={() => {
                                      handleEdit(item);
                                      setEditFeed(item);
                                    }}
                                  >
                                    Edit
                                  </span>
                                  <hr className="m-0" />
                                  <Link to={`/caseDonationStats/${item?.id}`}>
                                    <span className="text-white cursor-pointer hover:underline">
                                      Donation stats
                                    </span>
                                  </Link>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="rounded-full font-semibold p-2 w-14 text-xl h-14 border border-black flex justify-center items-center bg-gradient-to-br from-pink-600">
                            KWF
                          </div>
                          <div className="ml-2 flex flex-col gap-0">
                            <div className="flex flex-wrap items-baseline">
                              <span className="font-semibold text-xl">
                                {item?.title}
                              </span>
                              <span className="text-gray-600 text-sm pl-2">
                                {formatDate(item?.created_at)}
                              </span>
                            </div>
                            <span className="text-gray-600">
                              Khadim Walfare Foundation
                            </span>
                          </div>
                        </div>
                        <div className="relative">
                          <p className="mb-2">
                            {showFullDescription[index]
                              ? item.description
                              : item.description.length > 120
                              ? `${item.description.slice(0, 120)}...`
                              : item.description}
                            {item.description.length > 120 && (
                              <span
                                className="text-blue-500 font-semibold text-sm hover:underline cursor-pointer"
                                onClick={() => toggleDescription(index)}
                              >
                                {showFullDescription[index]
                                  ? " see less"
                                  : " see more"}
                              </span>
                            )}
                          </p>
                        </div>
                        <div>
                          {renderMediaContent(
                            item?.attachment_before_completion
                          )}
                        </div>
                        <div className="flex gap-2 justify-end pt-2 -mb-2 items-baseline">
                          <div className="text-xs">
                            {commentsCounts[item.id] === 0 ? (
                              ""
                            ) : commentsCounts[item.id] === 1 ? (
                              <span
                                className="hover:underline hover:text-blue-700 cursor-pointer"
                                onClick={() => {
                                  toggleComments(item?.id);
                                  setIsShowComment((prevIndex) =>
                                    prevIndex === index ? null : index
                                  );
                                }}
                              >
                                1 comment
                              </span>
                            ) : (
                              commentsCounts[item.id] > 1 && (
                                <span
                                  className="hover:underline hover:text-blue-700 cursor-pointer"
                                  onClick={() => {
                                    toggleComments(item?.id);
                                    setIsShowComment((prevIndex) =>
                                      prevIndex === index ? null : index
                                    );
                                  }}
                                >
                                  {commentsCounts[item.id]} comments
                                </span>
                              )
                            )}
                          </div>
                          {(item?.is_approved === true ||
                            item?.status === "in-progress") && (
                            <>
                              {donationsCounts[item.id] > 0 &&
                                commentsCounts[item.id] > 0 && (
                                  <span className="text-4xl text-gray-500 -mx-1">
                                    .
                                  </span>
                                )}
                              <div className="hover:text-blue-700 text-xs hover:underline cursor-pointer">
                                <Link to={`/donation/${item?.id}`}>
                                  {donationsCounts[item.id] === 0 ? (
                                    ""
                                  ) : donationsCounts[item.id] === 1 ? (
                                    <span className="hover:underline hover:text-blue-700 cursor-pointer">
                                      1 donation
                                    </span>
                                  ) : (
                                    donationsCounts[item.id] > 1 && (
                                      <span className="hover:underline hover:text-blue-700 cursor-pointer">
                                        {donationsCounts[item.id]} donations
                                      </span>
                                    )
                                  )}
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                        <hr className="-mb-3 " />
                        <div className="flex justify-between mt-4 mx-2">
                          <div className="flex gap-1 items-center ">
                            <div
                              className="cursor-pointer flex gap-2 h-6"
                              onClick={() => {
                                toggleComments(item?.id);
                                setIsShowComment((prevIndex) =>
                                  prevIndex === index ? null : index
                                );
                              }}
                            >
                              <FaComments className="text-2xl " />
                              <p>Comment</p>
                            </div>
                          </div>
                          {(item?.is_approved === true ||
                            item?.status === "in-progress") && (
                            <>
                              <div>
                                <Link to={`/donation/${item?.id}`}>
                                  <div className="flex gap-2 h-6">
                                    <FaDonate className="text-2xl" />
                                    <p>Donate</p>
                                  </div>
                                </Link>
                              </div>
                              <div>
                                <div
                                  className="flex gap-2 h-6 cursor-pointer relative"
                                  onClick={() => setShareMenu(true)}
                                >
                                  <IoIosShareAlt className="text-2xl" />
                                  <p>Share</p>
                                  {shareMenu && isHoveredId === item?.id && (
                                    <div className="flex gap-3 absolute bottom-7 -right-6 bg-slate-300 rounded-3xl h-10 items-center px-3 py-3">
                                      <div>
                                        <FacebookShareButton
                                          className="hover:-translate-y-1"
                                          url={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                        >
                                          <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                      </div>
                                      <div>
                                        <WhatsappShareButton
                                          className="hover:-translate-y-1"
                                          url={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                          title={"KWF"}
                                          separator=":: "
                                        >
                                          <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                      </div>
                                      <div>
                                        <LinkedinShareButton
                                          className="hover:-translate-y-1"
                                          url={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                        >
                                          <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                      </div>
                                      <CopyToClipboard
                                        onCopy={() => {
                                          toast.dismiss();
                                          toast.success("Link Copied");
                                        }}
                                        text={`https://khadimwelfarefoundation.com/casesfeeds/${item?.id}`}
                                      >
                                        <div className="text-[40px] cursor-pointer hover:-translate-y-1">
                                          <PiPaperclipFill />
                                        </div>
                                      </CopyToClipboard>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className={`${
                            isShowComment !== index ? "d-none" : ""
                          }`}
                        >
                          <div className={`feed-social socials p-4 py-3`}>
                            <form
                              className="relative flex justify-center w-full"
                              onSubmit={(e) => submitComment(e, item?.id)}
                            >
                              <input
                                required
                                type="text"
                                name="Comment"
                                placeholder="comment Here"
                                value={commentValue}
                                onChange={(e) =>
                                  setCommentValue(e.target.value)
                                }
                                className="px-3 Search_input border border-black"
                              />
                              <button
                                className="bg-transparent contents"
                                type="submit"
                              >
                                <BsFillSendFill className="text-black absolute right-4 top-4 text-xl cursor-pointer" />
                              </button>
                            </form>
                          </div>
                          <>
                            {comments[item?.id]?.map((cmnt) => (
                              <>
                                <div
                                  key={cmnt?.id}
                                  className="flex mb-1 gap-3 relative cmnt_text"
                                >
                                  <div className="rounded-full font-semibold p-2 w-8 text-xs h-8 border border-black flex justify-center items-center bg-gradient-to-br from-pink-600">
                                    KWF
                                  </div>
                                  <div className="text-justify mr-3 ">
                                    {cmnt?.text}
                                  </div>
                                  {role === "admin" && (
                                    <div
                                      className="cursor-pointer absolute right-0 text-lg cmnt_text_btn"
                                      onClick={() => handleDeleteCmnt(cmnt?.id)}
                                    >
                                      <MdDelete />
                                    </div>
                                  )}
                                </div>
                                <hr className="mb-3" />
                              </>
                            ))}
                            {hasMoreComments && (
                              <span
                                className="cursor-pointer hover:bg-gray-200 p-1 rounded"
                                onClick={() => loadMoreComments(item?.id)}
                              >
                                Show more comments
                              </span>
                            )}
                          </>
                          {commentLoading && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Lottie
                                loop={true}
                                animationData={Loader}
                                style={{ width: "100px" }}
                                className="auth-sidebar-video"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                )}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CasesFeeds;
