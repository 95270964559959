import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
  if (!token) {
    return true; // Token is not present
  }

  const decodedToken = jwtDecode(token);
  if (!decodedToken || !decodedToken.exp) {
    return true; 
  }

  const currentTime = Math.floor(Date.now() / 1000); 
  return decodedToken.exp < currentTime;
};
