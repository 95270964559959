import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { fetchApi } from "../../utlis/axios";

const UpCommingDonation = () => {
  const { access } = useSelector((state) => state.authSlice.userInfo);
  const [donation, setDonation] = useState([]);

  const getDonationData = async () => {
    try {
      const result = await fetchApi.get("donation-graph/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      if (result.status === 200) {
        const donationDataResponse = result.data;
        const monthlyMedication = {};
        const currentYear = new Date().getFullYear();

        donationDataResponse.forEach((donationData) => {
          const createdDate = new Date(donationData.created_at);
          const donationYear = createdDate.getFullYear();

          if (donationYear === currentYear && donationData.amount) {
            const month = createdDate.toLocaleString("en-US", {
              month: "long",
            });

            if (monthlyMedication[month]) {
              monthlyMedication[month] += donationData.amount; // Accumulate the amount for each month
            } else {
              monthlyMedication[month] = donationData.amount;
            }
          }
        });

        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = new Date(null, i).toLocaleString("en-US", {
            month: "long",
          });
          return {
            month: monthName,
            count: monthlyMedication[monthName] || 0,
          };
        });

        setDonation(allMonths);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getDonationData();
  }, []);

  const data = {
    labels: donation.map((label) => label?.month),
    datasets: [
      {
        label: "KWF Donation Amount",
        data: donation.map((label) => label?.count),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category",
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        precision: 0,
      },
    },
  };

  return (
    <div className="dashboard-responsive">
      <Bar data={data} options={options} height={230} />
    </div>
  );
};

export default UpCommingDonation;
