import React, { useState } from "react";
import MedicineData from "../Addmedicine/MedicineData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CaptureFingerprint = () => {
  const [showModel, setShowModel] = useState(false);

  // Function to handle verification
  const handleVerify = () => {
    setShowModel(true);
    toast.success("Verification successful!");
  };

  return (
    <>
      {showModel && (
        <div className="z-10 top-0 left-0 right-0 fixed">
          <div
            className="bg-gray-900 bg-opacity-80 h-full w-full fixed"
          ></div>
          <MedicineData setShowModel={setShowModel} />
        </div>
      )}
      <div className="flex flex-col items-center justify-center capture_fingerprint">
        <h1 className="text-xl md:text-3xl lg:text-4xl font-semibold mb-4 text-center">
          Verify Patient Fingerprint
        </h1>
        <div className="bg-white mx-8 p-4 md:p-8 lg:p-12 rounded-lg shadow-xl mt-10 shadow-black w-auto md:w-3/4 lg:w-2/3 xl:w-1/2">
          <h1 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4 text-center">
            Fingerprint Verification
          </h1>
          <p className="text-sm md:text-base lg:text-lg mb-4 text-center">
            Please place your hand on the fingerprint device.
          </p>
          <button
            onClick={handleVerify}
            className="bg-gray-900 text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded focus:outline-none focus:shadow-outline w-full md:w-auto"
          >
            Verify
          </button>
        </div>
      </div>
    </>
  );
};

export default CaptureFingerprint;
