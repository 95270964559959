import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchApi } from "../../utlis/axios";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import Loader from '../../animation/Loader.json'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'

export const FamilyDonation = () => {
  const [familyData, setFamilyData] = useState([]); // Initialize as an object with an empty results array
  const { access } = useSelector(state => state.authSlice.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(null)
  const { id } =  useSelector(state => state.authSlice.userInfo?.user)
  const [modal, setModal] = useState(false);
  const [isCheck, setIsCheck] = useState(true);
  const [program, setProgram] = useState();
  const [family, setFamily] = useState();
  const [formValues, setFormValues] = useState({ 
    type: 'education',
    reason: '',
    amount: '',
    family:  [], 
    program: '', 
  });
  const [validationErrors, setValidationErrors] = useState({
    type: "",
    reason: "",
    amount: "",
    program: "",
  });

  const navigate = useNavigate();

  const getFamilyData = async () => {
    setIsLoading(true)
    try {
      const result = await fetchApi.get(`donation/?search=${searchQuery}`, {
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
      if (result.status === 200) {
        setFamilyData(result?.data);
        console.log('result data', result?.data);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log({ error });
    }
  }

  const getPrograms =async ()=>{
    try{
      const result = await fetchApi.get('family-programs/',{
        headers:{
          Authorization:`Bearer ${access}`
        }
      })
      if( result?.status === 200){
        setProgram(result?.data)
        console.log("program result...//", result?.data)
        
      }
    } catch(error){
      console.log({error})
    }
  }

  const getFamily =async()=>{
    try{
      const result = await fetchApi.get('family/?status=accepted&limit=1000',{
        headers:{
          Authorization:`Bearer ${access}`
        }
      })
      if(result?.status === 200){
        setFamily(result?.data)
        
      }
    } catch(error){
      console.log({error})
    }
  }

  const validateForm = () => {
    let valid = true;
    const errors = {
      type: "",
      reason: "",
      amount: "",
      program: "",
    };

    if (!formValues.type) {
      valid = false;
      errors.type = "Type is required";
    }

    if (!formValues.reason) {
      valid = false;
      errors.reason = "Reason is required";
    }

    if (!formValues.amount) {
      valid = false;
      errors.amount = "Amount is required";
    }

    if (!formValues.program) {
      valid = false;
      errors.program = "Program is required";
    }

    

    setValidationErrors(errors);
    return valid;
  };

  useEffect(()=>{
    getPrograms();
    getFamily();
  },[])


 useEffect(() => {
    getFamilyData();
  }, [searchQuery, access]);

  const handleTableRowClick = (id) => {
    // navigate(`/applicationdetail/${id}`);
    console.log("id :", id)
  };

  const toggle = () => {
		setModal(!modal)
		setIsCheck(true)
		setFormValues({
			type: '',
			reason: '',
      amount:'',
      family:[],
      program:null
		})
	}

  const createDonation = async () => {
    console.log("clicked");
    if (!validateForm()) {
      return;
    }
    try {
      const data = new FormData();
      data.append('id', id);
      data.append('type', formValues?.type);
      data.append('reason', formValues?.reason);
      data.append('amount', formValues?.amount);
      data.append('program', formValues?.program);
      data.append('is_active', true);
      if (formValues.family.length > 0) {
        // Append the family array directly to the FormData object
        formValues.family.forEach((familyId, index) => {
          data.append('family', [familyId]);
        });
      }
      // const data = {'id': id,'type': formValues?.type, 'reason': formValues?.reason, 'amount':formValues?.amount, 'program':formValues?.program, 'family':formValues?.family, 'is_active':true};
      
      const result = await fetchApi.post('donation/', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 201) {
        console.log("posted program");
        setModal(false);
      }
      getFamilyData();
    } catch (error) {
      console.log({ error });
    }
  };
  
  

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(familyData.count / itemsPerPage);

  const fetchDataForPage = async (pageNumber) => {
    const newOffset = (pageNumber - 1) * itemsPerPage;
    
    try {
      const result = await fetchApi.get(
        `donation/?limit=${itemsPerPage}&offset=${newOffset}&page=${pageNumber}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      return result;
    } catch (error) {
      throw error;
    }
  };
  
  const renderPaginationItems = () => {
    const pageButtons = [];
    const maxVisibleButtons = 4;
  
    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageButtons.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageClick(1)}
        >
          1
        </Pagination.Item>
      );
  
      for (let i = 2; i < totalPages; i++) {
        if (
          i >= currentPage - 1 &&
          i <= currentPage + 2
        ) {
          pageButtons.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
  
      pageButtons.push(<Pagination.Ellipsis key="ellipsis2" />);

      pageButtons.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
  
    return pageButtons;
  };
  
  const handlePageClick = (pageNumber) => {
    console.log("pageNumbber", pageNumber)
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      setIsLoading(true);
  
      // Fetch data for the selected page
      fetchDataForPage(pageNumber)
        .then((result) => {
          if (result.status === 200) {
            
            setFamilyData(result?.data);
            // console.log("family data....", familyData)
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  
  
    const nextPageData = async () => {
    const nextPage = currentPage + 1;
    const newOffset = (nextPage - 1) * itemsPerPage;
  
    setIsLoading(true);
  
    try {
      const result = await fetchApi.get(
        `donation/?limit=${itemsPerPage}&offset=${newOffset}&page=${nextPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      if (result.status === 200) {
        setFamilyData(result?.data);
        console.log('next data',result.data.results )
        setCurrentPage(nextPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const prevPageData = async () => {
    const prevPage = currentPage - 1;
    const newOffset = (prevPage - 1) * itemsPerPage;
  
    setIsLoading(true);
  
    try {
      const result = await fetchApi.get(
        `donation/?limit=${itemsPerPage}&offset=${newOffset}&page=${prevPage}&pageSize=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
  
      if (result.status === 200) {
        setFamilyData(result?.data);
        setCurrentPage(prevPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <>
     
      <div className="Table_container" style={{ minHeight: '100vh', paddingTop:'0px' }}>
        <div className="Table_heading">
          <div className='sidebar-search d-flex justify-content-between'>
            <form className="formSearch">
              <input
                type='search'
                name='search'
                className='px-3 Search_input'
                placeholder='Search Here'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className="Tbtn">
            <Link to="">
              <button className="heading_btn" style={{backgroundColor:'#DE1285'}} onClick={toggle}>Donation for Family</button>
            </Link>
          </div>
        </div>
        <div style={{ display: 'block' }}>
				{isCheck && (
					<Modal isOpen={modal} toggle={toggle} className='modal-popups'>
						<ModalBody>
							<h3 className='text-center text-dark'>Donation for Family</h3>
							<div className='modal-body'>
								<form>
                <div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Type:
										</label>
                    <select
                      type="text"
                      id="Application Priority"
                      name="Application Priority"
                      placeholder="Application Priority"
                      className="formField custom-select w-100"
                      value={formValues?.type}
                      onChange={(e) => setFormValues({ ...formValues, type: e.target.value })}
                      required
                    >
                    <option >select</option>
                    <option key='education'>education</option>
                    <option key='health'>health</option>
                    <option key='food'>food</option>
                    <option key='business'>business</option>
                    <option key='other'>other</option>
                    </select>
                    <span className="error-message text-danger">{validationErrors.type}</span>
									</div>
                    <div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Reason:
										</label>
										<input
											type='text'
											className='form-control'
											id='recipient-name'
											style={{ borderRadius: '30px', height: '3rem'}}
											value={formValues?.reason}
											onChange={(e) =>
												setFormValues({ ...formValues, reason: e.target.value })
											}
                      required
										/>
                    <span className="error-message text-danger">{validationErrors.reason}</span>
									</div>
                    <div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Amount:
										</label>
										<input
											type='number'
											className='form-control'
											id='recipient-name'
											style={{ borderRadius: '30px', height: '3rem' }}
											value={formValues?.amount}
											onChange={(e) =>
												setFormValues({ ...formValues, amount: e.target.value })
											}
                      required
										/>
                    <span className="error-message text-danger">{validationErrors.amount}</span>
									</div>
                  <div className='form-group'>
                  <label htmlFor='recipient-name' className='col-form-label'>
                    Program:
                  </label>
                  <select
                    type="text"
                    id="Application Priority"
                    name="Application Priority"
                    placeholder="Application Priority"
                    className="formField custom-select w-100"
                    value={formValues?.program }
                    onChange={(e) => setFormValues({ ...formValues, program: e.target.value })}
                    required
                  >
                     <option >select</option>
                    {program?.results?.map((item) => (
                      <option  value={item?.id}>
                        {item?.program?.name}
                      </option>
                    ))}
                  </select>
                  <span className="error-message text-danger">{validationErrors.program}</span>
                  </div>
                  <div className='form-group'>
										<label htmlFor='recipient-name' className='col-form-label'>
											Family:
										</label>
                    <select
                        id="family"
                        name="family"
                        className="formField "
                        value={formValues?.family}
                        onChange={(e) => setFormValues({ ...formValues, family: Array.from(e.target.selectedOptions, option => option.value) })}
                        style={{width:'100%', borderRadius:'20px'}}
                        required
                        multiple // Add multiple attribute here
                      >
                    {family?.results?.map((item) => (
                    <option  value={item.id}>{item?.name}</option>
                     ))}
                    </select>
                    <span className="error-message text-danger">{validationErrors.family}</span>
									</div>
									
								</form>
							</div>
						</ModalBody>
						<ModalFooter>
							<div className='cmn-btn'>
								<button onClick={toggle}
                 style={{
                  backgroundColor:'#DE1285', 
                  borderRadius:'30px'}}>
                Close
                </button>
							</div>
							<div className='cmn-btn'>
								<button onClick={createDonation} 
                style={{
                  backgroundColor:'#DE1285', 
                  borderRadius:'30px'}}>
                Donate
                </button>
							</div>
						</ModalFooter>
					</Modal>
				)}
		</div>
        <div className="Table_Section">
          <h5 className=" p-3">Family Donation</h5>
          {
            isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Lottie
                loop={true}
                animationData={Loader}
                style={{ width: '200px' }}
                className='auth-sidebar-video'
              />
            </div>
            ) : (
              <>
               <div className="table-responsive">
          <table className="table align-middle mb-0 bg-white table-hover">
            <thead className="table-light m-3">
              <tr className="m-3 ">
                <th style={{ padding: "10px", }}>Family</th>
                <th style={{ padding: "10px", textAlign: 'center' }}>Program</th>
                <th style={{ padding: "10px",}}>Budget</th>
                <th style={{ padding: "10px", textAlign: 'center' }}>Reason</th>
                <th style={{ padding: "10px", textAlign: 'center' }}>Family Status</th>
              </tr>
            </thead>
            <tbody>

              {familyData?.results?.map((data, value) => {

                return (
                  <tr
                    key={value}
                    className="table-row" // Add this class for styling
                    onClick={() => handleTableRowClick(data?.id)}
                    >
                    <td style={{ verticalAlign: 'middle' }}>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <p className="fw-bold mb-3 mt-3 ">{data?.family?.name}</p>
                        </div>
                      </div>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <div
                        className='dot d-flex align-items-center justify-content-center w-100'
                      >
                        <span className='span-badge'></span>
                        {data?.program?.name}
                      </div>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                    <p className="fw-normal mb-1 ">{data?.program?.budget}</p>
                    </td>
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      {data?.reason}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {/* <p className="fw-normal mb-1 text-center">{data?.family?.status}</p> */}
                     {data?.family?.status}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Pagination>
            <Pagination.Prev
              onClick={prevPageData}
              disabled={currentPage === 1}
            />
           {renderPaginationItems()}
            <Pagination.Next
             onClick={nextPageData}
              disabled={currentPage === totalPages}
            />
          </Pagination>
            </div>
          </div>
              </>
            )
          }
        </div>
      </div>
    </>
  )
}
