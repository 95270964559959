import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import Loader from "../../animation/Loader.json";
import { FamilyDashboard } from "../Family/FamilyDashboard";
import { MemberDashboard } from "../Member/MemberDashboard";
import { DonationDashboard } from "../Donation/DonationDashboard";

export const AllGraph = () => {
  const [selectedToggle, setSelectedToggle] = useState("family");
  const [isLoading, setIsLoading] = useState(null);
  const handleToggleClick = (toggleName) => {
    setSelectedToggle(toggleName);
  };

  return (
    <>
      <div
        className="Table_container"
        style={{ minHeight: "100vh", paddingTop: "0px" }}
      >
        <div className="flex justify-center">
        <button
            className={`toggle-button accepted_btn ${
              selectedToggle === "family" ? "active" : ""
            }`}
            onClick={() => handleToggleClick("family")}
            style={{
              backgroundColor:
                selectedToggle === "family" ? "#de1285" : "white",
              color: selectedToggle === "family" ? "white" : "black",
            }}
          >
            Family
          </button>
          <button
            className={`toggle-button hold_btn ${
              selectedToggle === "member" ? "active" : ""
            }`}
            onClick={() => handleToggleClick("member")}
            style={{
              backgroundColor:
                selectedToggle === "member" ? "#de1285" : "white",
              color: selectedToggle === "member" ? "white" : "black",
            }}
          >
            Member
          </button>
          <button
            className={`toggle-button rejected_btn ${
              selectedToggle === "donation" ? "active" : ""
            }`}
            onClick={() => handleToggleClick("donation")}
            style={{
              backgroundColor:
                selectedToggle === "donation" ? "#de1285" : "white",
              color: selectedToggle === "donation" ? "white" : "black",
            }}
          >
            Donation
          </button>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Lottie
              loop={true}
              animationData={Loader}
              style={{ width: "200px" }}
              className="auth-sidebar-video"
            />
          </div>
        ) : (
          <div className="mt-4 w-100">
            {selectedToggle === "family" && <FamilyDashboard />}
            {selectedToggle === "member" && <MemberDashboard />}
            {selectedToggle === "donation" && <DonationDashboard />}
          </div>
        )}
      </div>
    </>
  );
};
